import { WriteDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import { LayoutItemFactory } from './layoutItemFactory'
import { LayoutItem, PositionContext, SizeContext } from 'application/layout'
import { Shaper } from 'application/text'
import { getVH, getVW, getVX, getVY } from './utils'
import { LayoutEngineCalculator } from '../types'

export class ContentLayoutEngine implements LayoutEngineCalculator {
  private document: WriteDocument
  private layoutItemFactory: LayoutItemFactory

  constructor(document: WriteDocument, textShaper: Shaper) {
    this.document = document
    this.layoutItemFactory = new LayoutItemFactory(textShaper)
  }

  layout = (nodes: Set<string>): void => {
    const canvasChildren = this.getCanvasChildren(nodes)

    const layoutItemMap: { [key: string]: LayoutItem } = {}
    for (const id of canvasChildren) {
      const node = this.document.getNode(id)
      if (!node) continue

      const layoutItem = this.layoutItemFactory.create(node)
      if (!layoutItem) continue

      layoutItemMap[id] = layoutItem
      this.addChildren(node, layoutItemMap)
    }

    for (const id of canvasChildren) {
      const node = this.document.getNode(id)
      if (!node) continue

      const item = layoutItemMap[id]
      if (!item) continue

      const x = getVX(node)
      const y = getVY(node)
      const w = getVW(node)
      const h = getVH(node)

      const sizeContext = new SizeContext()
      sizeContext.vw = w
      sizeContext.vh = h
      item.resize(sizeContext)

      const positionContext = new PositionContext()
      positionContext.vx = x
      positionContext.vy = y
      positionContext.vw = w
      positionContext.vh = h
      item.place(positionContext)
    }

    for (const id of Object.keys(layoutItemMap)) {
      const item = layoutItemMap[id]
      if (!item) continue

      const node = this.document.getNode(id)
      if (!node) continue

      const currX = node.getBaseAttribute('x')
      const currY = node.getBaseAttribute('y')
      const currW = node.getBaseAttribute('w')
      const currH = node.getBaseAttribute('h')

      const itemY = item.getTop()
      const itemX = item.getLeft()
      const itemW = item.getWidth()
      const itemH = item.getHeight()

      if (currX !== itemX) node.setBaseAttribute('x', itemX)
      if (currY !== itemY) node.setBaseAttribute('y', itemY)
      if (currW !== itemW) node.setBaseAttribute('w', itemW)
      if (currH !== itemH) node.setBaseAttribute('h', itemH)
    }

    for (const id of Object.keys(layoutItemMap)) {
      const item = layoutItemMap[id]
      if (!item) continue

      this.layoutItemFactory.release(item)
    }
  }

  private addChildren = (
    node: ReadOnlyNode,
    layoutItemMap: { [key: string]: LayoutItem }
  ) => {
    const layoutItem = layoutItemMap[node.getId()]
    if (!layoutItem) return

    const children = node.getChildren() || []
    for (const childId of children) {
      const child = this.document.getNode(childId)
      if (!child) continue

      const childLayoutItem = this.layoutItemFactory.create(child)
      if (!childLayoutItem) continue

      layoutItemMap[childId] = childLayoutItem
      layoutItem.children.push(childLayoutItem)

      this.addChildren(child, layoutItemMap)
    }
  }

  private getCanvasChildren = (nodes: Set<string>): string[] => {
    const canvasChildren: string[] = []
    for (const id of nodes) {
      const canvasParent = this.getCanvasParent(id)
      if (canvasParent && !canvasChildren.includes(canvasParent)) {
        canvasChildren.push(canvasParent)
      }
    }
    return canvasChildren
  }

  private getCanvasParent = (id: string): string | undefined => {
    const node = this.document.getNode(id)
    if (!node || node.getBaseAttribute('type') === 'root') return undefined

    const parent = this.document.getParent(node)
    if (!parent || parent.getBaseAttribute('type') === 'root') return undefined
    if (parent.getBaseAttribute('type') === 'canvas') return node.getId()

    const grandParent = this.document.getParent(parent)
    if (!grandParent) return parent.getId()
    if (grandParent.getBaseAttribute('type') === 'canvas') return parent.getId()

    return this.getCanvasParent(parent.getId())
  }
}
