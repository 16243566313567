import { AttributeType } from 'application/attributes'
import { IconKey } from 'assets/iconLibrary'
import { tooltipKey } from 'assets/tooltips'
import OnboardingPopup from 'components/Library/Components/OnboardingPopup/OnboardingPopup'
import ToolbarIconButton from 'components/Library/Components/Toolbar/ToolbarIconButton/ToolbarIconButton'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import persistAtom from 'components/State/persistAtom'
import { ActionDraw } from 'editor'
import useAction from 'hooks/editor/useAction'
import useActionInitiator from 'hooks/editor/useActionInitiator'
import { atom, useRecoilState } from 'recoil'

export const templateTooltipClosed = atom<boolean>(
  persistAtom({
    key: 'templateTooltipClosed',
    default: false,
    persistMode: 'local',
  })
)

export default function ToolButtons() {
  const action = useAction()
  const { draw, cancel, toggleLibrary } = useActionInitiator()

  const [tooltip, closeTooltip] = useRecoilState(templateTooltipClosed)

  const handleClick = (type: AttributeType) => {
    if (drawTools.some((t) => t.action === action && t.type === type)) {
      cancel()
    } else {
      draw(type)
    }
  }

  return (
    <PanelContainer
      id={'tool-buttons'}
      width={'hug'}
      height={48}
      paddingH={10}
      direction={'row'}
    >
      {drawTools.map((t) => (
        <ToolbarIconButton
          key={t.name}
          icon={t.icon}
          tooltipKey={t.tooltipKey}
          active={action === t.action}
          onClick={() => handleClick(t.type)}
        />
      ))}
      <OnboardingPopup active={!tooltip} onClose={() => closeTooltip(true)}>
        <ToolbarIconButton
          icon={'Grid'}
          tooltipKey={'AddTemplates'}
          active={action === 'openTemplateLibrary'}
          onClick={() => {
            closeTooltip(true)
            toggleLibrary('openTemplateLibrary')
          }}
        />
      </OnboardingPopup>
    </PanelContainer>
  )
}

const drawTools: {
  name: string
  action: ActionDraw
  type: AttributeType
  tooltipKey: tooltipKey
  icon: IconKey
  hotkey: string
}[] = [
  {
    name: 'Page',
    action: 'drawPage',
    type: 'page',
    tooltipKey: 'Page',
    icon: 'Page',
    hotkey: 'P',
  },
  {
    name: 'Frame',
    action: 'drawFrame',
    type: 'frame',
    tooltipKey: 'Frame',
    icon: 'Frame',
    hotkey: 'F',
  },
  {
    name: 'Text',
    action: 'drawText',
    type: 'text',
    tooltipKey: 'Text',
    icon: 'Text',
    hotkey: 'T',
  },
  {
    name: 'Image',
    action: 'drawImage',
    type: 'image',
    tooltipKey: 'Image',
    icon: 'Image',
    hotkey: 'I',
  },
  {
    name: 'Form',
    action: 'drawForm',
    type: 'form',
    tooltipKey: 'Form',
    icon: 'Form',
    hotkey: 'O',
  },
  {
    name: 'Input',
    action: 'drawInput',
    type: 'input',
    tooltipKey: 'Input',
    icon: 'Input',
    hotkey: 'I',
  },
]
