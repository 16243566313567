import { isAbsolutePositionMode } from 'application/attributes'
import { ReadOnlyNode } from 'application/node'
import { Point } from 'application/shapes'
import { ReadOnlyDocument } from './types'

export function getInsertionIndex(
  point: Point,
  parent: ReadOnlyNode,
  document: ReadOnlyDocument
): number {
  const siblings = parent.getChildren()
  if (!siblings) return 0

  const mode = parent.getStyleAttribute('display')
  switch (mode) {
    case 'block':
    case undefined:
      let blockIndex = 0
      for (const siblingId of siblings) {
        const sibling = document.getNode(siblingId)
        if (!sibling) continue

        if (isAbsolutePositionMode(sibling)) {
          blockIndex++
          continue
        }

        const siblingY = sibling.getBaseAttribute('y')
        const siblingH = sibling.getBaseAttribute('h')
        if (point.y < siblingY + siblingH / 2) return blockIndex
        blockIndex++
      }
      return blockIndex
    case 'flex':
      const parentDirection = parent.getStyleAttribute('flex.direction')
      let flexIndex = 0
      for (const siblingId of siblings) {
        const sibling = document.getNode(siblingId)
        if (!sibling) continue

        if (isAbsolutePositionMode(sibling)) {
          flexIndex++
          continue
        }

        switch (parentDirection) {
          case 'row':
          case 'wrap':
            const siblingX = sibling.getBaseAttribute('x')
            const siblingW = sibling.getBaseAttribute('w')
            if (point.x < siblingX + siblingW / 2) return flexIndex
            break
          case 'column':
            const siblingY = sibling.getBaseAttribute('y')
            const siblingH = sibling.getBaseAttribute('h')
            if (point.y < siblingY + siblingH / 2) return flexIndex
            break
        }

        flexIndex++
      }
      return flexIndex
    default:
      return 0
  }
}
