import { AttributeFill, AttributeType, StyleMap } from 'application/attributes'

export class FillAttributeFactory {
  create = (type: AttributeType): Partial<StyleMap> => {
    switch (type) {
      case 'page':
      case 'frame':
      case 'input':
      case 'form':
        return { background: [createWhite()] }
    }
    return {}
  }
}

function createWhite(): AttributeFill {
  return {
    type: 'color',
    color: { r: 255, g: 255, b: 255, a: 1 },
  }
}
