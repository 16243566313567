import { FlexSizeItem } from './flexSizeItem'
import { SizeContext } from '../context/sizeContext'
import { CssFlexAlign, CssOverflow, SizeValueType } from '../types/types'

export class FlexSizeItemRow extends FlexSizeItem {
  initializeMainAxis = (context: SizeContext): void => {
    this.grow = this.layoutItem!.getFlexGrow()
    this.shrink = this.layoutItem!.getFlexShrink()
    this.align = this.layoutItem!.getAlignSelf()

    const hasHeight = this.layoutItem!.hasCssHeight()
    this.stretch = !hasHeight && this.align === CssFlexAlign.stretch

    this.minContentMainSize = this.computeMinContentMainSize(context)
    this.maxContentMainSize = this.computeMaxContentMainSize(context)

    this.minSize = this.computeMinSize(context)
    this.maxSize = this.computeMaxSize(context)

    this.baseSize = this.computeBaseSize(context)
    this.mainSize = this.computeMainSize()
    this.computedSize = this.mainSize

    this.scaledFlexShrinkFactor = this.shrink * this.baseSize
  }

  applyMainAxisSize = (size: number, context: SizeContext): void => {
    const newContext = this.layoutItem!.sizeContextPool!.fromContext(context)
    newContext.width.value = size
    newContext.width.type = SizeValueType.exact

    this.layoutItem!.resize(newContext)

    this.layoutItem!.sizeContextPool!.release(newContext)
  }

  getCrossSize = (): number => {
    return this.layoutItem!.position.height
  }

  applyCrossAxisSize = (
    mainSize: number,
    crossSize: number,
    context: SizeContext
  ): void => {
    const minMaxCrossSize = this.layoutItem!.minMaxHeight(crossSize, context)

    const newContext = this.layoutItem!.sizeContextPool!.fromContext(context)
    newContext.width.value = mainSize
    newContext.width.type = SizeValueType.exact
    newContext.height.value = minMaxCrossSize
    newContext.height.type = SizeValueType.exact

    this.layoutItem!.resize(newContext)

    this.layoutItem!.sizeContextPool!.release(newContext)
  }

  private computeBaseSize = (context: SizeContext): number => {
    const basis = this.layoutItem!.getFlexBasis()
    if (basis.isDefined()) {
      return basis.calcPercentage(context.width.value)
    }

    const width = this.layoutItem!.getPreferredWidth(context)
    if (width !== undefined) {
      return width
    }

    const transferredWidth = this.layoutItem!.getTransferredWidth(context)
    if (transferredWidth !== undefined) {
      return this.layoutItem!.minMaxTransferredWidth(transferredWidth, context)
    }

    const transferredMinWidth = this.layoutItem!.getTransferredMinWidth(context)
    if (transferredMinWidth !== undefined) {
      return this.layoutItem!.minMaxTransferredWidth(
        transferredMinWidth,
        context
      )
    }

    const ratio = this.layoutItem!.getRatio()
    const definiteHeight = !context.isHeightAuto()
    if (this.stretch && definiteHeight && ratio !== undefined) {
      return this.layoutItem!.minMaxTransferredWidth(
        context.height.value * ratio,
        context
      )
    }

    switch (context.width.type) {
      case SizeValueType.min_content:
        return this.layoutItem!.getMinContentContributionWidth(context)
      case SizeValueType.auto:
      case SizeValueType.absolute:
      case SizeValueType.max_content:
        return this.layoutItem!.getMaxContentContributionWidth(context)
      case SizeValueType.exact:
        return context.width.value
    }
  }

  private computeMainSize = (): number => {
    return Math.max(this.minSize, Math.min(this.maxSize, this.baseSize))
  }

  private computeMinContentMainSize = (context: SizeContext): number => {
    const width = this.layoutItem!.getMinContentContributionWidth(context)

    return this.computeContentMainSize(width, context)
  }

  private computeMaxContentMainSize = (context: SizeContext): number => {
    const width = this.layoutItem!.getMaxContentContributionWidth(context)

    return this.computeContentMainSize(width, context)
  }

  private computeContentMainSize = (
    size: number,
    context: SizeContext
  ): number => {
    const preferredWidth = this.layoutItem!.getPreferredWidth(context)
    if (preferredWidth !== undefined) {
      const contentSize = Math.max(size, preferredWidth)
      return this.layoutItem!.minMaxWidth(contentSize, context)
    }

    const transferredWidth = this.layoutItem!.getTransferredWidth(context)
    if (transferredWidth !== undefined) {
      const contentSize = Math.max(
        size,
        this.layoutItem!.minMaxTransferredWidth(transferredWidth, context)
      )
      return this.layoutItem!.minMaxWidth(contentSize, context)
    }

    const minTransferredWidth = this.layoutItem!.getTransferredMinWidth(context)
    if (minTransferredWidth !== undefined) {
      const contentSize = Math.max(
        size,
        this.layoutItem!.minMaxTransferredWidth(minTransferredWidth, context)
      )
      return this.layoutItem!.minMaxWidth(contentSize, context)
    }

    const ratio = this.layoutItem!.getRatio()
    const definiteHeight = !context.isHeightAuto()
    if (this.stretch && definiteHeight && ratio !== undefined) {
      const transferredWidth = this.layoutItem!.minMaxTransferredWidth(
        context.height.value * ratio,
        context
      )
      const contentSize = Math.max(size, transferredWidth)
      return this.layoutItem!.minMaxWidth(contentSize, context)
    }

    return this.layoutItem!.minMaxWidth(size, context)
  }

  private computeMinSize = (context: SizeContext): number => {
    const minWidth = this.layoutItem!.getMinWidth(context)
    if (minWidth !== undefined) return minWidth

    const overflow = this.layoutItem!.getOverflow()
    if (overflow !== CssOverflow.overflow_visible) return 0

    const content = this.layoutItem!.getMinContentContributionWidth(context)
    const contentSizeSuggestion = this.layoutItem!.minMaxWidth(
      this.layoutItem!.minMaxTransferredWidth(content, context),
      context
    )

    const preferredWidth = this.layoutItem!.getPreferredWidth(context)
    if (preferredWidth !== undefined) {
      return Math.min(
        contentSizeSuggestion,
        this.layoutItem!.minMaxWidth(preferredWidth, context)
      )
    }

    const transferredWidth = this.layoutItem!.getTransferredWidth(context)
    if (transferredWidth !== undefined) {
      return Math.max(
        contentSizeSuggestion,
        this.layoutItem!.minMaxTransferredWidth(transferredWidth, context)
      )
    }

    const ratio = this.layoutItem!.getRatio()
    const definiteHeight = !context.isHeightAuto()
    if (this.stretch && definiteHeight && ratio !== undefined) {
      return Math.max(
        contentSizeSuggestion,
        this.layoutItem!.minMaxTransferredWidth(
          context.height.value * ratio,
          context
        )
      )
    }

    return contentSizeSuggestion
  }

  private computeMaxSize = (context: SizeContext): number => {
    const maxWidth = this.layoutItem!.getMaxWidth(context)
    if (maxWidth !== undefined) return maxWidth

    return Infinity
  }
}
