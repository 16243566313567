import { AttributeType, BaseMap, StyleMap } from 'application/attributes'
import { Command, CreateNode, SetParentNode } from 'application/client/command'
import { IdGenerator } from 'application/ids/generator'
import { Point } from 'application/shapes'
import { TextAttributeFactory } from './attributes/text'
import { FillAttributeFactory } from './attributes/fill'
import _ from 'lodash'

export interface InsertionStrategy {
  getParent: () => string
  getIndex: () => number | undefined
  getPosition: () => Point
}

interface CommandHandler {
  handle: (command: Command[]) => void
}

export class NodeCreationHandler {
  private commandHandler: CommandHandler
  private idGenerator: IdGenerator
  private fillAttributeFactory: FillAttributeFactory
  private textAttributeFactory: TextAttributeFactory

  constructor(
    commandHandler: CommandHandler,
    idGenerator: IdGenerator,
    fillAttributeFactory: FillAttributeFactory,
    textAttributeFactory: TextAttributeFactory
  ) {
    this.commandHandler = commandHandler
    this.idGenerator = idGenerator
    this.fillAttributeFactory = fillAttributeFactory
    this.textAttributeFactory = textAttributeFactory
  }

  createCanvas = (): string => {
    const id = this.idGenerator.next()
    const create = this.buildCreateCommand(
      id,
      'root',
      'canvas',
      { x: 0, y: 0 },
      {},
      {}
    )
    const reparent = this.buildReparentCommand(id, 'root', 0)
    this.commandHandler.handle([create, reparent])

    return id
  }

  createOnCanvas = (
    type: AttributeType,
    base: Partial<BaseMap>,
    style: Partial<StyleMap>,
    insertionStrategy: InsertionStrategy
  ): string => {
    const parent = insertionStrategy.getParent()
    const index = insertionStrategy.getIndex()
    const position = insertionStrategy.getPosition()

    const id = this.idGenerator.next()

    const create = this.buildCreateCommand(
      id,
      parent,
      type,
      position,
      base,
      style
    )
    const reparent = this.buildReparentCommand(id, parent, index)
    this.commandHandler.handle([create, reparent])

    return id
  }

  private buildCreateCommand = (
    id: string,
    parentId: string,
    type: AttributeType,
    position: Point,
    base: Partial<BaseMap>,
    style: Partial<StyleMap>
  ): CreateNode => {
    return {
      type: 'createNode',
      params: {
        settings: {
          id: id,
          base: {
            ...base,
            type: type,
            name: _.capitalize(type),
            x: position.x,
            y: position.y,
            'boundingBox.x': position.x,
            'boundingBox.y': position.y,
            ...this.textAttributeFactory.createBase(type),
          },
          style: {
            ...style,
            ...this.textAttributeFactory.createStyle(type),
            ...this.fillAttributeFactory.create(type),
          },
        },
      },
    }
  }

  private buildReparentCommand = (
    id: string,
    parentId: string,
    index: number | undefined
  ): SetParentNode => {
    return {
      type: 'setParent',
      params: {
        id: id,
        parentId: parentId,
        index: index,
      },
    }
  }
}
