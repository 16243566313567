import { ReactComponent as AlignBottom } from './icons/Icon=AlignBottom.svg'
import { ReactComponent as AlignCenterH } from './icons/Icon=AlignCenterH.svg'
import { ReactComponent as AlignCenterV } from './icons/Icon=AlignCenterV.svg'
import { ReactComponent as AlignLeft } from './icons/Icon=AlignLeft.svg'
import { ReactComponent as AlignRight } from './icons/Icon=AlignRight.svg'
import { ReactComponent as AlignTop } from './icons/Icon=AlignTop.svg'
import { ReactComponent as Anchor } from './icons/Icon=Anchor.svg'
import { ReactComponent as AlignSelfBottom } from './icons/Icon=AlignSelfBottom.svg'
import { ReactComponent as AlignSelfCenterH } from './icons/Icon=AlignSelfCenterH.svg'
import { ReactComponent as AlignSelfCenterV } from './icons/Icon=AlignSelfCenterV.svg'
import { ReactComponent as AlignSelfLeft } from './icons/Icon=AlignSelfLeft.svg'
import { ReactComponent as AlignSelfRight } from './icons/Icon=AlignSelfRight.svg'
import { ReactComponent as AlignSelfStretchH } from './icons/Icon=AlignSelfStretchH.svg'
import { ReactComponent as AlignSelfStretchV } from './icons/Icon=AlignSelfStretchV.svg'
import { ReactComponent as AlignSelfTop } from './icons/Icon=AlignSelfTop.svg'
import { ReactComponent as AspectRatio } from './icons/Icon=AspectRatio.svg'
import { ReactComponent as AutolayoutColumnBottomCenterSpread } from './icons/Icon=AutolayoutColumnBottomCenterSpread.svg'
import { ReactComponent as AutolayoutColumnBottomLeftSpread } from './icons/Icon=AutolayoutColumnBottomLeftSpread.svg'
import { ReactComponent as AutolayoutColumnBottomRightSpread } from './icons/Icon=AutolayoutColumnBottomRightSpread.svg'
import { ReactComponent as AutolayoutColumnCenter } from './icons/Icon=AutolayoutColumnCenter.svg'
import { ReactComponent as AutolayoutColumnCenterCenterSpread } from './icons/Icon=AutolayoutColumnCenterCenterSpread.svg'
import { ReactComponent as AutolayoutColumnCenterLeftSpread } from './icons/Icon=AutolayoutColumnCenterLeftSpread.svg'
import { ReactComponent as AutolayoutColumnCenterRightSpread } from './icons/Icon=AutolayoutColumnCenterRightSpread.svg'
import { ReactComponent as AutolayoutColumnLeft } from './icons/Icon=AutolayoutColumnLeft.svg'
import { ReactComponent as AutolayoutColumnRight } from './icons/Icon=AutolayoutColumnRight.svg'
import { ReactComponent as AutolayoutColumnTopCenterSpread } from './icons/Icon=AutolayoutColumnTopCenterSpread.svg'
import { ReactComponent as AutolayoutColumnTopLeftSpread } from './icons/Icon=AutolayoutColumnTopLeftSpread.svg'
import { ReactComponent as AutolayoutColumnTopRightSpread } from './icons/Icon=AutolayoutColumnTopRightSpread.svg'
import { ReactComponent as AutolayoutEmpty } from './icons/Icon=AutolayoutEmpty.svg'
import { ReactComponent as AutolayoutHorizontal } from './icons/Icon=AutolayoutHorizontal.svg'
import { ReactComponent as AutolayoutRowBottom } from './icons/Icon=AutolayoutRowBottom.svg'
import { ReactComponent as AutolayoutRowBottomCenterSpread } from './icons/Icon=AutolayoutRowBottomCenterSpread.svg'
import { ReactComponent as AutolayoutRowBottomLeftSpread } from './icons/Icon=AutolayoutRowBottomLeftSpread.svg'
import { ReactComponent as AutolayoutRowBottomRightSpread } from './icons/Icon=AutolayoutRowBottomRightSpread.svg'
import { ReactComponent as AutolayoutRowCenter } from './icons/Icon=AutolayoutRowCenter.svg'
import { ReactComponent as AutolayoutRowCenterCenterSpread } from './icons/Icon=AutolayoutRowCenterCenterSpread.svg'
import { ReactComponent as AutolayoutRowCenterLeftSpread } from './icons/Icon=AutolayoutRowCenterLeftSpread.svg'
import { ReactComponent as AutolayoutRowCenterRightSpread } from './icons/Icon=AutolayoutRowCenterRightSpread.svg'
import { ReactComponent as AutolayoutRowTop } from './icons/Icon=AutolayoutRowTop.svg'
import { ReactComponent as AutolayoutRowTopCenterSpread } from './icons/Icon=AutolayoutRowTopCenterSpread.svg'
import { ReactComponent as AutolayoutRowTopLeftSpread } from './icons/Icon=AutolayoutRowTopLeftSpread.svg'
import { ReactComponent as AutolayoutRowTopRightSpread } from './icons/Icon=AutolayoutRowTopRightSpread.svg'
import { ReactComponent as AutolayoutVertical } from './icons/Icon=AutolayoutVertical.svg'
import { ReactComponent as Blur } from './icons/Icon=Blur.svg'
import { ReactComponent as BorderAll } from './icons/Icon=BorderAll.svg'
import { ReactComponent as BorderBottom } from './icons/Icon=BorderBottom.svg'
import { ReactComponent as BorderLeft } from './icons/Icon=BorderLeft.svg'
import { ReactComponent as BorderRight } from './icons/Icon=BorderRight.svg'
import { ReactComponent as BorderSplit } from './icons/Icon=BorderSplit.svg'
import { ReactComponent as BorderTop } from './icons/Icon=BorderTop.svg'
import { ReactComponent as BorderWidth } from './icons/Icon=BorderWidth.svg'
import { ReactComponent as BottomOfPage } from './icons/Icon=BottomOfPage.svg'
import { ReactComponent as Button } from './icons/Icon=Button.svg'
import { ReactComponent as CheckboxChecked } from './icons/Icon=CheckboxChecked.svg'
import { ReactComponent as CheckboxMixed } from './icons/Icon=CheckboxMixed.svg'
import { ReactComponent as CheckboxUnchecked } from './icons/Icon=CheckboxUnchecked.svg'
import { ReactComponent as ChevronLineDown } from './icons/Icon=ChevronLineDown.svg'
import { ReactComponent as ChevronLineRight } from './icons/Icon=ChevronLineRight.svg'
import { ReactComponent as Code } from './icons/Icon=Code.svg'
import { ReactComponent as CollapseLayers } from './icons/Icon=CollapseLayers.svg'
import { ReactComponent as Column } from './icons/Icon=Column.svg'
import { ReactComponent as ConstraintBottomCenter } from './icons/Icon=ConstraintBottomCenter.svg'
import { ReactComponent as ConstraintBottomLeft } from './icons/Icon=ConstraintBottomLeft.svg'
import { ReactComponent as ConstraintBottomRight } from './icons/Icon=ConstraintBottomRight.svg'
import { ReactComponent as ConstraintBottomScale } from './icons/Icon=ConstraintBottomScale.svg'
import { ReactComponent as ConstraintCenterCenter } from './icons/Icon=ConstraintCenterCenter.svg'
import { ReactComponent as ConstraintCenterLeft } from './icons/Icon=ConstraintCenterLeft.svg'
import { ReactComponent as ConstraintCenterRight } from './icons/Icon=ConstraintCenterRight.svg'
import { ReactComponent as ConstraintCenterScale } from './icons/Icon=ConstraintCenterScale.svg'
import { ReactComponent as ConstraintEmpty } from './icons/Icon=ConstraintEmpty.svg'
import { ReactComponent as ConstraintScaleCenter } from './icons/Icon=ConstraintScaleCenter.svg'
import { ReactComponent as ConstraintScaleLeft } from './icons/Icon=ConstraintScaleLeft.svg'
import { ReactComponent as ConstraintScaleRight } from './icons/Icon=ConstraintScaleRight.svg'
import { ReactComponent as ConstraintScaleScale } from './icons/Icon=ConstraintScaleScale.svg'
import { ReactComponent as ConstraintTopCenter } from './icons/Icon=ConstraintTopCenter.svg'
import { ReactComponent as ConstraintTopLeft } from './icons/Icon=ConstraintTopLeft.svg'
import { ReactComponent as ConstraintTopRight } from './icons/Icon=ConstraintTopRight.svg'
import { ReactComponent as ConstraintTopScale } from './icons/Icon=ConstraintTopScale.svg'
import { ReactComponent as Copy } from './icons/Icon=Copy.svg'
import { ReactComponent as Delay } from './icons/Icon=Delay.svg'
import { ReactComponent as Desktop } from './icons/Icon=Desktop.svg'
import { ReactComponent as DisplayBlock } from './icons/Icon=DisplayBlock.svg'
import { ReactComponent as DisplayFlex } from './icons/Icon=DisplayFlex.svg'
import { ReactComponent as DisplayHidden } from './icons/Icon=DisplayHidden.svg'
import { ReactComponent as DistributeH } from './icons/Icon=DistributeH.svg'
import { ReactComponent as DistributeV } from './icons/Icon=DistributeV.svg'
import { ReactComponent as Download } from './icons/Icon=Download.svg'
import { ReactComponent as DropdownArrowDown } from './icons/Icon=DropdownArrowDown.svg'
import { ReactComponent as DropdownArrowRight } from './icons/Icon=DropdownArrowRight.svg'
import { ReactComponent as DropdownArrowUp } from './icons/Icon=DropdownArrowUp.svg'
import { ReactComponent as Duration } from './icons/Icon=Duration.svg'
import { ReactComponent as Easing } from './icons/Icon=Easing.svg'
import { ReactComponent as Ellipse } from './icons/Icon=Ellipse.svg'
import { ReactComponent as Email } from './icons/Icon=Email.svg'
import { ReactComponent as FillHeight } from './icons/Icon=FillHeight.svg'
import { ReactComponent as FillWidth } from './icons/Icon=FillWidth.svg'
import { ReactComponent as FixedHeight } from './icons/Icon=FixedHeight.svg'
import { ReactComponent as FixedWidth } from './icons/Icon=FixedWidth.svg'
import { ReactComponent as FlexGrowH } from './icons/Icon=FlexGrowH.svg'
import { ReactComponent as FlexGrowV } from './icons/Icon=FlexGrowV.svg'
import { ReactComponent as FlexResizeH } from './icons/Icon=FlexResizeH.svg'
import { ReactComponent as FlexResizeV } from './icons/Icon=FlexResizeV.svg'
import { ReactComponent as FlexShrinkH } from './icons/Icon=FlexShrinkH.svg'
import { ReactComponent as FlexShrinkV } from './icons/Icon=FlexShrinkV.svg'
import { ReactComponent as FontSize } from './icons/Icon=FontSize.svg'
import { ReactComponent as Form } from './icons/Icon=Form.svg'
import { ReactComponent as Frame } from './icons/Icon=Frame.svg'
import { ReactComponent as Fullscreen } from './icons/Icon=Fullscreen.svg'
import { ReactComponent as Gap } from './icons/Icon=Gap.svg'
import { ReactComponent as Grid } from './icons/Icon=Grid.svg'
import { ReactComponent as GuidelineColumnCount } from './icons/Icon=GuidelineColumnCount.svg'
import { ReactComponent as GuidelineMarginH } from './icons/Icon=GuidelineMarginH.svg'
import { ReactComponent as GuidelineMarginV } from './icons/Icon=GuidelineMarginV.svg'
import { ReactComponent as HandOpen } from './icons/Icon=HandOpen.svg'
import { ReactComponent as Height } from './icons/Icon=Height.svg'
import { ReactComponent as Home } from './icons/Icon=Home.svg'
import { ReactComponent as HugHeight } from './icons/Icon=HugHeight.svg'
import { ReactComponent as HugWidth } from './icons/Icon=HugWidth.svg'
import { ReactComponent as Image } from './icons/Icon=Image.svg'
import { ReactComponent as Input } from './icons/Icon=Input.svg'
import { ReactComponent as Keyboard } from './icons/Icon=Keyboard.svg'
import { ReactComponent as LetterSpacing } from './icons/Icon=LetterSpacing.svg'
import { ReactComponent as Lightning } from './icons/Icon=Lightning.svg'
import { ReactComponent as LineSpacing } from './icons/Icon=LineSpacing.svg'
import { ReactComponent as Link } from './icons/Icon=Link.svg'
import { ReactComponent as LinkBroken } from './icons/Icon=LinkBroken.svg'
import { ReactComponent as LockChild } from './icons/Icon=LockChild.svg'
import { ReactComponent as LockClosed } from './icons/Icon=LockClosed.svg'
import { ReactComponent as LockOpen } from './icons/Icon=LockOpen.svg'
import { ReactComponent as MaxHeight } from './icons/Icon=MaxHeight.svg'
import { ReactComponent as MaxWidth } from './icons/Icon=MaxWidth.svg'
import { ReactComponent as MiddleOfPage } from './icons/Icon=MiddleOfPage.svg'
import { ReactComponent as MinHeight } from './icons/Icon=MinHeight.svg'
import { ReactComponent as Minus } from './icons/Icon=Minus.svg'
import { ReactComponent as MinWidth } from './icons/Icon=MinWidth.svg'
import { ReactComponent as Mobile } from './icons/Icon=Mobile.svg'
import { ReactComponent as MobileH } from './icons/Icon=MobileH.svg'
import { ReactComponent as Moon } from './icons/Icon=Moon.svg'
import { ReactComponent as Navigate } from './icons/Icon=Navigate.svg'
import { ReactComponent as Navigation } from './icons/Icon=Navigation.svg'
import { ReactComponent as Opacity } from './icons/Icon=Opacity.svg'
import { ReactComponent as Overflow } from './icons/Icon=Overflow.svg'
import { ReactComponent as OverlayClose } from './icons/Icon=OverlayClose.svg'
import { ReactComponent as OverlayOpen } from './icons/Icon=OverlayOpen.svg'
import { ReactComponent as PaddingBottom } from './icons/Icon=PaddingBottom.svg'
import { ReactComponent as PaddingH } from './icons/Icon=PaddingH.svg'
import { ReactComponent as PaddingLeft } from './icons/Icon=PaddingLeft.svg'
import { ReactComponent as PaddingRight } from './icons/Icon=PaddingRight.svg'
import { ReactComponent as PaddingSplit } from './icons/Icon=PaddingSplit.svg'
import { ReactComponent as PaddingTop } from './icons/Icon=PaddingTop.svg'
import { ReactComponent as PaddingV } from './icons/Icon=PaddingV.svg'
import { ReactComponent as Page } from './icons/Icon=Page.svg'
import { ReactComponent as PasswordLock } from './icons/Icon=PasswordLock.svg'
import { ReactComponent as Percent } from './icons/Icon=Percent.svg'
import { ReactComponent as Play } from './icons/Icon=Play.svg'
import { ReactComponent as Plus } from './icons/Icon=Plus.svg'
import { ReactComponent as PositionAbsolute } from './icons/Icon=PositionAbsolute.svg'
import { ReactComponent as PositionAuto } from './icons/Icon=PositionAuto.svg'
import { ReactComponent as PositionB } from './icons/Icon=PositionB.svg'
import { ReactComponent as PositionFixed } from './icons/Icon=PositionFixed.svg'
import { ReactComponent as PositionL } from './icons/Icon=PositionL.svg'
import { ReactComponent as PositionR } from './icons/Icon=PositionR.svg'
import { ReactComponent as PositionSticky } from './icons/Icon=PositionSticky.svg'
import { ReactComponent as PositionT } from './icons/Icon=PositionT.svg'
import { ReactComponent as PositionX } from './icons/Icon=PositionX.svg'
import { ReactComponent as PositionY } from './icons/Icon=PositionY.svg'
import { ReactComponent as PositionZ } from './icons/Icon=PositionZ.svg'
import { ReactComponent as Projects } from './icons/Icon=Projects.svg'
import { ReactComponent as Question } from './icons/Icon=Question.svg'
import { ReactComponent as RadiusBottomLeft } from './icons/Icon=RadiusBottomLeft.svg'
import { ReactComponent as RadiusBottomRight } from './icons/Icon=RadiusBottomRight.svg'
import { ReactComponent as RadiusSplit } from './icons/Icon=RadiusSplit.svg'
import { ReactComponent as RadiusTopLeft } from './icons/Icon=RadiusTopLeft.svg'
import { ReactComponent as RadiusTopRight } from './icons/Icon=RadiusTopRight.svg'
import { ReactComponent as Rectangle } from './icons/Icon=Rectangle.svg'
import { ReactComponent as Refresh } from './icons/Icon=Refresh.svg'
import { ReactComponent as RepaintLogo } from './icons/Icon=RepaintLogo.svg'
import { ReactComponent as Rotate } from './icons/Icon=Rotate.svg'
import { ReactComponent as Row } from './icons/Icon=Row.svg'
import { ReactComponent as ScreenSizes } from './icons/Icon=ScreenSizes.svg'
import { ReactComponent as ScrollAnchorBottom } from './icons/Icon=ScrollAnchorBottom.svg'
import { ReactComponent as ScrollAnchorCenter } from './icons/Icon=ScrollAnchorCenter.svg'
import { ReactComponent as ScrollAnchorTop } from './icons/Icon=ScrollAnchorTop.svg'
import { ReactComponent as ScrollBottomOffset } from './icons/Icon=ScrollBottomOffset.svg'
import { ReactComponent as ScrollLink } from './icons/Icon=ScrollLink.svg'
import { ReactComponent as ScrollProgress } from './icons/Icon=ScrollProgress.svg'
import { ReactComponent as ScrollTopOffset } from './icons/Icon=ScrollTopOffset.svg'
import { ReactComponent as Search } from './icons/Icon=Search.svg'
import { ReactComponent as Section } from './icons/Icon=Section.svg'
import { ReactComponent as Settings } from './icons/Icon=Settings.svg'
import { ReactComponent as Settings2 } from './icons/Icon=Settings2.svg'
import { ReactComponent as ShrinkHeight } from './icons/Icon=ShrinkHeight.svg'
import { ReactComponent as ShrinkWidth } from './icons/Icon=ShrinkWidth.svg'
import { ReactComponent as Smoothing } from './icons/Icon=Smoothing.svg'
import { ReactComponent as Spread } from './icons/Icon=Spread.svg'
import { ReactComponent as SpreadGap } from './icons/Icon=SpreadGap.svg'
import { ReactComponent as StateDefault } from './icons/Icon=StateDefault.svg'
import { ReactComponent as StateHover } from './icons/Icon=StateHover.svg'
import { ReactComponent as StatePressed } from './icons/Icon=StatePressed.svg'
import { ReactComponent as StickyGap } from './icons/Icon=StickyGap.svg'
import { ReactComponent as Sun } from './icons/Icon=Sun.svg'
import { ReactComponent as Swap } from './icons/Icon=Swap.svg'
import { ReactComponent as Tablet } from './icons/Icon=Tablet.svg'
import { ReactComponent as Target } from './icons/Icon=Target.svg'
import { ReactComponent as Text } from './icons/Icon=Text.svg'
import { ReactComponent as TextAlignCenterH } from './icons/Icon=TextAlignCenterH.svg'
import { ReactComponent as TextAlignLeft } from './icons/Icon=TextAlignLeft.svg'
import { ReactComponent as TextAlignRight } from './icons/Icon=TextAlignRight.svg'
import { ReactComponent as TextFixedSize } from './icons/Icon=TextFixedSize.svg'
import { ReactComponent as TextHug } from './icons/Icon=TextHug.svg'
import { ReactComponent as TextHugHeight } from './icons/Icon=TextHugHeight.svg'
import { ReactComponent as ThreeDots } from './icons/Icon=ThreeDots.svg'
import { ReactComponent as TooltipArrow } from './icons/Icon=TooltipArrow.svg'
import { ReactComponent as TopOfPage } from './icons/Icon=TopOfPage.svg'
import { ReactComponent as TransformRotate } from './icons/Icon=TransformRotate.svg'
import { ReactComponent as TransformScale } from './icons/Icon=TransformScale.svg'
import { ReactComponent as TransformX } from './icons/Icon=TransformX.svg'
import { ReactComponent as TransformY } from './icons/Icon=TransformY.svg'
import { ReactComponent as Upload } from './icons/Icon=Upload.svg'
import { ReactComponent as UploadImage } from './icons/Icon=UploadImage.svg'
import { ReactComponent as User } from './icons/Icon=User.svg'
import { ReactComponent as VisibleFalse } from './icons/Icon=VisibleFalse.svg'
import { ReactComponent as VisibleTrue } from './icons/Icon=VisibleTrue.svg'
import { ReactComponent as Warning } from './icons/Icon=Warning.svg'
import { ReactComponent as Website } from './icons/Icon=Website.svg'
import { ReactComponent as Width } from './icons/Icon=Width.svg'
import { ReactComponent as Wrap } from './icons/Icon=Wrap.svg'
import { ReactComponent as X } from './icons/Icon=X.svg'
import { ReactComponent as Zoom } from './icons/Icon=Zoom.svg'

export type IconKey =
  | 'AlignBottom'
  | 'AlignCenterH'
  | 'AlignCenterV'
  | 'AlignLeft'
  | 'AlignRight'
  | 'AlignTop'
  | 'Anchor'
  | 'AlignSelfBottom'
  | 'AlignSelfCenterH'
  | 'AlignSelfCenterV'
  | 'AlignSelfLeft'
  | 'AlignSelfRight'
  | 'AlignSelfStretchH'
  | 'AlignSelfStretchV'
  | 'AlignSelfTop'
  | 'AspectRatio'
  | 'AutolayoutColumnBottomCenterSpread'
  | 'AutolayoutColumnBottomLeftSpread'
  | 'AutolayoutColumnBottomRightSpread'
  | 'AutolayoutColumnCenter'
  | 'AutolayoutColumnCenterCenterSpread'
  | 'AutolayoutColumnCenterLeftSpread'
  | 'AutolayoutColumnCenterRightSpread'
  | 'AutolayoutColumnLeft'
  | 'AutolayoutColumnRight'
  | 'AutolayoutColumnTopCenterSpread'
  | 'AutolayoutColumnTopLeftSpread'
  | 'AutolayoutColumnTopRightSpread'
  | 'AutolayoutEmpty'
  | 'AutolayoutHorizontal'
  | 'AutolayoutRowBottom'
  | 'AutolayoutRowBottomCenterSpread'
  | 'AutolayoutRowBottomLeftSpread'
  | 'AutolayoutRowBottomRightSpread'
  | 'AutolayoutRowCenter'
  | 'AutolayoutRowCenterCenterSpread'
  | 'AutolayoutRowCenterLeftSpread'
  | 'AutolayoutRowCenterRightSpread'
  | 'AutolayoutRowTop'
  | 'AutolayoutRowTopCenterSpread'
  | 'AutolayoutRowTopLeftSpread'
  | 'AutolayoutRowTopRightSpread'
  | 'AutolayoutVertical'
  | 'Blur'
  | 'BorderAll'
  | 'BorderBottom'
  | 'BorderLeft'
  | 'BorderRight'
  | 'BorderSplit'
  | 'BorderTop'
  | 'BorderWidth'
  | 'BottomOfPage'
  | 'Button'
  | 'CheckboxChecked'
  | 'CheckboxMixed'
  | 'CheckboxUnchecked'
  | 'ChevronLineDown'
  | 'ChevronLineRight'
  | 'Code'
  | 'CollapseLayers'
  | 'Column'
  | 'ConstraintBottomCenter'
  | 'ConstraintBottomLeft'
  | 'ConstraintBottomRight'
  | 'ConstraintBottomScale'
  | 'ConstraintCenterCenter'
  | 'ConstraintCenterLeft'
  | 'ConstraintCenterRight'
  | 'ConstraintCenterScale'
  | 'ConstraintEmpty'
  | 'ConstraintScaleCenter'
  | 'ConstraintScaleLeft'
  | 'ConstraintScaleRight'
  | 'ConstraintScaleScale'
  | 'ConstraintTopCenter'
  | 'ConstraintTopLeft'
  | 'ConstraintTopRight'
  | 'ConstraintTopScale'
  | 'Copy'
  | 'Delay'
  | 'Desktop'
  | 'DisplayBlock'
  | 'DisplayFlex'
  | 'DisplayHidden'
  | 'DistributeH'
  | 'DistributeV'
  | 'Download'
  | 'DropdownArrowDown'
  | 'DropdownArrowRight'
  | 'DropdownArrowUp'
  | 'Duration'
  | 'Easing'
  | 'Ellipse'
  | 'Email'
  | 'FillHeight'
  | 'FillWidth'
  | 'FixedHeight'
  | 'FixedWidth'
  | 'FlexGrowH'
  | 'FlexGrowV'
  | 'FlexResizeH'
  | 'FlexResizeV'
  | 'FlexShrinkH'
  | 'FlexShrinkV'
  | 'FontSize'
  | 'Form'
  | 'Frame'
  | 'Fullscreen'
  | 'Gap'
  | 'Grid'
  | 'GuidelineColumnCount'
  | 'GuidelineMarginH'
  | 'GuidelineMarginV'
  | 'HandOpen'
  | 'Height'
  | 'Home'
  | 'HugHeight'
  | 'HugWidth'
  | 'Image'
  | 'Input'
  | 'Keyboard'
  | 'LetterSpacing'
  | 'Lightning'
  | 'LineSpacing'
  | 'Link'
  | 'LinkBroken'
  | 'LockChild'
  | 'LockClosed'
  | 'LockOpen'
  | 'MaxHeight'
  | 'MaxWidth'
  | 'MiddleOfPage'
  | 'MinHeight'
  | 'Minus'
  | 'MinWidth'
  | 'Mobile'
  | 'MobileH'
  | 'Moon'
  | 'Navigate'
  | 'Navigation'
  | 'Opacity'
  | 'Overflow'
  | 'OverlayClose'
  | 'OverlayOpen'
  | 'PaddingBottom'
  | 'PaddingH'
  | 'PaddingLeft'
  | 'PaddingRight'
  | 'PaddingSplit'
  | 'PaddingTop'
  | 'PaddingV'
  | 'Page'
  | 'PasswordLock'
  | 'Percent'
  | 'Play'
  | 'Plus'
  | 'PositionAbsolute'
  | 'PositionAuto'
  | 'PositionB'
  | 'PositionFixed'
  | 'PositionL'
  | 'PositionR'
  | 'PositionSticky'
  | 'PositionT'
  | 'PositionX'
  | 'PositionY'
  | 'PositionZ'
  | 'Projects'
  | 'Question'
  | 'RadiusBottomLeft'
  | 'RadiusBottomRight'
  | 'RadiusSplit'
  | 'RadiusTopLeft'
  | 'RadiusTopRight'
  | 'Rectangle'
  | 'Refresh'
  | 'RepaintLogo'
  | 'Rotate'
  | 'Row'
  | 'ScreenSizes'
  | 'ScrollAnchorBottom'
  | 'ScrollAnchorCenter'
  | 'ScrollAnchorTop'
  | 'ScrollBottomOffset'
  | 'ScrollLink'
  | 'ScrollProgress'
  | 'ScrollTopOffset'
  | 'Search'
  | 'Section'
  | 'Settings'
  | 'Settings2'
  | 'ShrinkHeight'
  | 'ShrinkWidth'
  | 'Smoothing'
  | 'Spread'
  | 'SpreadGap'
  | 'StateDefault'
  | 'StateHover'
  | 'StatePressed'
  | 'StickyGap'
  | 'Sun'
  | 'Swap'
  | 'Tablet'
  | 'Target'
  | 'Text'
  | 'TextAlignCenterH'
  | 'TextAlignLeft'
  | 'TextAlignRight'
  | 'TextFixedSize'
  | 'TextHug'
  | 'TextHugHeight'
  | 'ThreeDots'
  | 'TooltipArrow'
  | 'TopOfPage'
  | 'TransformRotate'
  | 'TransformScale'
  | 'TransformX'
  | 'TransformY'
  | 'Upload'
  | 'UploadImage'
  | 'User'
  | 'VisibleFalse'
  | 'VisibleTrue'
  | 'Warning'
  | 'Website'
  | 'Width'
  | 'Wrap'
  | 'X'
  | 'Zoom'

export type icon = React.FunctionComponent<React.SVGProps<SVGSVGElement>>

export type iconMap = { [key in IconKey]: icon }

export const IconMap: iconMap = {
  AlignBottom,
  AlignCenterH,
  AlignCenterV,
  AlignLeft,
  AlignRight,
  AlignTop,
  Anchor,
  AlignSelfBottom,
  AlignSelfCenterH,
  AlignSelfCenterV,
  AlignSelfLeft,
  AlignSelfRight,
  AlignSelfStretchH,
  AlignSelfStretchV,
  AlignSelfTop,
  AspectRatio,
  AutolayoutColumnBottomCenterSpread,
  AutolayoutColumnBottomLeftSpread,
  AutolayoutColumnBottomRightSpread,
  AutolayoutColumnCenter,
  AutolayoutColumnCenterCenterSpread,
  AutolayoutColumnCenterLeftSpread,
  AutolayoutColumnCenterRightSpread,
  AutolayoutColumnLeft,
  AutolayoutColumnRight,
  AutolayoutColumnTopCenterSpread,
  AutolayoutColumnTopLeftSpread,
  AutolayoutColumnTopRightSpread,
  AutolayoutEmpty,
  AutolayoutHorizontal,
  AutolayoutRowBottom,
  AutolayoutRowBottomCenterSpread,
  AutolayoutRowBottomLeftSpread,
  AutolayoutRowBottomRightSpread,
  AutolayoutRowCenter,
  AutolayoutRowCenterCenterSpread,
  AutolayoutRowCenterLeftSpread,
  AutolayoutRowCenterRightSpread,
  AutolayoutRowTop,
  AutolayoutRowTopCenterSpread,
  AutolayoutRowTopLeftSpread,
  AutolayoutRowTopRightSpread,
  AutolayoutVertical,
  Blur,
  BorderAll,
  BorderBottom,
  BorderLeft,
  BorderRight,
  BorderSplit,
  BorderTop,
  BorderWidth,
  BottomOfPage,
  Button,
  CheckboxChecked,
  CheckboxMixed,
  CheckboxUnchecked,
  ChevronLineDown,
  ChevronLineRight,
  Code,
  CollapseLayers,
  Column,
  ConstraintBottomCenter,
  ConstraintBottomLeft,
  ConstraintBottomRight,
  ConstraintBottomScale,
  ConstraintCenterCenter,
  ConstraintCenterLeft,
  ConstraintCenterRight,
  ConstraintCenterScale,
  ConstraintEmpty,
  ConstraintScaleCenter,
  ConstraintScaleLeft,
  ConstraintScaleRight,
  ConstraintScaleScale,
  ConstraintTopCenter,
  ConstraintTopLeft,
  ConstraintTopRight,
  ConstraintTopScale,
  Copy,
  Delay,
  Desktop,
  DisplayBlock,
  DisplayFlex,
  DisplayHidden,
  DistributeH,
  DistributeV,
  Download,
  DropdownArrowDown,
  DropdownArrowRight,
  DropdownArrowUp,
  Duration,
  Easing,
  Ellipse,
  Email,
  FillHeight,
  FillWidth,
  FixedHeight,
  FixedWidth,
  FlexGrowH,
  FlexGrowV,
  FlexResizeH,
  FlexResizeV,
  FlexShrinkH,
  FlexShrinkV,
  FontSize,
  Form,
  Frame,
  Fullscreen,
  Gap,
  Grid,
  GuidelineColumnCount,
  GuidelineMarginH,
  GuidelineMarginV,
  HandOpen,
  Height,
  Home,
  HugHeight,
  HugWidth,
  Image,
  Input,
  Keyboard,
  LetterSpacing,
  Lightning,
  LineSpacing,
  Link,
  LinkBroken,
  LockChild,
  LockClosed,
  LockOpen,
  MaxHeight,
  MaxWidth,
  MiddleOfPage,
  MinHeight,
  Minus,
  MinWidth,
  Mobile,
  MobileH,
  Moon,
  Navigate,
  Navigation,
  Opacity,
  Overflow,
  OverlayClose,
  OverlayOpen,
  PaddingBottom,
  PaddingH,
  PaddingLeft,
  PaddingRight,
  PaddingSplit,
  PaddingTop,
  PaddingV,
  Page,
  PasswordLock,
  Percent,
  Play,
  Plus,
  PositionAbsolute,
  PositionAuto,
  PositionB,
  PositionFixed,
  PositionL,
  PositionR,
  PositionSticky,
  PositionT,
  PositionX,
  PositionY,
  PositionZ,
  Projects,
  Question,
  RadiusBottomLeft,
  RadiusBottomRight,
  RadiusSplit,
  RadiusTopLeft,
  RadiusTopRight,
  Rectangle,
  Refresh,
  RepaintLogo,
  Rotate,
  Row,
  ScreenSizes,
  ScrollAnchorBottom,
  ScrollAnchorCenter,
  ScrollAnchorTop,
  ScrollBottomOffset,
  ScrollLink,
  ScrollProgress,
  ScrollTopOffset,
  Search,
  Section,
  Settings,
  Settings2,
  ShrinkHeight,
  ShrinkWidth,
  Smoothing,
  Spread,
  SpreadGap,
  StateDefault,
  StateHover,
  StatePressed,
  StickyGap,
  Sun,
  Swap,
  Tablet,
  Target,
  Text,
  TextAlignCenterH,
  TextAlignLeft,
  TextAlignRight,
  TextFixedSize,
  TextHug,
  TextHugHeight,
  ThreeDots,
  TooltipArrow,
  TopOfPage,
  TransformRotate,
  TransformScale,
  TransformX,
  TransformY,
  Upload,
  UploadImage,
  User,
  VisibleFalse,
  VisibleTrue,
  Warning,
  Website,
  Width,
  Wrap,
  X,
  Zoom,
}
