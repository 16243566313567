import { ReadOnlyDocument } from 'application/document'
import { CodeExport, DocumentTransformer } from 'application/export'

export class CodeExporter {
  private document: ReadOnlyDocument
  private transformer: DocumentTransformer

  constructor(document: ReadOnlyDocument) {
    this.document = document
    this.transformer = new DocumentTransformer()
  }

  export = (id: string): CodeExport | null => {
    return this.transformer.transform(this.document, id)
  }
}
