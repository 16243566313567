import {
  AttributeType,
  BaseMap,
  PLACEHOLDER_IMAGE,
} from 'application/attributes'
import { Node } from './node'
import { NewNodeSettings, NodeUpdateListener } from './types'
import { ProjectDocumentNode } from 'application/service'

const nodesWithChildren = ['root', 'canvas', 'page', 'frame', 'form']

export class NodeFactory {
  private listener: NodeUpdateListener

  constructor(listener: NodeUpdateListener) {
    this.listener = listener
  }

  createNode(settings: NewNodeSettings): Node | null {
    if (!settings.base.type) return null
    const hasChildren = nodesWithChildren.includes(settings.base.type)

    return new Node(
      settings.id,
      undefined,
      hasChildren ? [] : undefined,
      {
        ...this.getDefaultBase(settings.base.type),
        ...this.addTextContent(settings.base.type),
        ...this.addImageSrc(settings.base.type),
        ...settings.base,
      },
      {
        name: 'default',
        styles: { ...settings.style },
      },
      [],
      'default',
      'none',
      this.listener
    )
  }

  createLoadedNode(node: ProjectDocumentNode): Node {
    return new Node(
      node.id,
      node.parent,
      node.children,
      node.baseAttributes,
      node.defaultSelector,
      node.selectors,
      node.activeBreakpoint,
      node.activePseudo,
      this.listener
    )
  }

  private getDefaultBase(type: AttributeType): BaseMap {
    return {
      type: type,
      name: 'Node',
      nameOverridden: false,
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      'boundingBox.h': 0,
      'boundingBox.w': 0,
      'boundingBox.x': 0,
      'boundingBox.y': 0,
    }
  }

  private addTextContent(type: AttributeType): Partial<BaseMap> {
    switch (type) {
      case 'text':
        return { 'text.content': 'Text' }
      default:
        return {}
    }
  }

  private addImageSrc(type: AttributeType): Partial<BaseMap> {
    switch (type) {
      case 'image':
        return {
          'image.src': PLACEHOLDER_IMAGE,
          'image.originalSize.w': 800,
          'image.originalSize.h': 800,
        }
      default:
        return {}
    }
  }
}
