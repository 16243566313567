import { AttributeType, StyleMap } from 'application/attributes'
import {
  CopiedDefaultSelector,
  CopiedStyles,
  CopyStylesSnapshot,
} from '../types'

const pageAttributes: (keyof StyleMap)[] = [
  'background',
  'padding.top.unit',
  'padding.left.unit',
  'padding.right.unit',
  'padding.bottom.unit',
  'padding.top.px',
  'padding.left.px',
  'padding.right.px',
  'padding.bottom.px',
  'display',
  'flex.direction',
  'flex.align',
  'flex.justify',
  'flex.gap',
]

const frameAttributes: (keyof StyleMap)[] = [
  'background',
  'border.radius.topLeft.unit',
  'border.radius.topRight.unit',
  'border.radius.bottomLeft.unit',
  'border.radius.bottomRight.unit',
  'border.radius.topLeft.px',
  'border.radius.topRight.px',
  'border.radius.bottomLeft.px',
  'border.radius.bottomRight.px',
  'border.top.unit',
  'border.left.unit',
  'border.right.unit',
  'border.bottom.unit',
  'border.top.px',
  'border.right.px',
  'border.bottom.px',
  'border.left.px',
  'border.color',
  'padding.top.unit',
  'padding.right.unit',
  'padding.bottom.unit',
  'padding.left.unit',
  'padding.top.px',
  'padding.right.px',
  'padding.bottom.px',
  'padding.left.px',
  'display',
  'flex.direction',
  'flex.align',
  'flex.justify',
  'flex.gap',
  'shadows',
  'filter.mode',
  'filter.blur.radius',
  'transition.mode',
  'transition.duration',
  'transition.timing',
]

const textAttributes: (keyof StyleMap)[] = [
  'text.align',
  'text.font.family',
  'text.font.weight',
  'text.font.size',
  'text.letterSpacing',
  'text.lineHeight',
  'text.color',
  'transition.mode',
  'transition.duration',
  'transition.timing',
]

const inputAttributes: (keyof StyleMap)[] = [
  'background',
  'border.radius.topLeft.unit',
  'border.radius.topRight.unit',
  'border.radius.bottomLeft.unit',
  'border.radius.bottomRight.unit',
  'border.radius.topLeft.px',
  'border.radius.topRight.px',
  'border.radius.bottomLeft.px',
  'border.radius.bottomRight.px',
  'border.top.unit',
  'border.left.unit',
  'border.right.unit',
  'border.bottom.unit',
  'border.top.px',
  'border.right.px',
  'border.bottom.px',
  'border.left.px',
  'border.color',
  'padding.top.unit',
  'padding.right.unit',
  'padding.bottom.unit',
  'padding.left.unit',
  'padding.top.px',
  'padding.right.px',
  'padding.bottom.px',
  'padding.left.px',
  'display',
  'shadows',
  'transition.mode',
  'transition.duration',
  'transition.timing',
  'text.align',
  'text.font.family',
  'text.font.weight',
  'text.font.size',
  'text.letterSpacing',
  'text.lineHeight',
  'text.color',
  'transition.mode',
  'transition.duration',
  'transition.timing',
]

const imageAttributes: (keyof StyleMap)[] = [
  'border.radius.topRight.unit',
  'border.radius.topLeft.unit',
  'border.radius.bottomRight.unit',
  'border.radius.bottomLeft.unit',
  'border.radius.topRight.px',
  'border.radius.topLeft.px',
  'border.radius.bottomRight.px',
  'border.radius.bottomLeft.px',
  'image.resize',
  'transition.mode',
  'transition.duration',
  'transition.timing',
]

export class PasteAttributesFilter {
  filter = (
    type: AttributeType,
    copyAttributesSnapshot: CopyStylesSnapshot
  ): CopyStylesSnapshot => {
    const filteredDefaultSelector: CopiedDefaultSelector = {
      ...copyAttributesSnapshot.defaultSelector,
      styles: this.filterAttributes(
        type,
        copyAttributesSnapshot.defaultSelector.styles
      ),
    }
    const filteredSelectors = copyAttributesSnapshot.selectors.map((s) => {
      return {
        name: s.name,
        styles: this.filterAttributes(type, s.styles),
      }
    })

    return {
      defaultSelector: filteredDefaultSelector,
      selectors: filteredSelectors,
    }
  }

  private getAttributesList = (type: AttributeType): (keyof StyleMap)[] => {
    switch (type) {
      case 'page':
        return pageAttributes
      case 'frame':
      case 'form':
        return frameAttributes
      case 'text':
        return textAttributes
      case 'input':
        return inputAttributes
      case 'image':
        return imageAttributes
      default:
        return []
    }
  }

  private getAttributeKey = (key: keyof CopiedStyles): keyof StyleMap => {
    return key
  }

  private filterAttributes = (
    type: AttributeType,
    attributes: Partial<StyleMap>
  ): Partial<StyleMap> => {
    const filteredAttributes: Partial<StyleMap> = {}
    const attributesList = this.getAttributesList(type)

    for (const key of Object.keys(attributes)) {
      const copiedKey = key as keyof CopiedStyles
      const attributeKey = this.getAttributeKey(copiedKey)
      if (attributesList.includes(attributeKey)) {
        this.setAttributeMapValue(
          attributeKey,
          attributes[copiedKey],
          filteredAttributes
        )
      }
    }

    return filteredAttributes
  }

  private setAttributeMapValue<K extends keyof StyleMap>(
    key: K,
    value: StyleMap[K],
    attributes: Partial<StyleMap>
  ): void {
    attributes[key] = value
  }
}
