export enum CssUnit {
  unit_none = 0x1,
  unit_px = 0x2,
  unit_percent = 0x4,
  unit_vw = 0x8,
  unit_vh = 0x10,
}

export enum HtmlTag {
  tag_div = 0x1,
  tag_p = 0x2,
  tag_img = 0x4,
}

export enum LayoutItemType {
  block = 0x1,
  flex_row = 0x2,
  flex_column = 0x4,
  text = 0x8,
  image = 0x10,
  input = 0x20,
}

export enum CssDisplay {
  display_none = 0x1,
  display_block = 0x2,
  display_flex = 0x4,
}

export enum CssPosition {
  position_static = 0x1,
  position_relative = 0x2,
  position_absolute = 0x4,
  position_fixed = 0x8,
  position_sticky = 0x10,
}

export enum CssFlexDirection {
  row = 0x1,
  column = 0x2,
}

export enum CssFlexWrap {
  nowrap = 0x1,
  wrap = 0x2,
}

export enum CssFlexAlign {
  auto = 0x1,
  start = 0x2,
  end = 0x3,
  center = 0x4,
  stretch = 0x10,
}

export enum CssFlexJustify {
  start = 0x1,
  end = 0x2,
  center = 0x4,
  space_between = 0x10,
}

export enum CssOverflow {
  overflow_visible = 0x1,
  overflow_hidden = 0x2,
  overflow_scroll = 0x4,
  overflow_auto = 0x10,
}

export enum SizeValueType {
  absolute = 0x1,
  exact = 0x2,
  auto = 0x4,
  min_content = 0x8,
  max_content = 0x10,
}

export type TypedValue = {
  type: SizeValueType
  value: number
}
