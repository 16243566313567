import { Tag } from 'application/export/html/types'
import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import { AttributeProps } from '../AttributeSettings'

export default function TextAttributes({ settings, handlers }: AttributeProps) {
  return (
    <PanelRow>
      <TextBlock mode={'label2'}>Tag</TextBlock>
      <Dropdown
        options={tagOptions}
        selected={settings.tag}
        select={(v) => {
          if (v === 'Mixed') return
          handlers.setTag(v)
        }}
        commit={true}
      />
    </PanelRow>
  )
}

const tagOptions: dropdownOption<Tag>[] = [
  { text: '<p>', value: 'p' },
  { text: '<h1>', value: 'h1' },
  { text: '<h2>', value: 'h2' },
  { text: '<h3>', value: 'h3' },
  { text: '<h4>', value: 'h4' },
  { text: '<h5>', value: 'h5' },
  { text: '<h6>', value: 'h6' },
]
