import ToolbarTextInput from 'components/Library/Components/Toolbar/ToolbarTextInput/ToolbarTextInput'
import { activeProjectAtom } from 'components/Pages/Projects/Projects'
import useBackendService from 'hooks/services/useBackendService'
import { useRecoilState } from 'recoil'

export default function ProjectNameInput() {
  const backendService = useBackendService()
  const [project, setProject] = useRecoilState(activeProjectAtom)

  if (!project) return <></>

  const updateName = async (v: string) => {
    const updated = await backendService.updateProject(project.id, {
      name: v,
    })
    setProject(updated)
  }

  return <ToolbarTextInput value={project.name} setValue={updateName} />
}
