import TitleButtonBlock from 'components/Library/Components/Button/TitleButtonBlock/TitleButtonBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import TextAttributes from './TextAttributes/TextAttributes'
import {
  HTMLAttributePanelHandlers,
  HTMLAttributePanelState,
} from 'editor/ui/htmlAttributePanel'
import { AttributeType } from 'application/attributes'
import ImageAttributes from './ImageAttributes/ImageAttributes'
import InputAttributes from './InputAttributes/TextAttributes'

export interface AttributeProps {
  settings: HTMLAttributePanelState
  handlers: HTMLAttributePanelHandlers
}

export default function AttributeSettings() {
  const attributePanel = usePanelHelper(editor.getUI().getHTMLAttributePanel())
  const { settings, handlers } = attributePanel
  if (!settings || settings.attributes === 'Mixed') return null

  const { type } = settings
  if (type === 'Mixed' || !allowedTypes.includes(type)) return null

  return (
    <PanelSection>
      <PanelRow>
        <TitleButtonBlock title={'Settings'} active={true} />
      </PanelRow>
      <AttributeComponent type={type} settings={settings} handlers={handlers} />
    </PanelSection>
  )
}

function AttributeComponent({
  type,
  settings,
  handlers,
}: {
  type: AttributeType
  settings: HTMLAttributePanelState
  handlers: HTMLAttributePanelHandlers
}) {
  switch (type) {
    case 'text':
      return <TextAttributes settings={settings} handlers={handlers} />
    case 'image':
      return <ImageAttributes settings={settings} handlers={handlers} />
    case 'input':
      return <InputAttributes settings={settings} handlers={handlers} />
    default:
      return null
  }
}

const allowedTypes = ['text', 'image', 'input']
