import { Border } from '../outline/border'
import { Element } from '../element/element'
import { Padding } from '../outline/padding'
import { Position } from '../types/position'
import {
  PositionContext,
  PositionContextBuilder,
} from '../context/positionContext'
import { SizeContext } from '../context/sizeContext'
import {
  CssFlexAlign,
  CssFlexDirection,
  CssFlexWrap,
  CssOverflow,
  CssPosition,
  CssUnit,
  LayoutItemType,
  SizeValueType,
  TypedValue,
} from '../types/types'
import { CssLength } from '../css/cssLength'
import {
  ContainingContext,
  ContainingContextBuilder,
} from '../context/containingContext'
import { AbsoluteItem } from '../absolute/absoluteItem'
import { CachedItem } from './cachedItem'
import { SizeContextPool } from '../context/sizeContextPool'
import { ElementDiv } from '../element/elementDiv'

export abstract class LayoutItem extends CachedItem {
  id: string = 'none'
  element: Element = ElementDiv.create()
  children: LayoutItem[] = []
  position: Position = Position.create()
  padding: Padding = Padding.create()
  border: Border = Border.create()
  sizeContextPool: SizeContextPool = new SizeContextPool(1)

  reset(): void {
    this.id = 'none'
    this.element = ElementDiv.create()
    this.children = []
    this.position = Position.create()
    this.padding = Padding.create()
    this.border = Border.create()
    this.clearSizeCache()
    this.clearChildSizeCache()
  }

  getType = (): LayoutItemType => LayoutItemType.block

  getTop = (): number => this.position.y
  getLeft = (): number => this.position.x
  getBottom = (): number => this.position.y + this.position.height
  getRight = (): number => this.position.x + this.position.width

  getWidth = (): number => this.position.width
  getHeight = (): number => this.position.height

  setWidth = (width: number): void => {
    this.position.width = width
  }
  setHeight = (height: number): void => {
    this.position.height = height
  }

  getOutlineTop = (): number => this.padding.top + this.border.top
  getOutlineRight = (): number => this.padding.right + this.border.right
  getOutlineBottom = (): number => this.padding.bottom + this.border.bottom
  getOutlineLeft = (): number => this.padding.left + this.border.left

  getOutlineWidth = (): number => this.getOutlineRight() + this.getOutlineLeft()
  getOutlineHeight = (): number =>
    this.getOutlineBottom() + this.getOutlineTop()

  minMaxWidth = (value: number, context: SizeContext): number => {
    let maxWidth = Infinity
    let minWidth = 0

    const cssMaxWidth = this.element!.css.maxWidth
    if (cssMaxWidth.isDefined()) {
      maxWidth = cssMaxWidth.calcPercentage(context.width.value)
    }

    const cssMinWidth = this.element!.css.minWidth
    if (cssMinWidth.isDefined()) {
      minWidth = cssMinWidth.calcPercentage(context.width.value)
    }

    return Math.max(minWidth, Math.min(maxWidth, value))
  }

  minMaxHeight = (value: number, context: SizeContext): number => {
    let maxHeight = Infinity
    let minHeight = 0

    const cssMaxHeight = this.element!.css.maxHeight
    if (cssMaxHeight.isDefined()) {
      maxHeight = cssMaxHeight.calcPercentage(context.height.value)
    }

    const cssMinHeight = this.element!.css.minHeight
    if (cssMinHeight.isDefined()) {
      minHeight = cssMinHeight.calcPercentage(context.height.value)
    }

    return Math.max(minHeight, Math.min(maxHeight, value))
  }

  minMaxTransferredWidth = (value: number, context: SizeContext): number => {
    let maxTransferredWidth = Infinity
    let minTransferredWidth = 0

    const transferredMaxWidth = this.getTransferredMaxWidth(context)
    if (transferredMaxWidth !== undefined) {
      maxTransferredWidth = transferredMaxWidth
    }

    const transferredMinWidth = this.getTransferredMinWidth(context)
    if (transferredMinWidth !== undefined) {
      minTransferredWidth = transferredMinWidth
    }

    return Math.max(minTransferredWidth, Math.min(maxTransferredWidth, value))
  }

  minMaxTransferredHeight = (value: number, context: SizeContext): number => {
    let maxTransferredHeight = Infinity
    let minTransferredHeight = 0

    const transferredMaxHeight = this.getTransferredMaxHeight(context)
    if (transferredMaxHeight !== undefined) {
      maxTransferredHeight = transferredMaxHeight
    }

    const transferredMinHeight = this.getTransferredMinHeight(context)
    if (transferredMinHeight !== undefined) {
      minTransferredHeight = transferredMinHeight
    }

    return Math.max(minTransferredHeight, Math.min(maxTransferredHeight, value))
  }

  getMinWidth = (context: SizeContext): number | undefined => {
    const minWidth = this.element!.css.minWidth
    if (minWidth.isDefined()) {
      return minWidth.calcPercentage(context.width.value)
    }
    return undefined
  }

  getMinHeight = (context: SizeContext): number | undefined => {
    const minHeight = this.element!.css.minHeight
    if (minHeight.isDefined()) {
      return minHeight.calcPercentage(context.height.value)
    }
    return undefined
  }

  getMaxWidth = (context: SizeContext): number | undefined => {
    const maxWidth = this.element!.css.maxWidth
    if (maxWidth.isDefined()) {
      return maxWidth.calcPercentage(context.width.value)
    }
    return undefined
  }

  getMaxHeight = (context: SizeContext): number | undefined => {
    const maxHeight = this.element!.css.maxHeight
    if (maxHeight.isDefined()) {
      return maxHeight.calcPercentage(context.height.value)
    }
    return undefined
  }

  hasCssWidth = (): boolean => {
    return this.element!.css.width.isDefined()
  }

  hasCssHeight = (): boolean => {
    return this.element!.css.height.isDefined()
  }

  getRatio = (): number | undefined => this.element!.css.aspectRatio
  getOverflow = (): CssOverflow => this.element!.css.overflow

  getFlexGrow = (): number => this.element!.css.flexGrow
  getFlexShrink = (): number => this.element!.css.flexShrink
  getFlexBasis = (): CssLength => this.element!.css.flexBasis
  getAlignSelf = (): CssFlexAlign => this.element!.css.alignSelf

  getFlexDirection = (): CssFlexDirection => CssFlexDirection.row
  getFlexWrap = (): CssFlexWrap => CssFlexWrap.nowrap

  getGap = (context: ContainingContext): number => {
    const cssGap = this.element!.css.gap
    if (!cssGap.isDefined()) return 0

    return cssGap.calcPercentage(context.w)
  }

  isInFlow = (): boolean => {
    return (
      this.element!.css.position !== CssPosition.position_absolute &&
      this.element!.css.position !== CssPosition.position_fixed
    )
  }

  getPreferredTop = (
    containingContext: ContainingContext
  ): number | undefined => {
    const cssTop = this.element!.css.top
    if (!cssTop.isDefined()) return undefined

    switch (this.element!.css.position) {
      case CssPosition.position_static:
        return undefined
      case CssPosition.position_fixed:
        return cssTop.calcPercentage(containingContext.vh)
      default:
        return cssTop.calcPercentage(containingContext.h)
    }
  }

  getPreferredBottom = (
    containingContext: ContainingContext
  ): number | undefined => {
    const cssBottom = this.element!.css.bottom
    if (!cssBottom.isDefined()) return undefined

    switch (this.element!.css.position) {
      case CssPosition.position_static:
        return undefined
      case CssPosition.position_fixed:
        return cssBottom.calcPercentage(containingContext.vh)
      default:
        return cssBottom.calcPercentage(containingContext.h)
    }
  }

  getPreferredLeft = (
    containingContext: ContainingContext
  ): number | undefined => {
    const cssLeft = this.element!.css.left
    if (!cssLeft.isDefined()) return undefined

    switch (this.element!.css.position) {
      case CssPosition.position_static:
        return undefined
      case CssPosition.position_fixed:
        return cssLeft.calcPercentage(containingContext.vw)
      default:
        return cssLeft.calcPercentage(containingContext.w)
    }
  }

  getPreferredRight = (
    containingContext: ContainingContext
  ): number | undefined => {
    const cssRight = this.element!.css.right
    if (!cssRight.isDefined()) return undefined

    switch (this.element!.css.position) {
      case CssPosition.position_static:
        return undefined
      case CssPosition.position_fixed:
        return cssRight.calcPercentage(containingContext.vw)
      default:
        return cssRight.calcPercentage(containingContext.w)
    }
  }

  getConvertedTop = (context: ContainingContext): number | undefined => {
    return this.getPreferredTop(context)
  }

  getConvertedBottom = (context: ContainingContext): number | undefined => {
    const bottom = this.element!.css.bottom
    if (!bottom.isDefined()) return undefined

    switch (this.element!.css.position) {
      case CssPosition.position_static:
        return undefined
      case CssPosition.position_fixed:
        return context.vh - bottom.calcPercentage(context.vh)
      default:
        return context.h - bottom.calcPercentage(context.h)
    }
  }

  getConvertedLeft = (context: ContainingContext): number | undefined => {
    return this.getPreferredLeft(context)
  }

  getConvertedRight = (context: ContainingContext): number | undefined => {
    const right = this.element!.css.right
    if (!right.isDefined()) return undefined

    switch (this.element!.css.position) {
      case CssPosition.position_static:
        return undefined
      case CssPosition.position_fixed:
        return context.vw - right.calcPercentage(context.vw)
      default:
        return context.w - right.calcPercentage(context.w)
    }
  }

  getPreferredWidth = (context: SizeContext): number | undefined => {
    if (context.width.type === SizeValueType.exact) {
      return context.width.value
    }

    const cssWidth = this.element!.css.width
    if (
      cssWidth.isDefined() &&
      (cssWidth.getUnit() !== CssUnit.unit_percent ||
        context.width.type === SizeValueType.absolute)
    ) {
      return cssWidth.calcPercentage(context.width.value)
    }

    return undefined
  }

  getPreferredHeight = (context: SizeContext): number | undefined => {
    if (context.height.type === SizeValueType.exact) {
      return context.height.value
    }

    const cssHeight = this.element!.css.height
    if (
      cssHeight.isDefined() &&
      (cssHeight.getUnit() !== CssUnit.unit_percent ||
        context.height.type === SizeValueType.absolute)
    ) {
      return cssHeight.calcPercentage(context.height.value)
    }

    return undefined
  }

  getTransferredWidth = (context: SizeContext): number | undefined => {
    const preferredHeight = this.getPreferredHeight(context)
    if (preferredHeight === undefined) return undefined

    const ratio = this.getRatio()
    if (ratio === undefined) return undefined

    return preferredHeight * ratio
  }

  getTransferredHeight = (context: SizeContext): number | undefined => {
    const preferredWidth = this.getPreferredWidth(context)
    if (preferredWidth === undefined) return undefined

    const ratio = this.getRatio()
    if (ratio === undefined) return undefined

    return preferredWidth / ratio
  }

  getTransferredMinWidth = (context: SizeContext): number | undefined => {
    const minHeight = this.getMinHeight(context)
    if (minHeight === undefined) return undefined

    const ratio = this.getRatio()
    if (ratio === undefined) return undefined

    return minHeight * ratio
  }

  getTransferredMinHeight = (context: SizeContext): number | undefined => {
    const minWidth = this.getMinWidth(context)
    if (minWidth === undefined) return undefined

    const ratio = this.getRatio()
    if (ratio === undefined) return undefined

    return minWidth / ratio
  }

  getTransferredMaxWidth = (context: SizeContext): number | undefined => {
    const maxHeight = this.getMaxHeight(context)
    if (maxHeight === undefined) return undefined

    const ratio = this.getRatio()
    if (ratio === undefined) return undefined

    return maxHeight * ratio
  }

  getTransferredMaxHeight = (context: SizeContext): number | undefined => {
    const maxWidth = this.getMaxWidth(context)
    if (maxWidth === undefined) return undefined

    const ratio = this.getRatio()
    if (ratio === undefined) return undefined

    return maxWidth / ratio
  }

  getMinContentWidth = (context: SizeContext): number => {
    const preferredWidth = this.getPreferredWidth(context)
    if (preferredWidth !== undefined) {
      return this.minMaxWidth(preferredWidth, context)
    }

    const transferredWidth = this.getTransferredWidth(context)
    if (transferredWidth !== undefined) {
      return this.minMaxWidth(
        this.minMaxTransferredWidth(transferredWidth, context),
        context
      )
    }

    return this.minMaxWidth(
      this.getMinContentContributionWidth(context),
      context
    )
  }

  getMinContentHeight = (context: SizeContext): number => {
    const preferredHeight = this.getPreferredHeight(context)
    if (preferredHeight !== undefined) {
      return this.minMaxHeight(preferredHeight, context)
    }

    const transferredHeight = this.getTransferredHeight(context)
    if (transferredHeight !== undefined) {
      return this.minMaxHeight(
        this.minMaxTransferredHeight(transferredHeight, context),
        context
      )
    }

    return this.minMaxHeight(
      this.getMinContentContributionHeight(context),
      context
    )
  }

  getMaxContentWidth = (context: SizeContext): number => {
    const preferredWidth = this.getPreferredWidth(context)
    if (preferredWidth !== undefined) {
      return this.minMaxWidth(preferredWidth, context)
    }

    const transferredWidth = this.getTransferredWidth(context)
    if (transferredWidth !== undefined) {
      return this.minMaxWidth(
        this.minMaxTransferredWidth(transferredWidth, context),
        context
      )
    }

    return this.minMaxWidth(
      this.getMaxContentContributionWidth(context),
      context
    )
  }

  getMaxContentHeight = (context: SizeContext): number => {
    const preferredHeight = this.getPreferredHeight(context)
    if (preferredHeight !== undefined) {
      return this.minMaxHeight(preferredHeight, context)
    }

    const transferredHeight = this.getTransferredHeight(context)
    if (transferredHeight !== undefined) {
      return this.minMaxHeight(
        this.minMaxTransferredHeight(transferredHeight, context),
        context
      )
    }

    return this.minMaxHeight(
      this.getMaxContentContributionHeight(context),
      context
    )
  }

  getMinContentContributionWidth = (context: SizeContext): number => {
    const minContentWidth = this.sizeContextPool!.fromContext(context)
    minContentWidth.width.value = 0
    minContentWidth.width.type = SizeValueType.min_content

    const cachedSize = this.getCachedSize(minContentWidth)
    if (cachedSize !== undefined) {
      this.sizeContextPool!.release(minContentWidth)
      return cachedSize[0]
    }

    this.resize(minContentWidth)

    this.cacheSize(minContentWidth)

    this.sizeContextPool!.release(minContentWidth)

    return this.position.width
  }

  getMinContentContributionHeight = (context: SizeContext): number => {
    const minContentHeight = this.sizeContextPool!.fromContext(context)
    minContentHeight.height.value = 0
    minContentHeight.height.type = SizeValueType.min_content

    const cachedSize = this.getCachedSize(minContentHeight)
    if (cachedSize !== undefined) {
      this.sizeContextPool!.release(minContentHeight)
      return cachedSize[1]
    }

    this.resize(minContentHeight)

    this.cacheSize(minContentHeight)

    this.sizeContextPool!.release(minContentHeight)

    return this.position.height
  }

  getMaxContentContributionWidth = (context: SizeContext): number => {
    const maxContentWidth = this.sizeContextPool!.fromContext(context)
    maxContentWidth.width.value = 0
    maxContentWidth.width.type = SizeValueType.max_content

    const cachedSize = this.getCachedSize(maxContentWidth)
    if (cachedSize !== undefined) {
      this.sizeContextPool!.release(maxContentWidth)
      return cachedSize[0]
    }

    this.resize(maxContentWidth)

    this.cacheSize(maxContentWidth)

    this.sizeContextPool!.release(maxContentWidth)

    return this.position.width
  }

  getMaxContentContributionHeight = (context: SizeContext): number => {
    const maxContentHeight = this.sizeContextPool!.fromContext(context)
    maxContentHeight.height.value = 0
    maxContentHeight.height.type = SizeValueType.max_content

    const cachedSize = this.getCachedSize(maxContentHeight)
    if (cachedSize !== undefined) {
      this.sizeContextPool!.release(maxContentHeight)
      return cachedSize[1]
    }

    this.resize(maxContentHeight)

    this.cacheSize(maxContentHeight)

    this.sizeContextPool!.release(maxContentHeight)

    return this.position.height
  }

  getFitContentWidth = (context: SizeContext): TypedValue => {
    switch (context.width.type) {
      case SizeValueType.exact:
      case SizeValueType.absolute:
        return {
          type: SizeValueType.absolute,
          value: context.width.value,
        }
      case SizeValueType.min_content:
        return {
          type: SizeValueType.absolute,
          value: 0,
        }
      case SizeValueType.max_content:
      case SizeValueType.auto:
        return {
          type: SizeValueType.auto,
          value: 0,
        }
    }
  }

  abstract resize(context: SizeContext): void
  abstract place(context: PositionContext): void

  protected cacheSizes = (context: SizeContext): void => {
    this.cacheSize(context)
    this.cacheChildSizes(context, this.children)
  }

  protected resizeCached = (context: SizeContext): boolean => {
    const cachedSize = this.getCachedSize(context)
    if (!cachedSize) return false

    const cachedSizes = this.getCachedChildSizes(context)
    if (!cachedSizes) return false

    this.setWidth(cachedSize[0])
    this.setHeight(cachedSize[1])

    for (let i = 0; i < this.children.length; i++) {
      const cachedSize = cachedSizes[i]

      const childContext = this.sizeContextPool!.fromContext(context)
      childContext.width.type = SizeValueType.exact
      childContext.width.value = cachedSize[0]
      childContext.height.type = SizeValueType.exact
      childContext.height.value = cachedSize[1]

      this.children[i].resize(childContext)

      this.sizeContextPool!.release(childContext)
    }

    return true
  }

  protected computeOutline = (context: SizeContext): void => {
    this.border.top = this.element!.css.borderTop.calcPercentage(
      context.width.value
    )
    this.border.bottom = this.element!.css.borderBottom.calcPercentage(
      context.width.value
    )
    this.border.left = this.element!.css.borderLeft.calcPercentage(
      context.width.value
    )
    this.border.right = this.element!.css.borderRight.calcPercentage(
      context.width.value
    )

    this.padding.top = this.element!.css.paddingTop.calcPercentage(
      context.width.value
    )
    this.padding.bottom = this.element!.css.paddingBottom.calcPercentage(
      context.width.value
    )
    this.padding.left = this.element!.css.paddingLeft.calcPercentage(
      context.width.value
    )
    this.padding.right = this.element!.css.paddingRight.calcPercentage(
      context.width.value
    )
  }

  protected createChildSizeContext = (context: SizeContext): SizeContext => {
    const childContext = this.sizeContextPool!.fromContext(context)

    switch (context.width.type) {
      case SizeValueType.exact:
        childContext.width.type = SizeValueType.absolute
        childContext.width.value = context.width.value - this.getOutlineWidth()
        break
      case SizeValueType.min_content:
        childContext.width.type = SizeValueType.absolute
        childContext.width.value = 0
        break
      case SizeValueType.absolute:
      case SizeValueType.auto:
        const preferredWidth = this.getPreferredWidth(context)
        if (preferredWidth !== undefined) {
          childContext.width.type = SizeValueType.absolute
          childContext.width.value =
            this.minMaxWidth(preferredWidth, context) - this.getOutlineWidth()
          break
        }

        const transferredWidth = this.getTransferredWidth(context)
        if (transferredWidth !== undefined) {
          childContext.width.type = SizeValueType.absolute
          childContext.width.value =
            this.minMaxWidth(
              this.minMaxTransferredWidth(transferredWidth, context),
              context
            ) - this.getOutlineWidth()
          break
        }

        childContext.width.type = context.width.type
        childContext.width.value = Math.max(
          0,
          context.width.value - this.getOutlineWidth()
        )
        break
      case SizeValueType.max_content:
        childContext.width.type = SizeValueType.auto
        childContext.width.value = 0
        break
    }

    switch (context.height.type) {
      case SizeValueType.exact:
        childContext.height.type = SizeValueType.absolute
        childContext.height.value =
          context.height.value - this.getOutlineHeight()
        break
      case SizeValueType.absolute:
      case SizeValueType.auto:
        const preferredHeight = this.getPreferredHeight(context)
        if (preferredHeight !== undefined) {
          childContext.height.type = SizeValueType.absolute
          childContext.height.value =
            this.minMaxHeight(preferredHeight, context) -
            this.getOutlineHeight()
          break
        }

        const transferredHeight = this.getTransferredHeight(context)
        if (transferredHeight !== undefined) {
          childContext.height.type = SizeValueType.absolute
          childContext.height.value =
            this.minMaxHeight(
              this.minMaxTransferredHeight(transferredHeight, context),
              context
            ) - this.getOutlineHeight()
          break
        }

        childContext.height.type = context.height.type
        childContext.height.value = Math.max(
          0,
          context.height.value - this.getOutlineHeight()
        )
        break
      case SizeValueType.min_content:
        childContext.height.type = SizeValueType.absolute
        childContext.height.value = 0
        break
      case SizeValueType.max_content:
        childContext.height.type = SizeValueType.auto
        childContext.height.value = 0
        break
    }

    return childContext
  }

  protected placeSelf = (context: PositionContext): void => {
    const containingContext = ContainingContext.fromPositionContext(context)

    const top = this.getPreferredTop(containingContext)
    const bottom = this.getPreferredBottom(containingContext)

    const left = this.getPreferredLeft(containingContext)
    const right = this.getPreferredRight(containingContext)

    const height = this.position.height
    const width = this.position.width

    this.position.x = context.x
    this.position.y = context.y

    switch (this.element!.css.position) {
      case CssPosition.position_relative:
        if (top !== undefined) {
          this.position.y = context.y + top
        } else if (bottom !== undefined) {
          this.position.y = context.y - bottom
        }
        if (left !== undefined) {
          this.position.x = context.x + left
        } else if (right !== undefined) {
          this.position.x = context.x - right
        }
        break
      case CssPosition.position_absolute:
        if (top !== undefined) {
          this.position.y = context.top + top
        } else if (bottom !== undefined) {
          this.position.y = context.bottom - bottom - height
        }
        if (left !== undefined) {
          this.position.x = context.left + left
        } else if (right !== undefined) {
          this.position.x = context.right - right - width
        }
        break
      case CssPosition.position_fixed:
        if (top !== undefined) {
          this.position.y = context.vy + top
        } else if (bottom !== undefined) {
          this.position.y = context.vh + context.vy - bottom - height
        }
        if (left !== undefined) {
          this.position.x = context.vx + left
        } else if (right !== undefined) {
          this.position.x = context.vw + context.vx - right - width
        }
        break
      case CssPosition.position_sticky:
        if (top !== undefined) {
          this.position.y = Math.max(top, context.y)
        } else if (bottom !== undefined) {
          this.position.y = Math.min(context.vh - bottom - height, context.y)
        }
        if (left !== undefined) {
          this.position.x = Math.max(left, context.x)
        } else if (right !== undefined) {
          this.position.x = Math.min(context.vw - right - width, context.x)
        }
        break
    }
  }

  protected createChildPositionContext = (
    context: PositionContext
  ): PositionContext => {
    const positionContextBuilder = PositionContextBuilder.create(context)

    switch (this.element!.css.position) {
      case CssPosition.position_static:
        positionContextBuilder
          .withY(this.getTop() + this.getOutlineTop())
          .withX(this.getLeft() + this.getOutlineLeft())
        break
      default:
        positionContextBuilder
          .withBlock(
            this.getTop(),
            this.getLeft(),
            this.getRight(),
            this.getBottom()
          )
          .withY(this.getTop() + this.getOutlineTop())
          .withX(this.getLeft() + this.getOutlineLeft())
    }

    return positionContextBuilder.build()
  }

  protected createChildContainingContext = (
    positionContext: PositionContext
  ): ContainingContext => {
    return ContainingContextBuilder.create()
      .withVW(positionContext.vw)
      .withVH(positionContext.vh)
      .withW(this.getWidth())
      .withH(this.getHeight())
      .build()
  }

  protected sizeOutOfFlowItems = (context: SizeContext): void => {
    const width = this.getWidth()
    const height = this.getHeight()
    const outOfFlowContext = this.sizeContextPool!.fromContext(context)
    outOfFlowContext.width.type = SizeValueType.absolute
    outOfFlowContext.width.value = width
    outOfFlowContext.height.type = SizeValueType.absolute
    outOfFlowContext.height.value = height

    for (const child of this.children) {
      if (!child.isInFlow()) {
        const absoluteItem = new AbsoluteItem(child)
        absoluteItem.resize(outOfFlowContext)
      }
    }

    this.sizeContextPool!.release(outOfFlowContext)
  }
}
