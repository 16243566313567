import { Delta, Point } from 'application/shapes'
import { InsertionStrategy } from '../creation'
import { QuadTree } from 'application/quadtree'
import { getInsertionIndex, ReadOnlyDocument } from 'application/document'
import {
  AttributeType,
  canTypeInsertInto,
  isInteractable,
} from 'application/attributes'
import { ReadOnlyDocumentSelection } from 'application/selection/types'
import { QuadTreeNodeFilter } from 'application/action/types'
import { truncate } from 'application/math'

export class ClickedNodeInsertionStrategy implements InsertionStrategy {
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private quadtree: QuadTree
  private type: AttributeType
  private point: Point
  private offset?: Delta

  constructor(
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    quadtree: QuadTree,
    type: AttributeType,
    point: Point,
    offset?: Delta
  ) {
    this.document = document
    this.documentSelection = documentSelection
    this.quadtree = quadtree
    this.type = type
    this.point = point
    this.offset = offset
  }

  getParent = (): string => {
    const rectanglesAtPoint = this.quadtree.getAtPoint(
      this.point,
      QuadTreeNodeFilter
    )
    const filtered = this.filterClickedNodes(rectanglesAtPoint.map((r) => r.id))

    if (filtered.length === 0) return this.documentSelection.getSelectedCanvas()

    return filtered[0]
  }

  getIndex = (): number | undefined => {
    const parentId = this.getParent()
    if (!parentId) return 0

    const parent = this.document.getNode(parentId)
    if (!parent || parent.getBaseAttribute('type') === 'canvas') return 0

    return getInsertionIndex(this.point, parent, this.document)
  }

  getPosition = (): Point => {
    return {
      x: truncate(this.point.x - (this.offset?.dx || 0)),
      y: truncate(this.point.y - (this.offset?.dy || 0)),
    }
  }

  private filterClickedNodes = (ids: string[]): string[] => {
    return ids.filter((id) => {
      const node = this.document.getNode(id)
      if (!node) return false

      return (
        isInteractable(node) &&
        canTypeInsertInto(this.type, node.getBaseAttribute('type'))
      )
    })
  }
}
