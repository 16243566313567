import {
  AttributeFill,
  AttributeType,
  BaseMap,
  isTextType,
  StyleMap,
} from 'application/attributes'
import { FontKey, FontWeight } from 'application/text'

export class TextAttributeFactory {
  private defaultColor: AttributeFill = {
    type: 'color',
    color: { r: 0, g: 0, b: 0, a: 1 },
  }
  private defaultFamily: FontKey = 'inter'
  private defaultWeight: FontWeight = 'regular'
  private defaultSize = 24

  createBase = (type: AttributeType): Partial<BaseMap> => {
    if (!isTextType(type)) return {}
    return { 'text.content': '' }
  }

  createStyle = (type: AttributeType): Partial<StyleMap> => {
    if (!isTextType(type)) return {}
    return {
      'text.color': this.defaultColor,
      'text.font.family': this.defaultFamily,
      'text.font.weight': this.defaultWeight,
      'text.font.size': this.defaultSize,
      'text.align': 'left',
      'text.letterSpacing': 0,
      'text.lineHeight': 0,
      'text.italic': false,
      'text.underline': false,
    }
  }

  setDefaultAttributes = (attributes: Partial<StyleMap>): void => {
    this.defaultColor = attributes['text.color'] || this.defaultColor
    this.defaultFamily = attributes['text.font.family'] || this.defaultFamily
    this.defaultWeight = attributes['text.font.weight'] || this.defaultWeight
    this.defaultSize = attributes['text.font.size'] || this.defaultSize
  }
}
