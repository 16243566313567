import { Style, UnitLinearGradient } from '../css'
import {
  AttributeFill,
  AttributeFlexAlign,
  AttributeFlexDirection,
} from 'application/attributes'

export function addFlexDirection(
  direction: AttributeFlexDirection,
  style: Style
): void {
  switch (direction) {
    case 'row':
      break
    case 'column':
      style.setProperty('flexDirection', 'column')
      break
    case 'wrap':
      style.setProperty('flexWrap', 'wrap')
      break
  }
}

export function addFlexAlign(main: AttributeFlexAlign, style: Style): void {
  switch (main) {
    case 'start':
      style.setProperty('alignItems', 'start')
      break
    case 'center':
      style.setProperty('alignItems', 'center')
      break
    case 'end':
      style.setProperty('alignItems', 'end')
      break
  }
}

export function addFlexJustify(
  counter: AttributeFlexAlign,
  style: Style
): void {
  switch (counter) {
    case 'start':
      style.setProperty('justifyContent', 'start')
      break
    case 'center':
      style.setProperty('justifyContent', 'center')
      break
    case 'end':
      style.setProperty('justifyContent', 'end')
      break
    case 'spaced':
      style.setProperty('justifyContent', 'space-between')
      break
  }
}

export function addGap(gap: number, style: Style): void {
  if (gap === 0) return
  style.setProperty('gap', { unit: 'px', value: gap })
}

export function addBackgroundToStyle(fill: AttributeFill, style: Style) {
  switch (fill.type) {
    case 'color':
      if (!fill.color) return
      style.setProperty('backgroundColor', fill.color)
      break
    case 'gradient':
      if (!fill.gradient) return
      const gradient: UnitLinearGradient = {
        angle: (fill.gradient.angle || 0) - 180,
        stops: [...fill.gradient.steps].map((s) => [s.color, s.position]),
      }
      style.setProperty('background', gradient)
      break
    case 'image':
      if (!fill.image) return
      style.setProperty('backgroundImage', { url: fill.image.src })
      switch (fill.image.resize) {
        case 'fill':
          style.setProperty('backgroundRepeat', 'no-repeat')
          style.setProperty('backgroundPosition', 'center')
          style.setProperty('backgroundSize', 'cover')
          break
        case 'fit':
          style.setProperty('backgroundRepeat', 'no-repeat')
          style.setProperty('backgroundPosition', 'center')
          style.setProperty('backgroundSize', 'contain')
          break
        case 'stretch':
          style.setProperty('backgroundRepeat', 'no-repeat')
          style.setProperty('backgroundPosition', 'center')
          style.setProperty('backgroundSize', [
            { unit: '%', value: 100 },
            { unit: '%', value: 100 },
          ])
          break
        case 'repeat':
          style.setProperty('backgroundRepeat', 'repeat')
          break
      }
      break
  }
}

export function addColorToStyle(fill: AttributeFill, style: Style): void {
  switch (fill.type) {
    case 'color':
      if (!fill.color) return
      style.setProperty('color', fill.color)
      break
    case 'gradient':
      if (!fill.gradient) return
      const gradient: UnitLinearGradient = {
        angle: (fill.gradient.angle || 0) - 180,
        stops: [...fill.gradient.steps].map((s) => [s.color, s.position]),
      }
      style.setProperty('background', gradient)
      style.setProperty('textFillColor', 'transparent')
      style.setProperty('webkitTextFillColor', 'transparent')
      style.setProperty('backgroundClip', 'text')
      style.setProperty('webkitBackgroundClip', 'text')
      break
  }
}

export function fontWeightToCSS(
  fontWeight: string
): 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 {
  switch (fontWeight) {
    case 'thin':
      return 100
    case 'extra-light':
      return 200
    case 'light':
      return 300
    case 'regular':
      return 400
    case 'medium':
      return 500
    case 'semi-bold':
      return 600
    case 'bold':
      return 700
    case 'extra-bold':
      return 800
    case 'black':
      return 900
    default:
      return 400
  }
}

export function fontFamilyToLineHeight(fontFamily: string): number {
  switch (fontFamily) {
    case 'abrilFatface':
      return 1.349
    case 'acme':
      return 1.266
    case 'alegreya':
      return 1.361
    case 'amaticSc':
      return 1.261
    case 'anton':
      return 1.50537109375
    case 'archivo':
      return 1.088
    case 'archivoNarrow':
      return 1.347
    case 'arial':
      return 1.14990234375
    case 'arimo':
      return 1.14990234375
    case 'arvo':
      return 1.23486328125
    case 'asap':
      return 1.146
    case 'balooPaaji2':
      return 1.771
    case 'bangers':
      return 1.064
    case 'barlow':
      return 1.2
    case 'barlowCondensed':
      return 1.2
    case 'bebasNeue':
      return 1.2
    case 'benchnine':
      return 1.337890625
    case 'bitter':
      return 1.2
    case 'breeSerif':
      return 1.358
    case 'bubblegumSans':
      return 1.163
    case 'bungee':
      return 1.2
    case 'cabin':
      return 1.215
    case 'cairo':
      return 1.874
    case 'catamaran':
      return 1.64
    case 'caveat':
      return 1.26
    case 'cinzel':
      return 1.348
    case 'comfortaa':
      return 1.115
    case 'comicSansMs':
      return 1.3935546875
    case 'concertOne':
      return 0.77880859375
    case 'cookie':
      return 1.109
    case 'cormorantGaramond':
      return 1.211
    case 'courgette':
      return 1.25
    case 'creteRound':
      return 1.276
    case 'crimsonText':
      return 1.2998046875
    case 'cuprum':
      return 1.155
    case 'dancingScript':
      return 1.2
    case 'dmSans':
      return 1.302
    case 'domine':
      return 1.14
    case 'ebGaramond':
      return 1.305
    case 'epilogue':
      return 1.025
    case 'exo2':
      return 1.2
    case 'firaSans':
      return 1.2
    case 'fjallaOne':
      return 1.2568359375
    case 'francoisOne':
      return 1.418
    case 'gloriaHallelujah':
      return 1.982421875
    case 'gothicA1':
      return 1.25
    case 'heebo':
      return 1.46875
    case 'hind':
      return 1.601
    case 'hindSiliguri':
      return 1.617
    case 'inconsolata':
      return 1.049
    case 'indieFlower':
      return 1.458984375
    case 'inter':
      return 1.2102272727272727
    case 'josefinSans':
      return 1
    case 'kanit':
      return 1.495
    case 'karla':
      return 1.169
    case 'lato':
      return 1.2
    case 'lekton':
      return 1
    case 'libreBaskerville':
      return 1.24
    case 'lobster':
      return 1.25
    case 'lobsterTwo':
      return 1.25
    case 'lora':
      return 1.28
    case 'martel':
      return 1.687
    case 'merienda':
      return 1.444
    case 'merriweather':
      return 1.257
    case 'merriweatherSans':
      return 1.257
    case 'monda':
      return 1.62890625
    case 'montserrat':
      return 1.219
    case 'mukta':
      return 1.662
    case 'mulish':
      return 1.255
    case 'nanumGothic':
      return 1.15
    case 'notoSans':
      return 1.362
    case 'notoSansKr':
      return 1.448
    case 'notoSansTc':
      return 1.448
    case 'notoSerif':
      return 1.362
    case 'nunito':
      return 1.364
    case 'oldStandardTt':
      return 1.236
    case 'onest':
      return 1.275
    case 'openSans':
      return 1.36181640625
    case 'orbitron':
      return 1.254
    case 'oswald':
      return 1.482
    case 'overpass':
      return 1.266
    case 'oxygen':
      return 1.2626953125
    case 'pacifico':
      return 1.756
    case 'pathwayGothicOne':
      return 1.152
    case 'patuaOne':
      return 1.219
    case 'permanentMarker':
      return 1.45703125
    case 'philosopher':
      return 1.12
    case 'play':
      return 1.157
    case 'playfairDisplay':
      return 1.333
    case 'poiretOne':
      return 1.17
    case 'poppins':
      return 1.5
    case 'prata':
      return 1.355
    case 'ptSans':
      return 1.294
    case 'ptSerif':
      return 1.325
    case 'quattrocento':
      return 1.108
    case 'quattrocentoSans':
      return 1.108
    case 'questrial':
      return 1.03
    case 'quicksand':
      return 1.25
    case 'rajdhani':
      return 1.276
    case 'raleway':
      return 1.174
    case 'righteous':
      return 1.24169921875
    case 'roboto':
      return 1.171875
    case 'robotoCondensed':
      return 1.171875
    case 'robotoSlab':
      return 1.31884765625
    case 'rokkitt':
      return 1.137
    case 'ropaSans':
      return 1.072
    case 'rubik':
      return 1.185
    case 'saira':
      return 1.574
    case 'sen':
      return 1.203125
    case 'shadowsIntoLight':
      return 1.6064453125
    case 'slabo27px':
      return 1.2222222222222223
    case 'spectral':
      return 1.522
    case 'teko':
      return 1.433
    case 'tinos':
      return 1.14990234375
    case 'titilliumWeb':
      return 1.521
    case 'ubuntu':
      return 1.149
    case 'varelaRound':
      return 1.204
    case 'vollkorn':
      return 1.393
    case 'workSans':
      return 1.173
    case 'yanoneKaffeesatz':
      return 0.992
    case 'zillaSlab':
      return 1.2
    default:
      return 1.25
  }
}

export function fontNameToCSS(fontName: string): string {
  switch (fontName) {
    case 'dancingScript':
      return 'Dancing Script'
    case 'creteRound':
      return 'Crete Round'
    case 'tinos':
      return 'Tinos'
    case 'mukta':
      return 'Mukta'
    case 'asap':
      return 'Asap'
    case 'teko':
      return 'Teko'
    case 'playfairDisplay':
      return 'Playfair Display'
    case 'libreBaskerville':
      return 'Libre Baskerville'
    case 'barlowCondensed':
      return 'Barlow Condensed'
    case 'crimsonText':
      return 'Crimson Text'
    case 'notoSansTc':
      return 'Noto Sans TC'
    case 'varelaRound':
      return 'Varela Round'
    case 'rajdhani':
      return 'Rajdhani'
    case 'oxygen':
      return 'Oxygen'
    case 'cormorantGaramond':
      return 'Cormorant Garamond'
    case 'prata':
      return 'Prata'
    case 'cinzel':
      return 'Cinzel'
    case 'benchnine':
      return 'BenchNine'
    case 'firaSans':
      return 'Fira Sans'
    case 'quicksand':
      return 'Quicksand'
    case 'robotoSlab':
      return 'Roboto Slab'
    case 'indieFlower':
      return 'Indie Flower'
    case 'martel':
      return 'Martel'
    case 'workSans':
      return 'Work Sans'
    case 'rokkitt':
      return 'Rokkitt'
    case 'acme':
      return 'Acme'
    case 'bungee':
      return 'Bungee'
    case 'merienda':
      return 'Merienda'
    case 'bitter':
      return 'Bitter'
    case 'concertOne':
      return 'Concert One'
    case 'arimo':
      return 'Arimo'
    case 'dmSans':
      return 'DM Sans'
    case 'lato':
      return 'Lato'
    case 'orbitron':
      return 'Orbitron'
    case 'slabo27px':
      return 'Slabo 27px'
    case 'lobster':
      return 'Lobster'
    case 'overpass':
      return 'Overpass'
    case 'anton':
      return 'Anton'
    case 'breeSerif':
      return 'Bree Serif'
    case 'saira':
      return 'Saira'
    case 'bebasNeue':
      return 'Bebas Neue'
    case 'patuaOne':
      return 'Patua One'
    case 'caveat':
      return 'Caveat'
    case 'philosopher':
      return 'Philosopher'
    case 'courgette':
      return 'Courgette'
    case 'shadowsIntoLight':
      return 'Shadows Into Light'
    case 'comfortaa':
      return 'Comfortaa'
    case 'quattrocentoSans':
      return 'Quattrocento Sans'
    case 'robotoCondensed':
      return 'Roboto Condensed'
    case 'merriweather':
      return 'Merriweather'
    case 'cairo':
      return 'Cairo'
    case 'spectral':
      return 'Spectral'
    case 'lobsterTwo':
      return 'Lobster Two'
    case 'cuprum':
      return 'Cuprum'
    case 'karla':
      return 'Karla'
    case 'permanentMarker':
      return 'Permanent Marker'
    case 'epilogue':
      return 'Epilogue'
    case 'cabin':
      return 'Cabin'
    case 'inconsolata':
      return 'Inconsolata'
    case 'titilliumWeb':
      return 'Titillium Web'
    case 'gloriaHallelujah':
      return 'Gloria Hallelujah'
    case 'onest':
      return 'Onest'
    case 'questrial':
      return 'Questrial'
    case 'merriweatherSans':
      return 'Merriweather Sans'
    case 'inter':
      return 'Inter'
    case 'abrilFatface':
      return 'Abril Fatface'
    case 'nunito':
      return 'Nunito'
    case 'oswald':
      return 'Oswald'
    case 'ptSans':
      return 'PT Sans'
    case 'lekton':
      return 'Lekton'
    case 'domine':
      return 'Domine'
    case 'hind':
      return 'Hind'
    case 'ptSerif':
      return 'PT Serif'
    case 'oldStandardTt':
      return 'Old Standard TT'
    case 'exo2':
      return 'Exo 2'
    case 'righteous':
      return 'Righteous'
    case 'zillaSlab':
      return 'Zilla Slab'
    case 'pathwayGothicOne':
      return 'Pathway Gothic One'
    case 'vollkorn':
      return 'Vollkorn'
    case 'rubik':
      return 'Rubik'
    case 'yanoneKaffeesatz':
      return 'Yanone Kaffeesatz'
    case 'alegreya':
      return 'Alegreya'
    case 'bubblegumSans':
      return 'Bubblegum Sans'
    case 'quattrocento':
      return 'Quattrocento'
    case 'francoisOne':
      return 'Francois One'
    case 'ubuntu':
      return 'Ubuntu'
    case 'poppins':
      return 'Poppins'
    case 'josefinSans':
      return 'Josefin Sans'
    case 'heebo':
      return 'Heebo'
    case 'barlow':
      return 'Barlow'
    case 'raleway':
      return 'Raleway'
    case 'arvo':
      return 'Arvo'
    case 'amaticSc':
      return 'Amatic SC'
    default:
      return fontName
  }
}
