import { CodeExporter } from 'editor/export/code'
import { AttributePanel } from './attributePanel'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { CommandHandler } from 'application/client'
import { NodeAttributesAction } from 'application/action/attributes'
import { ReadOnlyDocument } from 'application/document'
import { CodeBlockType, CodeExport } from 'application/export'

type CodePanelBaseKeys = never

type CodePanelStyleKeys = never

export type CodePanelState = {
  styleMode: CodeBlockType
  exportMode: CodeBlockType
  export: CodeExport | null
}

export type CodePanelHandlers = {
  setStyleMode: (mode: CodeBlockType) => void
  setExportMode: (mode: CodeBlockType) => void
}

export class CodePanel extends AttributePanel<
  CodePanelState,
  CodePanelHandlers,
  CodePanelStyleKeys,
  CodePanelBaseKeys
> {
  private styleMode: CodeBlockType = 'css'
  private exportMode: CodeBlockType = 'html'
  private exporter: CodeExporter

  constructor(
    commandHandler: CommandHandler,
    action: NodeAttributesAction,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    exporter: CodeExporter
  ) {
    super(commandHandler, action, document, documentSelection)
    this.exporter = exporter
  }

  getSettings = (): CodePanelState => {
    const codeExport = this.getExport()
    return {
      styleMode: this.styleMode,
      exportMode: this.exportMode,
      export: codeExport,
    }
  }

  getHandlers = (): CodePanelHandlers => {
    return {
      setStyleMode: this.setStyleMode,
      setExportMode: this.setExportMode,
    }
  }

  private setStyleMode = (mode: CodeBlockType): void => {
    this.styleMode = mode
  }

  private setExportMode = (mode: CodeBlockType): void => {
    this.exportMode = mode
  }

  private getExport = (): CodeExport | null => {
    const selected = this.documentSelection.getSelected()
    if (selected.length === 0) return null
    return this.exporter.export(selected[0].getId())
  }
}
