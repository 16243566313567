const publicURL = process.env.PUBLIC_URL

export const Cursors = {
  CanvasDefault: `url(${publicURL}/cursors/CanvasDefault.svg) 6 2, auto`,
  DrawPage: `url(${publicURL}/cursors/DrawPage.svg) 2 13, auto`,
  DrawFrame: `url(${publicURL}/cursors/DrawFrame.svg) 2 13, auto`,
  DrawText: `url(${publicURL}/cursors/DrawText.svg) 12 12, auto`,
  DrawImage: `url(${publicURL}/cursors/DrawImage.svg) 2 13, auto`,
  DrawRectangle: `url(${publicURL}/cursors/DrawRectangle.svg) 2 13, auto`,
  DrawEllipse: `url(${publicURL}/cursors/DrawEllipse.svg) 2 13, auto`,
  Target: `url(${publicURL}/cursors/Target.svg) 12 12, auto`,
  HandOpen: `url(${publicURL}/cursors/HandOpen.svg) 10 12, auto`,
  HandClosed: `url(${publicURL}/cursors/HandClosed.svg) 10 12, auto`,
}
