import {
  BackgroundSize2,
  Border2,
  Border3,
  BorderRadius4,
  BorderStyle,
  BoxShadow,
  BoxShadow2,
  BoxShadow3,
  BoxShadow4,
  CalcFunction,
  FilterBlur,
  FilterBrightness,
  FilterFunction,
  ImageFunction,
  Properties,
  PropertyOrder,
  Style,
  Transform,
  Transition,
  UnitColor,
  UnitDeg,
  UnitFlex2,
  UnitFlex3,
  UnitFlex3Auto,
  UnitLength,
  UnitLinearGradient,
  UnitPercent,
  UnitRGB,
  UnitSize,
} from './types'

export function propertiesToString(properties: Partial<Properties>): string {
  const result: string[] = []
  const keys = Object.keys(properties) as (keyof Properties)[]
  const sortedKeys = keys.sort(
    (a, b) => PropertyOrder.indexOf(a) - PropertyOrder.indexOf(b)
  )
  for (const key of sortedKeys) {
    const value = properties[key as keyof Properties]
    const cssKey = propertyToCSSKey[key as keyof Properties]
    if (cssKey) {
      result.push(`${cssKey}: ${transformValue(value)};`)
    }
  }
  return result.join(' ')
}

export function formatStyle(style: Style): string {
  const properties = propertiesToString(style.getProperties())
  if (Object.keys(properties).length === 0) return ''
  return style.getSelector().toWrappedString(properties)
}

function transformValue(v: any): string {
  if (typeof v === 'string') return v
  if (typeof v === 'number') return v.toString()
  if (isUnitSize(v)) return unitSizeToString(v)
  if (isUnitFlex2(v)) return unitFlex2ToString(v)
  if (isUnitFlex3(v)) return unitFlex3ToString(v)
  if (isUnitFlex3Auto(v)) return unitFlex3AutoToString(v)
  if (isUnitRBG(v)) return unitRGBToString(v)
  if (isUnitLinearGradient(v)) return unitLinearGradientToString(v)
  if (isFilterFunctionArray(v)) return filterFunctionArrayToString(v)
  if (isBoxShadowArray(v)) return boxShadowArrayToString(v)
  if (isBorder2(v)) return border2ToString(v)
  if (isBorder3(v)) return border3ToString(v)
  if (isBorderRadius4(v)) return borderRadius4ToString(v)
  if (isImageFunction(v)) return imageFunctionToString(v)
  if (isBackgroundSize2(v)) return backgroundSize2ToString(v)
  if (isTransition(v)) return transitionToString(v)
  if (isTransform(v)) return transformToString(v)
  return ''
}

function unitSizeToString(value: UnitSize): string {
  if (isCalcFunction(value)) {
    let result = ''
    for (let i = 0; i < value.values.length; i++) {
      result += unitSizeToString(value.values[i])
      const operator = value.operators[i]
      if (operator) result += ` ${operator} `
    }
    return `calc(${result})`
  }
  switch (value.unit) {
    case 'px':
    case 'em':
    case 'rem':
    case 'vh':
    case 'vw':
      return `${value.value}${value.unit}`
    case '%':
      return `${value.value}%`
  }
}

function unitFlex2ToString(value: UnitFlex2): string {
  return `${value[0]} ${value[1]}`
}

function unitFlex3ToString(value: UnitFlex3): string {
  return `${value[0]} ${value[1]} ${unitSizeToString(value[2])}`
}

function unitFlex3AutoToString(value: UnitFlex3Auto): string {
  return `${value[0]} ${value[1]} auto`
}

function unitRGBToString(value: UnitRGB): string {
  return `rgb(${Math.floor(value.r)}, ${Math.floor(value.g)}, ${Math.floor(value.b)}, ${Math.floor(value.a)})`
}

function unitColorToString(value: UnitColor): string {
  if (typeof value === 'string') return value
  return unitRGBToString(value)
}

function unitLinearGradientToString(value: UnitLinearGradient): string {
  return `linear-gradient(${value.angle}deg, ${value.stops
    .map(([color, stop]) => `${unitColorToString(color)} ${stop}%`)
    .join(', ')})`
}

function imageFunctionToString(value: ImageFunction): string {
  return `url(${value.url})`
}

function backgroundSize2ToString(value: BackgroundSize2): string {
  return `${unitSizeToString(value[0])} ${unitSizeToString(value[1])}`
}

function filterFunctionToString(value: FilterFunction): string {
  switch (value.type) {
    case 'blur':
      return `blur(${unitSizeToString(value.value)})`
    case 'brightness':
      return `brightness(${value.value})`
  }
}

function filterFunctionArrayToString(value: FilterFunction[]): string {
  return value.map(filterFunctionToString).join(' ')
}

function boxShadowToString(value: BoxShadow): string {
  let result = ''
  if (value.inset) result += 'inset '
  result += value.values.map((v) => unitSizeToString(v)).join(' ')
  result += ` ${unitColorToString(value.color)}`
  return result
}

function boxShadowArrayToString(value: BoxShadow[]): string {
  return value.map(boxShadowToString).join(', ')
}

function border2ToString(value: Border2): string {
  return `${value[0]} ${unitSizeToString(value[1])}`
}

function border3ToString(value: Border3): string {
  return `${value[0]} ${unitSizeToString(value[1])} ${unitColorToString(value[2])}`
}

function borderRadius4ToString(borderRadius: BorderRadius4): string {
  return borderRadius.map((v) => unitSizeToString(v)).join(' ')
}

function transitionToString(value: Transition): string {
  return `${value.property} ${value.duration}s ${value.timing}`
}

function transformToString(value: Transform): string {
  let result: string[] = []
  if (value.x !== undefined)
    result.push(`translateX(${unitSizeToString(value.x)})`)
  if (value.y !== undefined)
    result.push(`translateY(${unitSizeToString(value.y)})`)
  if (value.scale !== undefined) result.push(`scale(${value.scale})`)
  if (value.rotate !== undefined) result.push(`rotate(${value.rotate}deg)`)
  return result.join(' ')
}

export function isUnitLength(v: any): v is UnitLength {
  return (
    typeof v === 'object' &&
    v !== null &&
    'value' in v &&
    'unit' in v &&
    ['px', 'em', 'rem', 'vh', 'vw', '%'].includes(v.unit) &&
    typeof v.value === 'number'
  )
}

export function isUnitPercent(v: any): v is UnitPercent {
  return (
    typeof v === 'object' &&
    v !== null &&
    'value' in v &&
    'unit' in v &&
    v.unit === '%' &&
    typeof v.value === 'number'
  )
}

export function isUnitDeg(v: any): v is UnitDeg {
  return (
    typeof v === 'object' &&
    v !== null &&
    'value' in v &&
    'unit' in v &&
    v.unit === 'deg' &&
    typeof v.value === 'number'
  )
}

export function isCalcFunction(v: any): v is CalcFunction {
  return (
    typeof v === 'object' &&
    v !== null &&
    'operators' in v &&
    'values' in v &&
    Array.isArray(v.operators) &&
    Array.isArray(v.values) &&
    v.operators.length === v.values.length - 1 &&
    v.values.every(isUnitSize)
  )
}

export function isUnitSize(v: any): v is UnitSize {
  return isUnitLength(v) || isUnitPercent(v) || isCalcFunction(v)
}

export function isUnitFlex2(v: any): v is UnitFlex2 {
  return (
    Array.isArray(v) &&
    v.length === 2 &&
    typeof v[0] === 'number' &&
    typeof v[1] === 'number'
  )
}

export function isUnitFlex3(v: any): v is UnitFlex3 {
  return (
    Array.isArray(v) &&
    v.length === 3 &&
    typeof v[0] === 'number' &&
    typeof v[1] === 'number' &&
    isUnitLength(v[2])
  )
}

export function isUnitFlex3Auto(v: any): v is UnitFlex3Auto {
  return Array.isArray(v) && v.length === 3 && v[2] === 'auto'
}

export function isUnitRBG(v: any): v is UnitRGB {
  return (
    typeof v === 'object' &&
    v !== null &&
    'r' in v &&
    'g' in v &&
    'b' in v &&
    'a' in v &&
    typeof v.r === 'number' &&
    typeof v.g === 'number' &&
    typeof v.b === 'number' &&
    typeof v.a === 'number'
  )
}

export function isUnitColor(v: any): v is UnitColor {
  return isUnitRBG(v) || typeof v === 'string'
}

export function isUnitLinearGradient(v: any): v is UnitLinearGradient {
  return (
    typeof v === 'object' &&
    v !== null &&
    'angle' in v &&
    typeof v.angle === 'number' &&
    'stops' in v &&
    Array.isArray(v.stops)
  )
}

export function isImageFunction(v: any): v is ImageFunction {
  return (
    typeof v === 'object' &&
    v !== null &&
    'url' in v &&
    typeof v.url === 'string'
  )
}

export function isBackgroundSize2(v: any): v is BackgroundSize2 {
  return (
    Array.isArray(v) && v.length === 2 && isUnitSize(v[0]) && isUnitSize(v[1])
  )
}

export function isFilterBlur(v: any): v is FilterBlur {
  return (
    typeof v === 'object' &&
    v !== null &&
    'type' in v &&
    v.type === 'blur' &&
    'value' in v &&
    isUnitLength(v.value)
  )
}

export function isFilterBrightness(v: any): v is FilterBrightness {
  return (
    typeof v === 'object' &&
    v !== null &&
    'type' in v &&
    v.type === 'brightness' &&
    'value' in v &&
    typeof v.value === 'number'
  )
}

export function isFilterFunction(v: any): v is FilterFunction {
  return isFilterBlur(v) || isFilterBrightness(v)
}

export function isFilterFunctionArray(v: any): v is FilterFunction[] {
  return Array.isArray(v) && v.every(isFilterFunction)
}

export function isBoxShadow2(v: any): v is BoxShadow2 {
  return Array.isArray(v) && v.length === 2 && v.every(isUnitLength)
}

export function isBoxShadow3(v: any): v is BoxShadow3 {
  return Array.isArray(v) && v.length === 3 && v.every(isUnitLength)
}

export function isBoxShadow4(v: any): v is BoxShadow4 {
  return Array.isArray(v) && v.length === 4 && v.every(isUnitLength)
}

export function isBoxShadow(v: any): v is BoxShadow {
  return (
    typeof v === 'object' &&
    v !== null &&
    'inset' in v &&
    'values' in v &&
    'color' in v &&
    typeof v.inset === 'boolean' &&
    (isBoxShadow2(v.values) ||
      isBoxShadow3(v.values) ||
      isBoxShadow4(v.values)) &&
    isUnitColor(v.color)
  )
}

export function isBoxShadowArray(v: any): v is BoxShadow[] {
  return Array.isArray(v) && v.every(isBoxShadow)
}

export function isBorderStyle(v: any): v is BorderStyle {
  return ['solid', 'dotted', 'dashed'].includes(v)
}

export function isBorder2(v: any): v is Border2 {
  return (
    Array.isArray(v) &&
    v.length === 2 &&
    isBorderStyle(v[0]) &&
    isUnitLength(v[1])
  )
}

export function isBorder3(v: any): v is Border3 {
  return (
    Array.isArray(v) &&
    v.length === 3 &&
    isBorderStyle(v[0]) &&
    isUnitLength(v[1]) &&
    isUnitColor(v[2])
  )
}

export function isBorderRadius4(v: any): v is BorderRadius4 {
  return (
    Array.isArray(v) &&
    v.length === 4 &&
    isUnitSize(v[0]) &&
    isUnitSize(v[1]) &&
    isUnitSize(v[2]) &&
    isUnitSize(v[3])
  )
}

export function isTransition(v: any): v is Transition {
  return (
    typeof v === 'object' &&
    v !== null &&
    'property' in v &&
    'duration' in v &&
    'timing' in v &&
    typeof v.property === 'string' &&
    typeof v.duration === 'number' &&
    typeof v.timing === 'string'
  )
}

export function isTransform(v: any): v is Transform {
  return (
    typeof v === 'object' &&
    v !== null &&
    (!('x' in v) ||
      (typeof v.x === 'object' &&
        v.x !== null &&
        (isUnitLength(v.x) || isUnitPercent(v.x)))) &&
    (!('y' in v) ||
      (typeof v.y === 'object' &&
        v.y !== null &&
        (isUnitLength(v.y) || isUnitPercent(v.y)))) &&
    (!('rotate' in v) ||
      (typeof v.rotate === 'object' &&
        v.rotate !== null &&
        isUnitDeg(v.rotate))) &&
    (!('scale' in v) || typeof v.scale === 'number')
  )
}

const propertyToCSSKey: { [K in keyof Properties]: string } = {
  position: 'position',
  boxSizing: 'box-sizing',

  width: 'width',
  height: 'height',
  minWidth: 'min-width',
  minHeight: 'min-height',
  maxWidth: 'max-width',
  maxHeight: 'max-height',
  aspectRatio: 'aspect-ratio',

  margin: 'margin',
  padding: 'padding',
  paddingTop: 'padding-top',
  paddingRight: 'padding-right',
  paddingBottom: 'padding-bottom',
  paddingLeft: 'padding-left',

  top: 'top',
  right: 'right',
  bottom: 'bottom',
  left: 'left',

  display: 'display',
  flexDirection: 'flex-direction',
  flexWrap: 'flex-wrap',
  justifyContent: 'justify-content',
  alignItems: 'align-items',
  alignSelf: 'align-self',
  gap: 'gap',
  flex: 'flex',
  flexGrow: 'flex-grow',
  flexShrink: 'flex-shrink',
  flexBasis: 'flex-basis',
  order: 'order',

  overflow: 'overflow',
  overflowX: 'overflow-x',
  overflowY: 'overflow-y',

  zIndex: 'z-index',

  objectFit: 'object-fit',
  objectPosition: 'object-position',
  contain: 'contain',

  content: 'content',
  fontFamily: 'font-family',
  fontSize: 'font-size',
  fontStyle: 'font-style',
  fontWeight: 'font-weight',
  lineHeight: 'line-height',
  letterSpacing: 'letter-spacing',
  textAlign: 'text-align',
  textDecoration: 'text-decoration',
  whiteSpace: 'white-space',

  opacity: 'opacity',

  color: 'color',
  background: 'background',
  backgroundColor: 'background-color',
  backgroundImage: 'background-image',
  backgroundPosition: 'background-position',
  backgroundSize: 'background-size',
  backgroundRepeat: 'background-repeat',
  backgroundClip: 'background-clip',
  webkitBackgroundClip: '-webkit-background-clip',
  textFillColor: 'text-fill-color',
  webkitTextFillColor: '-webkit-text-fill-color',

  backdropFilter: 'backdrop-filter',
  webkitBackdropFilter: '-webkit-backdrop-filter',

  boxShadow: 'box-shadow',

  border: 'border',
  borderColor: 'border-color',
  borderTop: 'border-top',
  borderRight: 'border-right',
  borderBottom: 'border-bottom',
  borderLeft: 'border-left',
  borderTopColor: 'border-top-color',
  borderRightColor: 'border-right-color',
  borderBottomColor: 'border-bottom-color',
  borderLeftColor: 'border-left-color',
  borderRadius: 'border-radius',
  borderTopLeftRadius: 'border-top-left-radius',
  borderTopRightRadius: 'border-top-right-radius',
  borderBottomRightRadius: 'border-bottom-right-radius',
  borderBottomLeftRadius: 'border-bottom-left-radius',

  outline: 'outline',

  cursor: 'cursor',
  userSelect: 'user-select',
  pointerEvents: 'pointer-events',

  transition: 'transition',
  transitionDuration: 'transition-duration',
  transitionProperty: 'transition-property',
  transitionTimingFunction: 'transition-timing-function',

  transform: 'transform',
}
