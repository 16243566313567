import { formatStyle, Style, StyleMap } from './css'
import { Element } from './html/types'

export class DocumentRenderer {
  renderNode = (node: Element): string => {
    return node.toString()
  }

  renderNodes = (nodes: Element[]): string => {
    return nodes.map((node) => this.renderNode(node)).join('\n')
  }

  renderStyleMap = (styleMap: StyleMap): string => {
    return styleMap.toString()
  }

  renderStyles = (styles: Style[]): string => {
    return styles.map((style) => formatStyle(style)).join('\n')
  }
}
