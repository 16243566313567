import {
  AttributeFill,
  AttributeFillType,
  AttributeType,
  StyleMap,
  createNewWhiteFill,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'
import _ from 'lodash'

export type BackgroundPanelKeys = 'background'

export interface BackgroundPanelState {
  background: 'Mixed' | AttributeFill[] | null
  options: AttributeFillType[]
  mode: 'add' | 'remove' | 'none'
}

export interface BackgroundPanelHandlers {
  add: () => void
  remove: () => void
  set: (value: AttributeFill) => void
  clearMixed: () => void
}

interface NodeBackgroundAttributeHandler {
  getDefault: (node: ReadOnlyNode) => AttributeFill[]
  get: (node: ReadOnlyNode) => 'Mixed' | AttributeFill[]
  getOptions: () => AttributeFillType[]
  set: (node: ReadOnlyNode, background: AttributeFill[]) => Partial<StyleMap>
}

export class BackgroundPanel extends StyleAttributePanel<
  BackgroundPanelState,
  BackgroundPanelHandlers,
  BackgroundPanelKeys
> {
  private defaultHandler = new DefaultNodeBackgroundAttributeHandler()

  getSettings(): BackgroundPanelState {
    return {
      background: this.getBackground(),
      options: this.getOptions(),
      mode: this.getMode(),
    }
  }

  getHandlers(): BackgroundPanelHandlers {
    return {
      add: this.add,
      remove: this.remove,
      set: this.set,
      clearMixed: this.clearMixed,
    }
  }

  private getMode = (): 'add' | 'remove' | 'none' => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return 'none'

    let hasText = false
    let hasBackground = false
    for (const node of nodes) {
      const nodeBackground = node.getStyleAttribute('background')
      if (!nodeBackground) continue
      if (nodeBackground.length > 0) hasBackground = true

      const nodeType = node.getBaseAttribute('type')
      if (nodeType === 'text') hasText = true
    }

    if (hasText) return 'none'
    if (hasBackground) return 'remove'
    return 'add'
  }

  private getBackground = (): 'Mixed' | AttributeFill[] | null => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return null

    let fills: AttributeFill[] | null = null
    for (const node of nodes) {
      const handler = this.getHandler()
      const nodeBackground = handler.get(node)
      if (nodeBackground === 'Mixed') return 'Mixed'
      if (!fills) {
        fills = nodeBackground
      } else if (!_.isEqual(fills, nodeBackground)) {
        return 'Mixed'
      }
    }

    return fills
  }

  private getOptions = (): AttributeFillType[] => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return []

    const handler = this.getHandler()
    return handler.getOptions()
  }

  private add = (): void => {
    this.set(createNewWhiteFill())
    this.commit()
  }

  private remove = (): void => {
    this.set(null)
    this.commit()
  }

  private set = (value: AttributeFill | null): void => {
    this.setMulti({ background: value ? [value] : [] })
  }

  private clearMixed = (): void => {
    const options = this.getOptions()
    const nodes = this.getNodes()
    if (nodes.length === 0) return

    let fills: AttributeFill[] = []
    for (const node of nodes) {
      const handler = this.getHandler()
      const defaultFills = handler.getDefault(node)
      if (defaultFills.length === 0) continue
      if (defaultFills.some((f) => !options.includes(f.type))) continue
      fills = defaultFills
      break
    }

    for (const node of nodes) {
      const handler = this.getHandler()
      const newAttributes = handler.set(node, fills)
      this.setOne(node.getId(), newAttributes)
    }

    this.commit()
  }

  private getHandler = (): NodeBackgroundAttributeHandler => {
    return this.defaultHandler
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }
}

const allowedTypes: AttributeType[] = ['page', 'frame', 'input', 'form']

class DefaultNodeBackgroundAttributeHandler
  implements NodeBackgroundAttributeHandler
{
  getDefault = (node: ReadOnlyNode): AttributeFill[] => {
    const background = node.getStyleAttribute('background')
    if (!background) return []

    return background
  }

  get = (node: ReadOnlyNode): 'Mixed' | AttributeFill[] => {
    const background = node.getStyleAttribute('background')
    if (!background) return 'Mixed'

    return background
  }

  getOptions = (): AttributeFillType[] => {
    return ['color', 'gradient', 'image']
  }

  set = (_: ReadOnlyNode, fills: AttributeFill[]): Partial<StyleMap> => {
    return { background: fills }
  }
}
