import { Cursor } from 'application/cursor'

export type ActionEventResult = {
  done: boolean
  passthrough: boolean
  nextAction?: Action
}

export const Done = { done: true, passthrough: false }
export const DoneNext = (nextAction: Action): ActionEventResult => ({
  done: true,
  passthrough: false,
  nextAction: nextAction,
})
export const DonePassthrough = { done: true, passthrough: true }
export const NotDone = { done: false, passthrough: false }
export const NotDonePassthrough = { done: false, passthrough: true }

export interface ActionHandler {
  getType: () => Action
  getCursor?: () => Cursor
  init?: () => void
  onMouseDown?: (e: MouseEvent) => ActionEventResult
  onMouseUp?: (e: MouseEvent) => ActionEventResult
  onMouseMove?: (e: MouseEvent) => ActionEventResult
  onKeyDown?: (e: KeyboardEvent) => ActionEventResult
  onKeyUp?: (e: KeyboardEvent) => ActionEventResult
  onPaste?: (e: ClipboardEvent) => ActionEventResult
  onDragOver?: (e: DragEvent) => ActionEventResult
  onDrop?: (e: DragEvent) => ActionEventResult
  onWheel?: (e: WheelEvent) => ActionEventResult
  cancel?: () => void
}

export interface ActiveActionListener {
  onActiveAction(handler: ActionHandler | null): void
}

export type ActionDraw =
  | 'drawFrame'
  | 'drawPage'
  | 'drawText'
  | 'drawImage'
  | 'drawInput'
  | 'drawForm'

export type ActionDragTemplate = 'dragTemplate'

export type ActionCanvas =
  | 'move'
  | 'resize'
  | 'multiselect'
  | 'hand'
  | 'targeting'

export type ActionLibrary = 'openImageLibrary' | 'openTemplateLibrary'

export type ActionPanelDrag = 'dragLayers'

export type ActionInput = 'editInput'

export type ActionEditText = 'editText' | 'editTextInput'

export type ActionTarget = 'target'

export type Action =
  | ActionDraw
  | ActionDragTemplate
  | ActionCanvas
  | ActionLibrary
  | ActionPanelDrag
  | ActionInput
  | ActionEditText
  | ActionTarget
