import { FlexSizeItem } from './flexSizeItem'
import { SizeContext } from '../context/sizeContext'
import { CssFlexAlign, CssOverflow, SizeValueType } from '../types/types'

export class FlexSizeItemColumn extends FlexSizeItem {
  initializeMainAxis = (context: SizeContext): void => {
    this.grow = this.layoutItem!.getFlexGrow()
    this.shrink = this.layoutItem!.getFlexShrink()
    this.align = this.layoutItem!.getAlignSelf()

    const hasWidth = this.layoutItem!.hasCssWidth()
    this.stretch = !hasWidth && this.align === CssFlexAlign.stretch

    this.minContentMainSize = this.computeMinContentMainSize(context)
    this.maxContentMainSize = this.computeMaxContentMainSize(context)

    this.minSize = this.computeMinSize(context)
    this.maxSize = this.computeMaxSize(context)

    this.baseSize = this.computeBaseSize(context)
    this.mainSize = this.computeMainSize()
    this.computedSize = this.mainSize

    this.scaledFlexShrinkFactor = this.shrink * this.baseSize
  }

  applyMainAxisSize = (size: number, context: SizeContext): void => {
    const newContext = this.layoutItem!.sizeContextPool!.fromContext(context)
    newContext.height.value = size
    newContext.height.type = SizeValueType.exact

    this.layoutItem!.resize(newContext)

    this.layoutItem!.sizeContextPool!.release(newContext)
  }

  getCrossSize = (): number => {
    return this.layoutItem!.position.width
  }

  applyCrossAxisSize = (
    mainSize: number,
    crossSize: number,
    context: SizeContext
  ): void => {
    const minMaxCrossSize = this.layoutItem!.minMaxWidth(crossSize, context)

    const newContext = this.layoutItem!.sizeContextPool!.fromContext(context)
    newContext.height.value = mainSize
    newContext.height.type = SizeValueType.exact
    newContext.width.value = minMaxCrossSize
    newContext.width.type = SizeValueType.exact

    this.layoutItem!.resize(newContext)

    this.layoutItem!.sizeContextPool!.release(newContext)
  }

  private computeBaseSize = (context: SizeContext): number => {
    const basis = this.layoutItem!.getFlexBasis()
    if (basis.isDefined()) {
      return basis.calcPercentage(context.height.value)
    }

    const height = this.layoutItem!.getPreferredHeight(context)
    if (height !== undefined) {
      return height
    }

    const transferredHeight = this.layoutItem!.getTransferredHeight(context)
    if (transferredHeight !== undefined) {
      return this.layoutItem!.minMaxTransferredHeight(
        transferredHeight,
        context
      )
    }

    const transferredMinHeight =
      this.layoutItem!.getTransferredMinHeight(context)
    if (transferredMinHeight !== undefined) {
      return this.layoutItem!.minMaxTransferredHeight(
        transferredMinHeight,
        context
      )
    }

    const ratio = this.layoutItem!.getRatio()
    const definiteWidth = !context.isWidthAuto()
    if (this.stretch && definiteWidth && ratio !== undefined) {
      return this.layoutItem!.minMaxTransferredHeight(
        context.width.value / ratio,
        context
      )
    }

    switch (context.height.type) {
      case SizeValueType.exact:
        return context.height.value
      case SizeValueType.min_content:
        return this.layoutItem!.getMinContentContributionHeight(context)
      case SizeValueType.max_content:
        return this.layoutItem!.getMaxContentContributionHeight(context)
      case SizeValueType.auto:
      case SizeValueType.absolute:
        const fitContentWidth = this.layoutItem!.getFitContentWidth(context)

        const fit = this.layoutItem!.sizeContextPool!.fromContext(context)
        fit.width.type = fitContentWidth.type
        fit.width.value = fitContentWidth.value
        fit.height.type = SizeValueType.max_content
        fit.height.value = 0

        this.layoutItem!.resize(fit)

        this.layoutItem!.sizeContextPool!.release(fit)

        return this.layoutItem!.position.height
    }
  }

  private computeMainSize = (): number => {
    return Math.max(this.minSize, Math.min(this.maxSize, this.baseSize))
  }

  private computeMinContentMainSize = (context: SizeContext): number => {
    const height = this.layoutItem!.getMinContentContributionHeight(context)

    return this.computeContentMainSize(height, context)
  }

  private computeMaxContentMainSize = (context: SizeContext): number => {
    const height = this.layoutItem!.getMaxContentContributionHeight(context)

    return this.computeContentMainSize(height, context)
  }

  private computeContentMainSize = (
    size: number,
    context: SizeContext
  ): number => {
    const preferredHeight = this.layoutItem!.getPreferredHeight(context)
    if (preferredHeight !== undefined) {
      const contentSize = Math.max(size, preferredHeight)
      return this.layoutItem!.minMaxHeight(contentSize, context)
    }

    const transferredHeight = this.layoutItem!.getTransferredHeight(context)
    if (transferredHeight !== undefined) {
      const contentSize = Math.max(
        size,
        this.layoutItem!.minMaxTransferredHeight(transferredHeight, context)
      )
      return this.layoutItem!.minMaxHeight(contentSize, context)
    }

    const minTransferredHeight =
      this.layoutItem!.getTransferredMinHeight(context)
    if (minTransferredHeight !== undefined) {
      const contentSize = Math.max(
        size,
        this.layoutItem!.minMaxTransferredHeight(minTransferredHeight, context)
      )
      return this.layoutItem!.minMaxHeight(contentSize, context)
    }

    const ratio = this.layoutItem!.getRatio()
    const definiteHeight = !context.isHeightAuto()
    if (this.stretch && definiteHeight && ratio !== undefined) {
      const transferredHeight = this.layoutItem!.minMaxTransferredHeight(
        context.height.value / ratio,
        context
      )
      const contentSize = Math.max(size, transferredHeight)
      return this.layoutItem!.minMaxHeight(contentSize, context)
    }

    return this.layoutItem!.minMaxHeight(size, context)
  }

  private computeMinSize = (context: SizeContext): number => {
    const minHeight = this.layoutItem!.getMinHeight(context)
    if (minHeight !== undefined) return minHeight

    const overflow = this.layoutItem!.getOverflow()
    if (overflow !== CssOverflow.overflow_visible) return 0

    const contentSizeSuggestion = this.layoutItem!.minMaxHeight(
      this.layoutItem!.minMaxTransferredHeight(
        this.layoutItem!.getMinContentContributionHeight(context),
        context
      ),
      context
    )

    const preferredHeight = this.layoutItem!.getPreferredHeight(context)
    if (preferredHeight !== undefined) {
      return Math.min(
        contentSizeSuggestion,
        this.layoutItem!.minMaxHeight(preferredHeight, context)
      )
    }

    const transferredHeight = this.layoutItem!.getTransferredHeight(context)
    if (transferredHeight !== undefined) {
      return Math.max(
        contentSizeSuggestion,
        this.layoutItem!.minMaxTransferredHeight(transferredHeight, context)
      )
    }

    const ratio = this.layoutItem!.getRatio()
    const definiteWidth = !context.isHeightAuto()
    if (this.stretch && definiteWidth && ratio !== undefined) {
      return Math.max(
        contentSizeSuggestion,
        this.layoutItem!.minMaxTransferredHeight(
          context.height.value / ratio,
          context
        )
      )
    }

    return contentSizeSuggestion
  }

  private computeMaxSize = (context: SizeContext): number => {
    const maxHeight = this.layoutItem!.getMaxHeight(context)
    if (maxHeight !== undefined) return maxHeight

    return Infinity
  }
}
