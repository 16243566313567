import { Point } from 'application/shapes'
import { ActiveAction } from './active'
import { MultiselectActionFactory } from './multiselect/factory'
import { DrawActionFactory } from './draw/factory'
import { AttributeType } from 'application/attributes'
import { MoveActionFactory } from './move/factory'
import { ResizeActionFactory } from './resize/factory'
import { EditTextActionFactory } from './editText/factory'
import { SelectionSide } from 'application/selection'
import { DragLayersActionFactory } from './dragLayers/factory'
import { HandActionFactory } from './hand/factory'
import { OpenLibraryActionFactory } from './openLibrary/factory'
import { Action } from './types'
import { EditInputActionFactory } from './editInput/factory'
import { EditInputTextEditor } from './editText/input'
import { DragTemplateActionFactory } from './dragTemplate/factory'
import { TemplateData } from 'application/service'

export class ActionInitiator {
  private activeAction: ActiveAction
  private multiselectFactory: MultiselectActionFactory
  private drawFactory: DrawActionFactory
  private dragLayersFactory: DragLayersActionFactory
  private dragTemplateFactory: DragTemplateActionFactory
  private editInputActionFactory: EditInputActionFactory
  private editInputTextEditor: EditInputTextEditor
  private editTextFactory: EditTextActionFactory
  private handFactory: HandActionFactory
  private moveFactory: MoveActionFactory
  private openLibraryFactory: OpenLibraryActionFactory
  private resizeFactory: ResizeActionFactory

  constructor(
    activeAction: ActiveAction,
    multiselectFactory: MultiselectActionFactory,
    drawFactory: DrawActionFactory,
    dragLayersFactory: DragLayersActionFactory,
    dragTemplateFactory: DragTemplateActionFactory,
    editInputActionFactory: EditInputActionFactory,
    editInputTextEditor: EditInputTextEditor,
    editTextFactory: EditTextActionFactory,
    handFactory: HandActionFactory,
    moveFactory: MoveActionFactory,
    openLibraryFactory: OpenLibraryActionFactory,
    resizeFactory: ResizeActionFactory
  ) {
    this.activeAction = activeAction
    this.multiselectFactory = multiselectFactory
    this.drawFactory = drawFactory
    this.dragLayersFactory = dragLayersFactory
    this.dragTemplateFactory = dragTemplateFactory
    this.editInputActionFactory = editInputActionFactory
    this.editInputTextEditor = editInputTextEditor
    this.editTextFactory = editTextFactory
    this.handFactory = handFactory
    this.moveFactory = moveFactory
    this.openLibraryFactory = openLibraryFactory
    this.resizeFactory = resizeFactory
  }

  multiselect = (point: Point): void => {
    this.cancel()

    const newAction = this.multiselectFactory.create(point)
    this.activeAction.set(newAction)
  }

  draw = (
    type: AttributeType,
    hotkey: boolean = false,
    point: Point | null = null
  ): void => {
    this.cancel()

    const newAction = this.drawFactory.create(type, hotkey, point)
    if (newAction.init) newAction.init()
    this.activeAction.set(newAction)
  }

  dragLayers = (): void => {
    this.cancel()

    const newAction = this.dragLayersFactory.create()
    this.activeAction.set(newAction)
  }

  dragTemplate = (templateData: TemplateData): void => {
    this.cancel()

    const newAction = this.dragTemplateFactory.create(templateData)
    this.activeAction.set(newAction)
  }

  hand = (mode: 'wheel' | 'mouse' | 'key'): void => {
    this.cancel()

    const newAction = this.handFactory.create(mode)
    this.activeAction.set(newAction)
  }

  openLibrary = (type: Action): void => {
    this.cancel()

    const newAction = this.openLibraryFactory.create(type)
    this.activeAction.set(newAction)
  }

  closeLibrary = (): void => {
    this.cancel()

    this.activeAction.reset()
  }

  toggleLibrary = (type: Action): void => {
    const action = this.activeAction.get()
    if (action && action.getType() === type) {
      this.closeLibrary()
    } else {
      this.cancel()
      this.openLibrary(type)
    }
  }

  move = (point: Point, duplicate: boolean): void => {
    this.cancel()

    const newAction = this.moveFactory.create(point, duplicate)
    this.activeAction.set(newAction)
  }

  resize = (side: SelectionSide, point: Point): void => {
    this.cancel()

    const newAction = this.resizeFactory.create(side, point)
    this.activeAction.set(newAction)
  }

  editInput = (editing: boolean): void => {
    const action = this.activeAction.get()
    if (action && action.getType() === 'editText') {
      this.editInputTextEditor.setEditing(editing)
      return
    }

    if (editing) {
      if (action && action.cancel) action.cancel()
      const newAction = this.editInputActionFactory.create()
      this.activeAction.set(newAction)
    } else {
      if (action && action.cancel) action.cancel()
      this.activeAction.reset()
    }
  }

  editText = (): void => {
    this.cancel()

    const newAction = this.editTextFactory.create()
    this.activeAction.set(newAction)
  }

  cancel = (): void => {
    const action = this.activeAction.get()
    if (!action) return

    if (action.cancel) action.cancel()
    this.activeAction.reset()
  }
}
