import { Editor } from './editor'
import { Client, ClientFactory } from 'application/client'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { ReadOnlyDocument } from 'application/document'
import { DocumentRenderingService } from './document/render'
import {
  CameraService,
  CanvasSizeService,
  CoordinatesConversion,
} from 'application/camera'
import { BrowserEventService } from 'application/browser'
import { CameraBrowserEventHandler } from './event/camera'
import { CanvasBrowserEventHandler } from './event/canvas'
import { ActionService } from './action'
import { ActiveAction } from './action/active'
import { ActionCoordinator } from './action/coordinator'
import {
  AlignmentLinesCalculator,
  InsertionStrategyFactory,
  InteractableNodeFilter,
  NodeCreationHandler,
  NodeCreateAction,
  NodeMoveAction,
  NodeReparentAction,
  NodeSelectionAction,
  PointAlignmentCalculator,
  ReorderHandler,
  ReparentCalculator,
} from 'application/action'
import { ActionInitiator } from './action/initiator'
import { MultiselectActionFactory } from './action/multiselect/factory'
import { EditorQuadTreeService } from './quadtree/service'
import {
  HapticSelectionWindow,
  hapticSelectionWindowKey,
  hapticSelectionWindowBubblesKey,
  hapticSelectionWindowNodesKey,
  hapticSelectionWindowTextKey,
} from './haptic/selectionWindow'
import {
  HapticMultiselectWindow,
  hapticMultiselectWindowKey,
} from './haptic/multiselectWindow'
import { DrawActionFactory } from './action/draw/factory'
import {
  HapticDrawingWindow,
  hapticDrawingWindowKey,
} from './haptic/drawingWindow'
import { NodeDeleteAction } from 'application/action'
import { MoveActionFactory } from './action/move/factory'
import { QuadTree } from 'application/quadtree'
import { IdGenerator } from 'application/ids'
import { HotkeyActionHandler } from './action/hotkey/hotkey'
import { HotkeyHandlersFactory } from './action/hotkey/handlers'
import { AttributeStore } from './attributes/store'
import { EditorUI } from './ui/ui'
import { PositionPanel } from './ui/positionPanel'
import { NodeAttributesAction } from 'application/action/attributes'
import { SizePanel } from './ui/sizePanel'
import { DisplayPanel } from './ui/displayPanel'
import { HapticChildLine, hapticChildLineKey } from './haptic/childLine'
import { ChildLineComputer } from './action/childLine/computer'
import { ParentChildIndexCalculator } from 'application/action/reparent/parentIndex'
import { ResizeActionFactory } from './action/resize/factory'
import { NodeResizeAction } from 'application/action/resize'
import { Canvas } from './canvas/canvas'
import { CanvasFactory } from './canvas/canvasFactory'
import { HapticGridlines } from './haptic/gridlines'
import { EditTextActionFactory } from './action/editText/factory'
import {
  CommandParser,
  TextEditorCommandParser,
  TextEditorHapticCalculator,
  TextEditorStyles,
} from 'application/textEditor'
import { HapticTextEditor, hapticTextEditorKey } from './haptic/textEditor'
import { TextEditorActionInitiator } from './textEditor/clientListener'
import { BackgroundPanel } from './ui/backgroundPanel'
import { TextPanelDefault } from './ui/text/textPanel'
import { TextPanelTextEditor } from './ui/text/textPanelTextEditor'
import {
  HapticAlignment,
  hapticAlignmentGapsKey,
  hapticAlignmentLinesKey,
} from './haptic/alignment'
import { AddFlexAction } from './action/node/flex'
import { NodeWrapAction } from 'application/action/wrap'
import { GroupAction } from './action/node/group'
import { RearrangeAction } from './action/node/rearrange'
import { PositionDeltaAction } from './action/node/positionDelta'
import { SizeDeltaAction } from './action/node/sizeDelta'
import { TreeSelectionAction } from './action/node/treeSelection'
import { EditorCursor } from './cursor/cursor'
import {
  HapticHighlightWindow,
  hapticHighlightWindowKey,
} from './haptic/highlightWindow'
import { EditorHighlightService } from './highlight/service'
import { HighlightServiceEventHandler } from './highlight/handler'
import { LayersPanel } from './ui/layersPanel'
import { DragLayersActionFactory } from './action/dragLayers/factory'
import { DraggingLayersLine } from './action/dragLayers/line'
import {
  FontLoaderInterface,
  Shaper,
  FontLoader,
  TextShaper,
  FontDataMap,
} from 'application/text'
import { NodeTitleGenerator } from './titles/generator'
import { NodeTitlesService } from './titles/service'
import { HapticNodeTitles, hapticNodeTitlesKey } from './haptic/nodeTitles'
import { HandActionFactory } from './action/hand/factory'
import { BorderPanel } from './ui/borderPanel'
import { ShadowPanel } from './ui/shadowPanel'
import { OpacityPanel } from './ui/opacityPanel'
import { OverflowPanel } from './ui/overflowPanel'
import { RoundingPanel } from './ui/roundingPanel'
import { OpenLibraryActionFactory } from './action/openLibrary/factory'
import { CreateAction } from './action/node/create'
import { ImagePanel } from './ui/imagePanel'
import { FilterBlurPanel } from './ui/filterBlurPanel'
import { IconLoader } from 'application/render/svg/iconLoader'
import { HapticOrder } from 'application/render'
import {
  HapticGridlinesSmall,
  hapticGridlinesSmallKey,
} from './haptic/gridlinesSmall'
import {
  HapticDistanceLines,
  hapticDistanceLinesKey,
} from './haptic/distanceLines'
import {
  HapticNewParentWindow,
  hapticNewParentWindowKey,
} from './haptic/newParentWindow'
import { AlignmentGapsCalculator } from 'application/action/alignment/gaps'
import { SelectionColorsPanel } from './ui/selectionColors/selectionColorsPanel'
import { NodeActions } from './ui/nodeActions'
import { EditorProjectService } from './project/project'
import { BackendService } from 'application/service/backend'
import { HTTPService } from 'application/service/http'
import { LocalStorageAuthService } from 'application/service/auth'
import { WebsocketConnection } from 'application/service/connection'
import { WebsocketService } from 'application/service/websocket'
import { EditorCameraService } from './camera/camera'
import {
  HapticChildWindows,
  hapticChildWindowsKey,
} from './haptic/childWindows'
import { EditInputActionFactory } from './action/editInput/factory'
import { ImageLibraryPanel } from './ui/imageLibraryPanel'
import {
  HapticHighlightFlexChildren,
  hapticHighlightFlexChildrenKey,
} from './haptic/highlightFlexChildren'
import { ImageExporter } from './export/image'
import { TextAction } from './action/node/text'
import { ScreenSizePanel } from './ui/screenSizePanel'
import { FPSPanel } from './ui/fpsPanel'
import { StyleOverridesPanel } from './ui/styleOverrides'
import { DetailsPanel } from './ui/namePanel'
import { CameraPanel } from './ui/cameraPanel'
import { TextAttributeFactory } from 'application/action/creation/attributes/text'
import { FillAttributeFactory } from 'application/action/creation/attributes/fill'
import { DefaultTextAttributes } from './attributes/text'
import { TemplatesPanel } from './ui/templatesPanel'
import { PasteAction } from './action/paste/action'
import { EditInputTextEditor } from './action/editText/input'
import { UploadImageAction } from './action/uploadImage/action'
import {
  HapticConstraintLines,
  hapticConstraintLinesKey,
} from './haptic/constraintLines'
import { PositionModePanel } from './ui/positionModePanel'
import { TransitionPanel } from './ui/transitionPanel'
import { EditorWebsocketService } from './websocket/websocket'
import { CursorPanel } from './ui/cursorPanel'
import { FontPopupPanel } from './ui/fontPopupPanel'
import { FontDataMapMock } from 'application/text/fontMapMock'
import { FontDataMapImpl } from 'application/text/fontMap'
import { FlexChildPanel } from './ui/flexChildPanel'
import { SizeRatioPanel } from './ui/sizeRatioPanel'
import { PaddingPanel } from './ui/paddingPanel'
import { MouseDeviceState } from 'application/mouse'
import { CursorPositionState } from 'application/cursor/position'
import { DragTemplateActionFactory } from './action/dragTemplate/factory'
import { CodeExporter } from './export/code'
import { CodePanel } from './ui/codePanel'
import { HTMLAttributePanel } from './ui/htmlAttributePanel'

export class EditorDependencies {
  private registry: Map<string, any>

  constructor() {
    this.registry = new Map()
  }

  getClient(): Client {
    if (!this.registry.has('client')) {
      const clientFactory = new ClientFactory()
      const fontLoader = this.getFontLoader()
      const textShaper = this.getTextShaper()
      const fontMap = this.getFontMap()
      const cameraSerivce = this.getCameraService()
      const client = clientFactory.create(
        fontLoader,
        textShaper,
        fontMap,
        cameraSerivce
      )
      this.registry.set('client', client)
    }
    return this.registry.get('client')
  }

  getDocument(): ReadOnlyDocument {
    return this.getClient().getDocument()
  }

  getIdGenerator(): IdGenerator {
    return this.getClient().getIdGenerator()
  }

  getDocumentSelection(): ReadOnlyDocumentSelection {
    return this.getClient().getSelection()
  }

  getMouseDeviceState(): MouseDeviceState {
    if (!this.registry.has('mouseDeviceState')) {
      this.registry.set('mouseDeviceState', new MouseDeviceState())
    }
    return this.registry.get('mouseDeviceState')
  }

  getCanvasSizeService(): CanvasSizeService {
    if (!this.registry.has('canvasSizeService')) {
      this.registry.set(
        'canvasSizeService',
        new CanvasSizeService('webgl-canvas')
      )
    }
    return this.registry.get('canvasSizeService')
  }

  getCameraService(): CameraService {
    if (!this.registry.has('cameraService')) {
      this.registry.set(
        'cameraService',
        new CameraService(this.getCanvasSizeService())
      )
    }
    return this.registry.get('cameraService')
  }

  getCoordinateConversion(): CoordinatesConversion {
    if (!this.registry.has('coordinateConversion')) {
      this.registry.set(
        'coordinateConversion',
        new CoordinatesConversion(this.getCameraService())
      )
    }
    return this.registry.get('coordinateConversion')
  }

  getTextEditorCommandParser(): CommandParser {
    if (!this.registry.has('textEditorCommandParser')) {
      this.registry.set(
        'textEditorCommandParser',
        new TextEditorCommandParser()
      )
    }
    return this.registry.get('textEditorCommandParser')
  }

  getFontLoader(): FontLoaderInterface {
    if (!this.registry.has('textEditorFontLoader')) {
      this.registry.set('textEditorFontLoader', new FontLoader())
    }
    return this.registry.get('textEditorFontLoader')
  }

  getFontMap(): FontDataMap {
    if (!this.registry.has('textEditorFontMap')) {
      this.registry.set('textEditorFontMap', new FontDataMapImpl())
    }
    return this.registry.get('textEditorFontMap')
  }

  getIconLoader(): IconLoader {
    if (!this.registry.has('iconLoader')) {
      this.registry.set('iconLoader', new IconLoader())
    }
    return this.registry.get('iconLoader')
  }

  getTextShaper(): Shaper {
    if (!this.registry.has('textEditorShaper')) {
      this.registry.set(
        'textEditorShaper',
        new TextShaper(this.getFontLoader())
      )
    }
    return this.registry.get('textEditorShaper')
  }

  getTextEditorStyles(): TextEditorStyles {
    if (!this.registry.has('textEditorStyles')) {
      this.registry.set(
        'textEditorStyles',
        new TextEditorStyles(new FontDataMapMock())
      )
    }
    return this.registry.get('textEditorStyles')
  }

  getTextEditorHapticCalculator(): TextEditorHapticCalculator {
    if (!this.registry.has('textEditorHapticCalculator')) {
      this.registry.set(
        'textEditorHapticCalculator',
        new TextEditorHapticCalculator(
          this.getTextShaper(),
          this.getTextEditorStyles()
        )
      )
    }
    return this.registry.get('textEditorHapticCalculator')
  }

  getCanvas(): Canvas {
    if (!this.registry.has('canvas')) {
      const canvasFactory = new CanvasFactory()
      const canvas = canvasFactory.create(
        this.getCameraService(),
        this.getCanvasSizeService(),
        this.getDocumentSelection(),
        this.getHapticOrder()
      )
      this.registry.set('canvas', canvas)
    }
    return this.registry.get('canvas')
  }

  getCodeExporter(): CodeExporter {
    if (!this.registry.has('codeExporter')) {
      const codeExporter = new CodeExporter(this.getDocument())
      this.registry.set('codeExporter', codeExporter)
    }
    return this.registry.get('codeExporter')
  }

  getImageExporter(): ImageExporter {
    if (!this.registry.has('imageExporter')) {
      const imageExporter = new ImageExporter(
        this.getDocument(),
        this.getDocumentSelection(),
        this.getFontLoader(),
        this.getTextShaper()
      )
      this.registry.set('imageExporter', imageExporter)
    }
    return this.registry.get('imageExporter')
  }

  getEditorCameraService(): EditorCameraService {
    if (!this.registry.has('editorCameraService')) {
      this.registry.set(
        'editorCameraService',
        new EditorCameraService(
          this.getDocument(),
          this.getDocumentSelection(),
          this.getCameraService()
        )
      )
    }
    return this.registry.get('editorCameraService')
  }

  getEditorCursor(): EditorCursor {
    if (!this.registry.has('editorCursor')) {
      this.registry.set('editorCursor', new EditorCursor('webgl-canvas'))
    }
    return this.registry.get('editorCursor')
  }

  getDocumentRenderingService(): DocumentRenderingService {
    if (!this.registry.has('documentRenderingService')) {
      this.registry.set(
        'documentRenderingService',
        new DocumentRenderingService(
          this.getDocument(),
          this.getDocumentSelection(),
          this.getCanvas(),
          this.getFontLoader(),
          this.getTextShaper()
        )
      )
    }
    return this.registry.get('documentRenderingService')
  }

  getBrowserEventService(): BrowserEventService {
    if (!this.registry.has('browserEventService')) {
      this.registry.set('browserEventService', new BrowserEventService())
    }
    return this.registry.get('browserEventService')
  }

  getCameraBrowserEventService(): CameraBrowserEventHandler {
    if (!this.registry.has('cameraBrowserEventHandler')) {
      this.registry.set(
        'cameraBrowserEventHandler',
        new CameraBrowserEventHandler(
          this.getMouseDeviceState(),
          this.getCameraService()
        )
      )
    }
    return this.registry.get('cameraBrowserEventHandler')
  }

  getCursorPositionState(): CursorPositionState {
    if (!this.registry.has('cursorPositionState')) {
      this.registry.set(
        'cursorPositionState',
        new CursorPositionState(this.getCoordinateConversion())
      )
    }
    return this.registry.get('cursorPositionState')
  }

  getCanvasBrowserEventService(): CanvasBrowserEventHandler {
    if (!this.registry.has('canvasBrowserEventHandler')) {
      this.registry.set(
        'canvasBrowserEventHandler',
        new CanvasBrowserEventHandler(this.getCanvasSizeService())
      )
    }
    return this.registry.get('canvasBrowserEventHandler')
  }

  getActiveAction(): ActiveAction {
    if (!this.registry.has('activeAction')) {
      this.registry.set(
        'activeAction',
        new ActiveAction(this.getEditorCursor())
      )
    }
    return this.registry.get('activeAction')
  }

  getHighlightBrowserEventListener(): HighlightServiceEventHandler {
    if (!this.registry.has('highlightBrowserEventListener')) {
      this.registry.set(
        'highlightBrowserEventListener',
        new HighlightServiceEventHandler(
          this.getCoordinateConversion(),
          this.getEditorHighlightService(),
          this.getInteractableNodeFilter(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('highlightBrowserEventListener')
  }

  getEditorHighlightService(): EditorHighlightService {
    if (!this.registry.has('editorHighlightService')) {
      const editorHighlightService = new EditorHighlightService()
      this.registry.set('editorHighlightService', editorHighlightService)
    }
    return this.registry.get('editorHighlightService')
  }

  getQuadTree(): QuadTree {
    if (!this.registry.has('quadTree')) {
      this.registry.set(
        'quadTree',
        new QuadTree(-500_000, -500_000, 1_000_000, 1_000_000, 1_000)
      )
    }
    return this.registry.get('quadTree')
  }

  getEditorQuadTreeService(): EditorQuadTreeService {
    if (!this.registry.has('editorQuadTreeService')) {
      const editorQuadTreeService = new EditorQuadTreeService(
        this.getDocument(),
        this.getDocumentSelection(),
        this.getQuadTree()
      )
      this.registry.set('editorQuadTreeService', editorQuadTreeService)
    }
    return this.registry.get('editorQuadTreeService')
  }

  getTextEditorActionInitiator(): TextEditorActionInitiator {
    if (!this.registry.has('textEditorActionInitiator')) {
      const textEditorActionInitiator = new TextEditorActionInitiator(
        this.getActionInitiator()
      )
      this.registry.set('textEditorActionInitiator', textEditorActionInitiator)
    }
    return this.registry.get('textEditorActionInitiator')
  }

  getAlignmentGapsCalculator(): AlignmentGapsCalculator {
    if (!this.registry.has('alignmentGapsCalculator')) {
      const alignmentGapsCalculator = new AlignmentGapsCalculator(
        this.getDocument(),
        this.getDocumentSelection(),
        this.getCameraService()
      )
      this.registry.set('alignmentGapsCalculator', alignmentGapsCalculator)
    }
    return this.registry.get('alignmentGapsCalculator')
  }

  getAlignmentLinesCalculator(): AlignmentLinesCalculator {
    if (!this.registry.has('alignmentLinesCalculator')) {
      const alignmentLinesCalculator = new AlignmentLinesCalculator(
        this.getDocument(),
        this.getDocumentSelection(),
        this.getCameraService()
      )
      this.registry.set('alignmentLinesCalculator', alignmentLinesCalculator)
    }
    return this.registry.get('alignmentLinesCalculator')
  }

  getPointAlignmentCalculator(): PointAlignmentCalculator {
    if (!this.registry.has('pointAlignmentCalculator')) {
      const pointAlignmentCalculator = new PointAlignmentCalculator()
      this.registry.set('pointAlignmentCalculator', pointAlignmentCalculator)
    }
    return this.registry.get('pointAlignmentCalculator')
  }

  getInteractableNodeFilter(): InteractableNodeFilter {
    if (!this.registry.has('interactableNodeFilter')) {
      this.registry.set(
        'interactableNodeFilter',
        new InteractableNodeFilter(
          this.getDocument(),
          this.getDocumentSelection(),
          this.getQuadTree()
        )
      )
    }
    return this.registry.get('interactableNodeFilter')
  }

  getNodeSelectionAction(): NodeSelectionAction {
    if (!this.registry.has('nodeSelectionAction')) {
      const nodeSelectionAction = new NodeSelectionAction(
        this.getClient(),
        this.getDocumentSelection(),
        this.getInteractableNodeFilter()
      )
      this.registry.set('nodeSelectionAction', nodeSelectionAction)
    }
    return this.registry.get('nodeSelectionAction')
  }

  getTextAttributeFactory(): TextAttributeFactory {
    if (!this.registry.has('textAttributeFactory')) {
      this.registry.set('textAttributeFactory', new TextAttributeFactory())
    }
    return this.registry.get('textAttributeFactory')
  }

  getFillAttributeFactory(): FillAttributeFactory {
    if (!this.registry.has('fillAttributeFactory')) {
      this.registry.set('fillAttributeFactory', new FillAttributeFactory())
    }
    return this.registry.get('fillAttributeFactory')
  }

  getNodeCreationHandler(): NodeCreationHandler {
    if (!this.registry.has('nodeCreationHandler')) {
      const nodeCreationHandler = new NodeCreationHandler(
        this.getClient(),
        this.getIdGenerator(),
        this.getFillAttributeFactory(),
        this.getTextAttributeFactory()
      )
      this.registry.set('nodeCreationHandler', nodeCreationHandler)
    }
    return this.registry.get('nodeCreationHandler')
  }

  getInsertionStrategyFactory(): InsertionStrategyFactory {
    if (!this.registry.has('insertionStrategyFactory')) {
      const insertionStrategyFactory = new InsertionStrategyFactory(
        this.getDocument(),
        this.getDocumentSelection(),
        this.getCameraService(),
        this.getQuadTree()
      )
      this.registry.set('insertionStrategyFactory', insertionStrategyFactory)
    }
    return this.registry.get('insertionStrategyFactory')
  }

  getReorderHandler = (): ReorderHandler => {
    if (!this.registry.has('reorderHandler')) {
      this.registry.set(
        'reorderHandler',
        new ReorderHandler(this.getDocument())
      )
    }
    return this.registry.get('reorderHandler')
  }

  getReparentCalculator = (): ReparentCalculator => {
    if (!this.registry.has('reparentCalculator')) {
      this.registry.set(
        'reparentCalculator',
        new ReparentCalculator(
          this.getDocument(),
          this.getDocumentSelection(),
          this.getQuadTree(),
          this.getCameraService()
        )
      )
    }
    return this.registry.get('reparentCalculator')
  }

  getParentChildIndexCalculator = (): ParentChildIndexCalculator => {
    if (!this.registry.has('parentChildIndexCalculator')) {
      this.registry.set(
        'parentChildIndexCalculator',
        new ParentChildIndexCalculator(this.getDocument())
      )
    }
    return this.registry.get('parentChildIndexCalculator')
  }

  getNodeAttributesAction(): NodeAttributesAction {
    if (!this.registry.has('nodeAttributesAction')) {
      const nodeSetAttributesAction = new NodeAttributesAction(this.getClient())
      this.registry.set('nodeAttributesAction', nodeSetAttributesAction)
    }
    return this.registry.get('nodeAttributesAction')
  }

  getNodeCreateAction(): NodeCreateAction {
    if (!this.registry.has('nodeCreateAction')) {
      const nodeCreateAction = new NodeCreateAction(
        this.getClient(),
        this.getNodeCreationHandler(),
        this.getInsertionStrategyFactory(),
        this.getDocument()
      )
      this.registry.set('nodeCreateAction', nodeCreateAction)
    }
    return this.registry.get('nodeCreateAction')
  }

  getNodeDeleteAction(): NodeDeleteAction {
    if (!this.registry.has('nodeDeleteAction')) {
      const nodeDeleteAction = new NodeDeleteAction(
        this.getClient(),
        this.getDocument()
      )
      this.registry.set('nodeDeleteAction', nodeDeleteAction)
    }
    return this.registry.get('nodeDeleteAction')
  }

  getNodeMoveAction(): NodeMoveAction {
    if (!this.registry.has('nodeMoveAction')) {
      const nodeMoveAction = new NodeMoveAction(
        this.getClient(),
        this.getDocument(),
        this.getReorderHandler()
      )
      this.registry.set('nodeMoveAction', nodeMoveAction)
    }
    return this.registry.get('nodeMoveAction')
  }

  getNodeReparentAction(): NodeReparentAction {
    if (!this.registry.has('nodeReparentAction')) {
      const nodeReparentAction = new NodeReparentAction(
        this.getClient(),
        this.getDocument(),
        this.getReparentCalculator(),
        this.getParentChildIndexCalculator()
      )
      this.registry.set('nodeReparentAction', nodeReparentAction)
    }
    return this.registry.get('nodeReparentAction')
  }

  getNodeResizeAction(): NodeResizeAction {
    if (!this.registry.has('nodeResizeAction')) {
      const nodeResizeAction = new NodeResizeAction(
        this.getDocument(),
        this.getClient()
      )
      this.registry.set('nodeResizeAction', nodeResizeAction)
    }
    return this.registry.get('nodeResizeAction')
  }

  getNodeWrapAction(): NodeWrapAction {
    if (!this.registry.has('nodeWrapAction')) {
      const nodeWrapAction = new NodeWrapAction(
        this.getDocument(),
        this.getClient(),
        this.getNodeCreationHandler(),
        this.getInsertionStrategyFactory(),
        this.getNodeDeleteAction()
      )
      this.registry.set('nodeWrapAction', nodeWrapAction)
    }
    return this.registry.get('nodeWrapAction')
  }

  getHotkeyHandlersFactory(): HotkeyHandlersFactory {
    if (!this.registry.has('hotkeyHandlersFactory')) {
      const hotkeyHandlersFactory = new HotkeyHandlersFactory(
        this.getActionInitiator(),
        this.getClient(),
        this.getNodeSelectionAction(),
        this.getNodeDeleteAction(),
        this.getFlexAction(),
        this.getGroupAction(),
        this.getRearrangeAction(),
        this.getPositionDeltaAction(),
        this.getSizeDeltaAction(),
        this.getTextAction(),
        this.getTreeSelectionAction(),
        this.getImageExporter(),
        this.getLayersPanel(),
        this.getDocumentSelection(),
        this.getCameraService(),
        this.getCursorPositionState()
      )
      this.registry.set('hotkeyHandlersFactory', hotkeyHandlersFactory)
    }
    return this.registry.get('hotkeyHandlersFactory')
  }

  getHotkeyActionHandler(): HotkeyActionHandler {
    if (!this.registry.has('hotkeyActionHandler')) {
      const factory = this.getHotkeyHandlersFactory()
      const hotkeyActionHandler = new HotkeyActionHandler(factory.getHandlers())
      this.registry.set('hotkeyActionHandler', hotkeyActionHandler)
    }
    return this.registry.get('hotkeyActionHandler')
  }

  getActionInitiator(): ActionInitiator {
    if (!this.registry.has('actionInitiator')) {
      const actionInitiator = new ActionInitiator(
        this.getActiveAction(),
        this.getMultiselectActionFactory(),
        this.getDrawActionFactory(),
        this.getDragLayersActionFactory(),
        this.getDragTemplateActionFactory(),
        this.getEditInputActionFactory(),
        this.getEditInputTextEditor(),
        this.getEditTextActionFactory(),
        this.getHandActionFactory(),
        this.getMoveActionFactory(),
        this.getOpenLibraryActionFactory(),
        this.getResizeActionFactory()
      )
      this.registry.set('actionInitiator', actionInitiator)
    }
    return this.registry.get('actionInitiator')
  }

  getActionCoordinator(): ActionCoordinator {
    if (!this.registry.has('actionCoordinator')) {
      const actionCoordinator = new ActionCoordinator(
        this.getNodeSelectionAction(),
        this.getInteractableNodeFilter(),
        this.getPasteAction(),
        this.getUploadImageAction(),
        this.getDocument(),
        this.getDocumentSelection(),
        this.getCoordinateConversion(),
        this.getClient(),
        this.getActionInitiator(),
        this.getActiveAction(),
        this.getEditorCursor(),
        this.getHotkeyActionHandler()
      )
      this.registry.set('actionCoordinator', actionCoordinator)
    }
    return this.registry.get('actionCoordinator')
  }

  getActionService(): ActionService {
    if (!this.registry.has('actionService')) {
      const actionService = new ActionService(
        this.getActiveAction(),
        this.getActionCoordinator(),
        this.getEditorCursor()
      )
      this.registry.set('actionService', actionService)
    }
    return this.registry.get('actionService')
  }

  getDrawActionFactory(): DrawActionFactory {
    if (!this.registry.has('drawActionFactory')) {
      const drawActionFactory = new DrawActionFactory(
        this.getClient(),
        this.getDocument(),
        this.getNodeCreateAction(),
        this.getNodeSelectionAction(),
        this.getQuadTree(),
        this.getHapticDrawingWindow(),
        this.getHapticChildLine(),
        this.getHapticNewParentWindow(),
        this.getChildLineComputer(),
        this.getCoordinateConversion()
      )
      this.registry.set('drawActionFactory', drawActionFactory)
    }
    return this.registry.get('drawActionFactory')
  }

  getEditInputActionFactory(): EditInputActionFactory {
    if (!this.registry.has('editInputActionFactory')) {
      const editInputActionFactory = new EditInputActionFactory()
      this.registry.set('editInputActionFactory', editInputActionFactory)
    }
    return this.registry.get('editInputActionFactory')
  }

  getDragLayersActionFactory(): DragLayersActionFactory {
    if (!this.registry.has('dragLayersActionFactory')) {
      const draggingLayersActionFactory = new DragLayersActionFactory(
        this.getClient(),
        this.getDocument(),
        this.getDocumentSelection(),
        this.getNodeReparentAction(),
        this.getNodeSelectionAction(),
        this.getDraggingLayersLine()
      )
      this.registry.set('dragLayersActionFactory', draggingLayersActionFactory)
    }
    return this.registry.get('dragLayersActionFactory')
  }

  getDraggingLayersLine(): DraggingLayersLine {
    if (!this.registry.has('draggingLayersLine')) {
      this.registry.set('draggingLayersLine', new DraggingLayersLine())
    }
    return this.registry.get('draggingLayersLine')
  }

  getDragTemplateActionFactory(): DragTemplateActionFactory {
    if (!this.registry.has('dragTemplateActionFactory')) {
      const dragTemplateActionFactory = new DragTemplateActionFactory(
        this.getClient(),
        this.getDocument(),
        this.getQuadTree(),
        this.getHapticDrawingWindow(),
        this.getHapticChildLine(),
        this.getHapticNewParentWindow(),
        this.getChildLineComputer(),
        this.getCoordinateConversion()
      )
      this.registry.set('dragTemplateActionFactory', dragTemplateActionFactory)
    }
    return this.registry.get('dragTemplateActionFactory')
  }

  getHandActionFactory(): HandActionFactory {
    if (!this.registry.has('handActionFactory')) {
      const handActionFactory = new HandActionFactory(
        this.getMouseDeviceState(),
        this.getCameraService()
      )
      this.registry.set('handActionFactory', handActionFactory)
    }
    return this.registry.get('handActionFactory')
  }

  getChildLineComputer(): ChildLineComputer {
    if (!this.registry.has('childLineComputer')) {
      const childLineComputer = new ChildLineComputer(
        this.getDocument(),
        this.getParentChildIndexCalculator()
      )
      this.registry.set('childLineComputer', childLineComputer)
    }
    return this.registry.get('childLineComputer')
  }

  getMoveActionFactory(): MoveActionFactory {
    if (!this.registry.has('moveActionFactory')) {
      const moveActionFactory = new MoveActionFactory(
        this.getDocument(),
        this.getDocumentSelection(),
        this.getClient(),
        this.getNodeMoveAction(),
        this.getNodeReparentAction(),
        this.getNodeAttributesAction(),
        this.getCoordinateConversion(),
        this.getAlignmentLinesCalculator(),
        this.getAlignmentGapsCalculator(),
        this.getPointAlignmentCalculator(),
        this.getHapticAlignmentLines(),
        this.getHapticChildWindows(),
        this.getHapticChildLine(),
        this.getHapticNewParentWindow(),
        this.getChildLineComputer()
      )
      this.registry.set('moveActionFactory', moveActionFactory)
    }
    return this.registry.get('moveActionFactory')
  }

  getOpenLibraryActionFactory(): OpenLibraryActionFactory {
    if (!this.registry.has('openLibraryActionFactory')) {
      const openLibraryActionFactory = new OpenLibraryActionFactory()
      this.registry.set('openLibraryActionFactory', openLibraryActionFactory)
    }
    return this.registry.get('openLibraryActionFactory')
  }

  getPasteAction = (): PasteAction => {
    if (!this.registry.has('pasteAction')) {
      this.registry.set(
        'pasteAction',
        new PasteAction(
          this.getDocumentSelection(),
          this.getNodeCreateAction(),
          this.getNodeSelectionAction(),
          this.getClient(),
          this.getEditorProjectService(),
          this.getBackendService()
        )
      )
    }
    return this.registry.get('pasteAction')
  }

  getMultiselectActionFactory(): MultiselectActionFactory {
    if (!this.registry.has('multiselectActionFactory')) {
      const multiselectActionFactory = new MultiselectActionFactory(
        this.getClient(),
        this.getNodeSelectionAction(),
        this.getHapticMultiselectWindow(),
        this.getCoordinateConversion()
      )
      this.registry.set('multiselectActionFactory', multiselectActionFactory)
    }
    return this.registry.get('multiselectActionFactory')
  }

  getResizeActionFactory(): ResizeActionFactory {
    if (!this.registry.has('resizeActionFactory')) {
      const resizeActionFactory = new ResizeActionFactory(
        this.getDocument(),
        this.getDocumentSelection(),
        this.getClient(),
        this.getNodeResizeAction(),
        this.getCoordinateConversion(),
        this.getAlignmentLinesCalculator(),
        this.getPointAlignmentCalculator(),
        this.getHapticAlignmentLines()
      )
      this.registry.set('resizeActionFactory', resizeActionFactory)
    }
    return this.registry.get('resizeActionFactory')
  }

  getEditInputTextEditor = (): EditInputTextEditor => {
    if (!this.registry.has('editInputTextEditor')) {
      this.registry.set('editInputTextEditor', new EditInputTextEditor())
    }
    return this.registry.get('editInputTextEditor')
  }

  getEditTextActionFactory(): EditTextActionFactory {
    if (!this.registry.has('editTextActionFactory')) {
      const editTextActionFactory = new EditTextActionFactory(
        this.getDocumentSelection(),
        this.getNodeSelectionAction(),
        this.getNodeDeleteAction(),
        this.getClient(),
        this.getTextEditorCommandParser(),
        this.getCoordinateConversion(),
        this.getEditInputTextEditor()
      )
      this.registry.set('editTextActionFactory', editTextActionFactory)
    }
    return this.registry.get('editTextActionFactory')
  }

  getUploadImageAction(): UploadImageAction {
    if (!this.registry.has('uploadImageAction')) {
      this.registry.set(
        'uploadImageAction',
        new UploadImageAction(
          this.getBackendService(),
          this.getEditorProjectService(),
          this.getDocumentSelection(),
          this.getClient()
        )
      )
    }
    return this.registry.get('uploadImageAction')
  }

  getFlexAction(): AddFlexAction {
    if (!this.registry.has('addFlexAction')) {
      const addFlexAction = new AddFlexAction(
        this.getClient(),
        this.getDocument(),
        this.getDocumentSelection(),
        this.getNodeSelectionAction(),
        this.getNodeWrapAction()
      )
      this.registry.set('addFlexAction', addFlexAction)
    }
    return this.registry.get('addFlexAction')
  }

  getCreateAction(): CreateAction {
    if (!this.registry.has('createAction')) {
      const createAction = new CreateAction(
        this.getClient(),
        this.getNodeCreateAction(),
        this.getNodeSelectionAction()
      )
      this.registry.set('createAction', createAction)
    }
    return this.registry.get('createAction')
  }

  getGroupAction(): GroupAction {
    if (!this.registry.has('groupAction')) {
      const groupAction = new GroupAction(
        this.getClient(),
        this.getDocument(),
        this.getDocumentSelection(),
        this.getNodeSelectionAction(),
        this.getNodeWrapAction()
      )
      this.registry.set('groupAction', groupAction)
    }
    return this.registry.get('groupAction')
  }

  getPositionDeltaAction(): PositionDeltaAction {
    if (!this.registry.has('positionDeltaAction')) {
      const positionDeltaAction = new PositionDeltaAction(
        this.getClient(),
        this.getDocument(),
        this.getDocumentSelection()
      )
      this.registry.set('positionDeltaAction', positionDeltaAction)
    }
    return this.registry.get('positionDeltaAction')
  }

  getRearrangeAction(): RearrangeAction {
    if (!this.registry.has('rearrangeAction')) {
      const rearrangeAction = new RearrangeAction(
        this.getClient(),
        this.getDocument(),
        this.getDocumentSelection()
      )
      this.registry.set('rearrangeAction', rearrangeAction)
    }
    return this.registry.get('rearrangeAction')
  }

  getSizeDeltaAction(): SizeDeltaAction {
    if (!this.registry.has('sizeDeltaAction')) {
      const sizeDeltaAction = new SizeDeltaAction(
        this.getClient(),
        this.getDocument(),
        this.getDocumentSelection()
      )
      this.registry.set('sizeDeltaAction', sizeDeltaAction)
    }
    return this.registry.get('sizeDeltaAction')
  }

  getTextAction(): TextAction {
    if (!this.registry.has('textAction')) {
      const textAction = new TextAction(
        this.getClient(),
        this.getDocumentSelection(),
        this.getFontMap()
      )
      this.registry.set('textAction', textAction)
    }
    return this.registry.get('textAction')
  }

  getTreeSelectionAction(): TreeSelectionAction {
    if (!this.registry.has('treeSelectionAction')) {
      const treeSelectionAction = new TreeSelectionAction(
        this.getClient(),
        this.getDocument(),
        this.getDocumentSelection(),
        this.getNodeSelectionAction()
      )
      this.registry.set('treeSelectionAction', treeSelectionAction)
    }
    return this.registry.get('treeSelectionAction')
  }

  getHapticOrder(): HapticOrder {
    if (!this.registry.has('hapticOrder')) {
      this.registry.set('hapticOrder', [
        hapticGridlinesSmallKey,
        hapticAlignmentLinesKey,
        hapticAlignmentGapsKey,
        hapticMultiselectWindowKey,
        hapticSelectionWindowNodesKey,
        hapticHighlightFlexChildrenKey,
        hapticHighlightWindowKey,
        hapticNewParentWindowKey,
        hapticConstraintLinesKey,
        hapticSelectionWindowKey,
        hapticSelectionWindowTextKey,
        hapticSelectionWindowBubblesKey,
        hapticDrawingWindowKey,
        hapticChildLineKey,
        hapticChildWindowsKey,
        hapticDistanceLinesKey,
        hapticTextEditorKey,
        hapticNodeTitlesKey,
      ])
    }
    return this.registry.get('hapticOrder')
  }

  getHapticAlignmentLines(): HapticAlignment {
    if (!this.registry.has('hapticAlignmentLines')) {
      const hapticAlignmentLines = new HapticAlignment(
        this.getCanvas(),
        this.getFontLoader(),
        this.getTextShaper()
      )
      this.registry.set('hapticAlignmentLines', hapticAlignmentLines)
    }
    return this.registry.get('hapticAlignmentLines')
  }

  getHapticChildLine(): HapticChildLine {
    if (!this.registry.has('hapticChildLine')) {
      const hapticChildLine = new HapticChildLine(this.getCanvas())
      this.registry.set('hapticChildLine', hapticChildLine)
    }
    return this.registry.get('hapticChildLine')
  }

  getHapticChildWindows(): HapticChildWindows {
    if (!this.registry.has('hapticChildWindows')) {
      const hapticChildWindows = new HapticChildWindows(this.getCanvas())
      this.registry.set('hapticChildWindows', hapticChildWindows)
    }
    return this.registry.get('hapticChildWindows')
  }

  getHapticConstraintLines(): HapticConstraintLines {
    if (!this.registry.has('hapticConstraintLines')) {
      const hapticConstraintLines = new HapticConstraintLines(
        this.getDocument(),
        this.getCanvas()
      )
      this.registry.set('hapticConstraintLines', hapticConstraintLines)
    }
    return this.registry.get('hapticConstraintLines')
  }

  getHapticDistanceLines(): HapticDistanceLines {
    if (!this.registry.has('hapticDistanceLines')) {
      this.registry.set(
        'hapticDistanceLines',
        new HapticDistanceLines(
          this.getDocument(),
          this.getDocumentSelection(),
          this.getInteractableNodeFilter(),
          this.getCanvas(),
          this.getFontLoader(),
          this.getTextShaper(),
          this.getCoordinateConversion()
        )
      )
    }
    return this.registry.get('hapticDistanceLines')
  }

  getHapticDrawingWindow(): HapticDrawingWindow {
    if (!this.registry.has('hapticDrawingWindow')) {
      const hapticDrawingWindow = new HapticDrawingWindow(this.getCanvas())
      this.registry.set('hapticDrawingWindow', hapticDrawingWindow)
    }
    return this.registry.get('hapticDrawingWindow')
  }

  getHapticHighlightFlexChildren(): HapticHighlightFlexChildren {
    if (!this.registry.has('hapticHighlightFlexChildren')) {
      const hapticHighlightFlexChildren = new HapticHighlightFlexChildren(
        this.getDocument(),
        this.getCanvas()
      )
      this.registry.set(
        'hapticHighlightFlexChildren',
        hapticHighlightFlexChildren
      )
    }
    return this.registry.get('hapticHighlightFlexChildren')
  }

  getHapticHighlightWindow(): HapticHighlightWindow {
    if (!this.registry.has('hapticHighlightWindow')) {
      const hapticHighlightWindow = new HapticHighlightWindow(
        this.getDocument(),
        this.getCanvas()
      )
      this.registry.set('hapticHighlightWindow', hapticHighlightWindow)
    }
    return this.registry.get('hapticHighlightWindow')
  }

  getHapticMultiselectWindow(): HapticMultiselectWindow {
    if (!this.registry.has('hapticMultiselectWindow')) {
      const hapticMultiselectWindow = new HapticMultiselectWindow(
        this.getCanvas()
      )
      this.registry.set('hapticMultiselectWindow', hapticMultiselectWindow)
    }
    return this.registry.get('hapticMultiselectWindow')
  }

  getHapticNewParentWindow(): HapticNewParentWindow {
    if (!this.registry.has('hapticNewParentWindow')) {
      const hapticNewParentWindow = new HapticNewParentWindow(
        this.getDocument(),
        this.getCanvas()
      )
      this.registry.set('hapticNewParentWindow', hapticNewParentWindow)
    }
    return this.registry.get('hapticNewParentWindow')
  }

  getHapticNodeTitles(): HapticNodeTitles {
    if (!this.registry.has('hapticNodeTitles')) {
      const hapticNodeTitles = new HapticNodeTitles(
        this.getCanvas(),
        this.getFontLoader(),
        this.getTextShaper(),
        this.getIconLoader()
      )
      this.registry.set('hapticNodeTitles', hapticNodeTitles)
    }
    return this.registry.get('hapticNodeTitles')
  }

  getHapticSelectionWindow(): HapticSelectionWindow {
    if (!this.registry.has('hapticSelectionWindow')) {
      const hapticSelectionWindow = new HapticSelectionWindow(
        this.getDocument(),
        this.getFontLoader(),
        this.getTextShaper(),
        this.getCanvas()
      )
      this.registry.set('hapticSelectionWindow', hapticSelectionWindow)
    }
    return this.registry.get('hapticSelectionWindow')
  }

  getHapticGridlines(): HapticGridlines {
    if (!this.registry.has('gridlines')) {
      const gridlines = new HapticGridlines(this.getCanvas())
      this.registry.set('gridlines', gridlines)
    }
    return this.registry.get('gridlines')
  }

  getHapticGridlinesSmall(): HapticGridlinesSmall {
    if (!this.registry.has('gridlinesSmall')) {
      const gridlinesSmall = new HapticGridlinesSmall(this.getCanvas())
      this.registry.set('gridlinesSmall', gridlinesSmall)
    }
    return this.registry.get('gridlinesSmall')
  }

  getHapticTextEditor(): HapticTextEditor {
    if (!this.registry.has('hapticTextEditor')) {
      const hapticTextEditor = new HapticTextEditor(
        this.getDocumentSelection(),
        this.getCanvas(),
        this.getTextEditorHapticCalculator()
      )
      this.registry.set('hapticTextEditor', hapticTextEditor)
    }
    return this.registry.get('hapticTextEditor')
  }

  getNodeTitleGenerator(): NodeTitleGenerator {
    if (!this.registry.has('nodeTitleGenerator')) {
      const nodeTitleGenerator = new NodeTitleGenerator(
        this.getTextShaper(),
        this.getCameraService()
      )
      this.registry.set('nodeTitleGenerator', nodeTitleGenerator)
    }
    return this.registry.get('nodeTitleGenerator')
  }

  getNodeTitlesService(): NodeTitlesService {
    if (!this.registry.has('nodeTitlesService')) {
      const nodeTitlesService = new NodeTitlesService(
        this.getDocument(),
        this.getDocumentSelection(),
        this.getNodeTitleGenerator()
      )
      this.registry.set('nodeTitlesService', nodeTitlesService)
    }
    return this.registry.get('nodeTitlesService')
  }

  getAttributeStore(): AttributeStore {
    if (!this.registry.has('attributeStore')) {
      this.registry.set(
        'attributeStore',
        new AttributeStore(this.getDocument())
      )
    }
    return this.registry.get('attributeStore')
  }

  getDefaultTextAttributes(): DefaultTextAttributes {
    if (!this.registry.has('defaultTextAttributes')) {
      this.registry.set(
        'defaultTextAttributes',
        new DefaultTextAttributes(
          this.getDocumentSelection(),
          this.getTextAttributeFactory()
        )
      )
    }
    return this.registry.get('defaultTextAttributes')
  }

  getBackgroundPanel(): BackgroundPanel {
    if (!this.registry.has('backgroundPanel')) {
      this.registry.set(
        'backgroundPanel',
        new BackgroundPanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('backgroundPanel')
  }

  getBorderPanel(): BorderPanel {
    if (!this.registry.has('borderPanel')) {
      this.registry.set(
        'borderPanel',
        new BorderPanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('borderPanel')
  }

  getCursorPanel(): CursorPanel {
    if (!this.registry.has('cursorPanel')) {
      this.registry.set(
        'cursorPanel',
        new CursorPanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('cursorPanel')
  }

  getDisplayPanel(): DisplayPanel {
    if (!this.registry.has('displayPanel')) {
      this.registry.set(
        'displayPanel',
        new DisplayPanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('displayPanel')
  }

  getFilterBlurPanel(): FilterBlurPanel {
    if (!this.registry.has('filterBlurPanel')) {
      this.registry.set(
        'filterBlurPanel',
        new FilterBlurPanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('filterBlurPanel')
  }

  getFlexChildPanel(): FlexChildPanel {
    if (!this.registry.has('flexChildPanel')) {
      this.registry.set(
        'flexChildPanel',
        new FlexChildPanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('flexChildPanel')
  }

  getFontPopupPanel(): FontPopupPanel {
    if (!this.registry.has('fontPopupPanel')) {
      this.registry.set(
        'fontPopupPanel',
        new FontPopupPanel(
          this.getEditorProjectService(),
          this.getBackendService(),
          this.getFontLoader(),
          this.getFontMap()
        )
      )
    }
    return this.registry.get('fontPopupPanel')
  }

  getImageLibraryPanel(): ImageLibraryPanel {
    if (!this.registry.has('imageLibraryPanel')) {
      this.registry.set(
        'imageLibraryPanel',
        new ImageLibraryPanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('imageLibraryPanel')
  }

  getImagePanel(): ImagePanel {
    if (!this.registry.has('imagePanel')) {
      this.registry.set(
        'imagePanel',
        new ImagePanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('imagePanel')
  }

  getOpacityPanel(): OpacityPanel {
    if (!this.registry.has('opacityPanel')) {
      this.registry.set(
        'opacityPanel',
        new OpacityPanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('opacityPanel')
  }

  getOverflowPanel(): OverflowPanel {
    if (!this.registry.has('overflowPanel')) {
      this.registry.set(
        'overflowPanel',
        new OverflowPanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('overflowPanel')
  }

  getPaddingPanel(): PaddingPanel {
    if (!this.registry.has('paddingPanel')) {
      this.registry.set(
        'paddingPanel',
        new PaddingPanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('paddingPanel')
  }

  getPositionModePanel(): PositionModePanel {
    if (!this.registry.has('positionModePanel')) {
      this.registry.set(
        'positionModePanel',
        new PositionModePanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('positionModePanel')
  }

  getPositionPanel(): PositionPanel {
    if (!this.registry.has('positionPanel')) {
      this.registry.set(
        'positionPanel',
        new PositionPanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('positionPanel')
  }

  getScreenSizePanel(): ScreenSizePanel {
    if (!this.registry.has('screenSizePanel')) {
      this.registry.set(
        'screenSizePanel',
        new ScreenSizePanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('screenSizePanel')
  }

  getRoundingPanel(): RoundingPanel {
    if (!this.registry.has('roundingPanel')) {
      this.registry.set(
        'roundingPanel',
        new RoundingPanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('roundingPanel')
  }

  getSelectionColorsPanel(): SelectionColorsPanel {
    if (!this.registry.has('selectionColorsPanel')) {
      this.registry.set(
        'selectionColorsPanel',
        new SelectionColorsPanel(
          this.getClient(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('selectionColorsPanel')
  }

  getShadowPanel(): ShadowPanel {
    if (!this.registry.has('shadowPanel')) {
      this.registry.set(
        'shadowPanel',
        new ShadowPanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('shadowPanel')
  }

  getSizePanel(): SizePanel {
    if (!this.registry.has('sizePanel')) {
      this.registry.set(
        'sizePanel',
        new SizePanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('sizePanel')
  }

  getSizeRatioPanel(): SizeRatioPanel {
    if (!this.registry.has('sizeRatioPanel')) {
      this.registry.set(
        'sizeRatioPanel',
        new SizeRatioPanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('sizeRatioPanel')
  }

  getStyleOverridesPanel(): StyleOverridesPanel {
    if (!this.registry.has('styleOverridesPanel')) {
      this.registry.set(
        'styleOverridesPanel',
        new StyleOverridesPanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('styleOverridesPanel')
  }

  getTextPanel(): TextPanelDefault {
    if (!this.registry.has('textPanel')) {
      this.registry.set(
        'textPanel',
        new TextPanelDefault(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection(),
          this.getFontMap()
        )
      )
    }
    return this.registry.get('textPanel')
  }

  getTextPanelTextEditor(): TextPanelTextEditor {
    if (!this.registry.has('textPanelTextEditor')) {
      this.registry.set(
        'textPanelTextEditor',
        new TextPanelTextEditor(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection(),
          this.getFontMap()
        )
      )
    }
    return this.registry.get('textPanelTextEditor')
  }

  getTransitionPanel(): TransitionPanel {
    if (!this.registry.has('transitionPanel')) {
      this.registry.set(
        'transitionPanel',
        new TransitionPanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('transitionPanel')
  }

  getCodePanel(): CodePanel {
    if (!this.registry.has('codePanel')) {
      this.registry.set(
        'codePanel',
        new CodePanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection(),
          this.getCodeExporter()
        )
      )
    }
    return this.registry.get('codePanel')
  }

  getHTMLAttributePanel(): HTMLAttributePanel {
    if (!this.registry.has('htmlAttributePanel')) {
      this.registry.set(
        'htmlAttributePanel',
        new HTMLAttributePanel(
          this.getClient(),
          this.getNodeAttributesAction(),
          this.getDocument(),
          this.getDocumentSelection()
        )
      )
    }
    return this.registry.get('htmlAttributePanel')
  }

  getLayersPanel(): LayersPanel {
    if (!this.registry.has('layersPanel')) {
      this.registry.set(
        'layersPanel',
        new LayersPanel(
          this.getDocument(),
          this.getDocumentSelection(),
          this.getNodeSelectionAction(),
          this.getEditorHighlightService(),
          this.getActionInitiator(),
          this.getClient()
        )
      )
    }
    return this.registry.get('layersPanel')
  }

  getDetailsPanel(): DetailsPanel {
    if (!this.registry.has('detailsPanel')) {
      this.registry.set('detailsPanel', new DetailsPanel(this.getDocument()))
    }
    return this.registry.get('detailsPanel')
  }

  getTemplatesPanel(): TemplatesPanel {
    if (!this.registry.has('templatesPanel')) {
      this.registry.set(
        'templatesPanel',
        new TemplatesPanel(this.getBackendService(), this.getActionInitiator())
      )
    }
    return this.registry.get('templatesPanel')
  }

  getCameraPanel(): CameraPanel {
    if (!this.registry.has('cameraPanel')) {
      this.registry.set('cameraPanel', new CameraPanel(this.getCameraService()))
    }
    return this.registry.get('cameraPanel')
  }

  getFPSPanel(): FPSPanel {
    if (!this.registry.has('fpsPanel')) {
      this.registry.set('fpsPanel', new FPSPanel())
    }
    return this.registry.get('fpsPanel')
  }

  getNodeActions = (): NodeActions => {
    if (!this.registry.has('nodeActions')) {
      this.registry.set(
        'nodeActions',
        new NodeActions(
          this.getClient(),
          this.getFlexAction(),
          this.getGroupAction(),
          this.getRearrangeAction(),
          this.getPasteAction()
        )
      )
    }
    return this.registry.get('nodeActions')
  }

  getHTTPServiceURL(): string {
    const { REACT_APP_SERVER_ADDRESS } = process.env
    const SERVER_ADDRESS = REACT_APP_SERVER_ADDRESS
      ? REACT_APP_SERVER_ADDRESS
      : 'http://localhost:5000'
    return SERVER_ADDRESS
  }

  getWSServiceURL(): string {
    const { REACT_APP_SERVER_WS_ADDRESS } = process.env
    const WS_ADDRESS = REACT_APP_SERVER_WS_ADDRESS
      ? REACT_APP_SERVER_WS_ADDRESS
      : 'ws://localhost:5000'
    return WS_ADDRESS
  }

  getAuthService(): LocalStorageAuthService {
    if (!this.registry.has('authService')) {
      this.registry.set('authService', new LocalStorageAuthService())
    }
    return this.registry.get('authService')
  }

  getHTTPService(): HTTPService {
    if (!this.registry.has('httpService')) {
      this.registry.set(
        'httpService',
        new HTTPService(this.getHTTPServiceURL())
      )
    }
    return this.registry.get('httpService')
  }

  getBackendService(): BackendService {
    if (!this.registry.has('backendService')) {
      this.registry.set(
        'backendService',
        new BackendService(this.getHTTPService(), this.getAuthService())
      )
    }
    return this.registry.get('backendService')
  }

  getWebsocketConnection(): WebsocketConnection {
    if (!this.registry.has('websocketConnection')) {
      this.registry.set(
        'websocketConnection',
        new WebsocketConnection(this.getWSServiceURL())
      )
    }
    return this.registry.get('websocketConnection')
  }

  getWebsocketService(): WebsocketService {
    if (!this.registry.has('websocketService')) {
      this.registry.set(
        'websocketService',
        new WebsocketService(
          this.getWebsocketConnection(),
          this.getAuthService()
        )
      )
    }
    return this.registry.get('websocketService')
  }

  getEditorWebsocketService(): EditorWebsocketService {
    if (!this.registry.has('editorWebsocketService')) {
      this.registry.set(
        'editorWebsocketService',
        new EditorWebsocketService(this.getWebsocketService())
      )
    }
    return this.registry.get('editorWebsocketService')
  }

  getEditorProjectService(): EditorProjectService {
    if (!this.registry.has('editorProjectService')) {
      this.registry.set(
        'editorProjectService',
        new EditorProjectService(
          this.getClient(),
          this.getBackendService(),
          this.getEditorWebsocketService(),
          this.getDocument(),
          this.getFontLoader(),
          this.getFontMap()
        )
      )
    }
    return this.registry.get('editorProjectService')
  }

  getUI(): EditorUI {
    if (!this.registry.has('ui')) {
      this.registry.set(
        'ui',
        new EditorUI(
          this.getActionInitiator(),
          this.getCreateAction(),
          this.getNodeActions(),
          this.getDocument(),
          this.getDocumentSelection(),
          this.getClient(),
          this.getBackgroundPanel(),
          this.getBorderPanel(),
          this.getCursorPanel(),
          this.getDisplayPanel(),
          this.getFilterBlurPanel(),
          this.getFlexChildPanel(),
          this.getFontPopupPanel(),
          this.getImageLibraryPanel(),
          this.getImagePanel(),
          this.getOpacityPanel(),
          this.getOverflowPanel(),
          this.getPaddingPanel(),
          this.getPositionModePanel(),
          this.getPositionPanel(),
          this.getRoundingPanel(),
          this.getSelectionColorsPanel(),
          this.getScreenSizePanel(),
          this.getShadowPanel(),
          this.getSizePanel(),
          this.getSizeRatioPanel(),
          this.getStyleOverridesPanel(),
          this.getTextPanel(),
          this.getTextPanelTextEditor(),
          this.getTransitionPanel(),
          this.getCodePanel(),
          this.getHTMLAttributePanel(),
          this.getLayersPanel(),
          this.getDetailsPanel(),
          this.getTemplatesPanel(),
          this.getCameraPanel(),
          this.getFPSPanel(),
          this.getEditorProjectService(),
          this.getEditorWebsocketService()
        )
      )
    }
    return this.registry.get('ui')
  }

  getEditor(): Editor {
    return new Editor(
      this.getCanvasSizeService(),
      this.getCanvas(),
      this.getImageExporter(),
      this.getDocumentRenderingService(),
      this.getBrowserEventService(),
      this.getUI()
    )
  }
}
