import { Point, Rectangle } from 'application/shapes'
import { NodeCreationHandler } from './creation/creation'
import {
  AttributeFill,
  AttributeType,
  BaseMap,
  StyleMap,
  createNewBlackFill,
  createNewWhiteFill,
} from 'application/attributes'
import { InsertionStrategyFactory } from './creation/strategy/strategyFactory'
import { ReadOnlyDocument } from 'application/document'
import { Command } from 'application/client'
import { ReadOnlyNode } from 'application/node'
import { Color, hsbaToRgba, rgbaToHsba } from 'application/color'

interface CommandHandler {
  handle: (command: Command[]) => void
}

export class NodeCreateAction {
  private commandHandler: CommandHandler
  private nodeCreationHandler: NodeCreationHandler
  private strategyFactory: InsertionStrategyFactory
  private document: ReadOnlyDocument

  private progressiveFillStrategy: FillComputer

  constructor(
    commandHandler: CommandHandler,
    nodeCreationHandler: NodeCreationHandler,
    insertionStrategyFactory: InsertionStrategyFactory,
    document: ReadOnlyDocument
  ) {
    this.commandHandler = commandHandler
    this.nodeCreationHandler = nodeCreationHandler
    this.strategyFactory = insertionStrategyFactory
    this.document = document

    this.progressiveFillStrategy = new FillComputer()
  }

  click = (type: AttributeType, point: Point): string => {
    const { w, h } = this.getDefaultSize(type)
    const delta = { dx: w / 2, dy: h / 2 }

    const strategy = this.strategyFactory.createClickedNodeStrategy(
      type,
      point,
      delta
    )
    const { base, style } = this.getDefaultAttributes(type)

    const id = this.nodeCreationHandler.createOnCanvas(
      type,
      base,
      style,
      strategy
    )

    this.setFill(id)

    return id
  }

  insert = (type: AttributeType, rectangle?: Rectangle): string => {
    const defaultRectangle = rectangle || this.getDefaultSize(type)
    const strategy = this.strategyFactory.createCanvasStrategy(defaultRectangle)
    const { base, style } = this.getDefaultAttributes(type, rectangle)

    return this.nodeCreationHandler.createOnCanvas(type, base, style, strategy)
  }

  canvas = (): string => {
    return this.nodeCreationHandler.createCanvas()
  }

  private getDefaultAttributes = (
    type: AttributeType,
    rectangle?: Rectangle
  ): {
    base: Partial<BaseMap>
    style: Partial<StyleMap>
  } => {
    const base: Partial<BaseMap> = {}
    const style: Partial<StyleMap> = {}
    switch (type) {
      case 'text':
      case 'input':
        break
      default:
        if (rectangle) {
          base['w'] = rectangle.w
          base['h'] = rectangle.h
          style['size.h.unit'] = 'px'
          style['size.w.unit'] = 'px'
          style['size.w.px'] = rectangle.w
          style['size.h.px'] = rectangle.h
        } else {
          const { w, h } = this.getDefaultSize(type)
          base['w'] = w
          base['h'] = h
          style['size.h.unit'] = 'px'
          style['size.w.unit'] = 'px'
          style['size.h.px'] = h
          style['size.w.px'] = w
        }
    }

    return { base, style }
  }

  private getDefaultSize = (type: AttributeType): Rectangle => {
    switch (type) {
      case 'frame':
      case 'image':
      case 'form':
        return { x: 0, y: 0, w: 100, h: 100 }
      case 'text':
        return { x: 0, y: 0, w: 0, h: 0 }
      case 'page':
        return { x: 0, y: 0, w: 1440, h: 1080 }
      default:
        return { x: 0, y: 0, w: 100, h: 100 }
    }
  }

  private setFill(id: string): void {
    const node = this.document.getNode(id)
    if (!node) return

    const parents = this.document.getAncestors(node)
    if (parents.length === 0) return

    switch (node.getBaseAttribute('type')) {
      case 'frame':
        const fills = this.progressiveFillStrategy.getProgressiveFills(parents)
        if (!fills) return
        this.commandHandler.handle([
          {
            type: 'setNodeAttribute',
            params: {
              id: id,
              style: { background: fills },
              base: {},
              selector: 'default',
            },
          },
        ])
        break
      case 'text':
      case 'input':
        const color = this.progressiveFillStrategy.getWhiteBlackFills(parents)
        if (!color || color.length !== 1) return
        this.commandHandler.handle([
          {
            type: 'setNodeAttribute',
            params: {
              id: id,
              style: { 'text.color': color[0] },
              base: {},
              selector: 'default',
            },
          },
        ])
        break
      default:
        return
    }
  }
}

class FillComputer {
  getProgressiveFills(parents: ReadOnlyNode[]): AttributeFill[] | null {
    const fill = this.getFirstParentFill(parents)
    if (!fill || !fill.color) return null

    const b = this.getBrightness(fill.color)
    const color = this.adjustBrightness(fill.color, b > 50 ? -8 : 8)
    return [{ type: 'color', color }]
  }

  getWhiteBlackFills(parents: ReadOnlyNode[]): AttributeFill[] | null {
    const fill = this.getFirstParentFill(parents)
    if (!fill || !fill.color) return null

    const brightness = this.getBrightness(fill.color)
    return brightness > 50 ? [createNewBlackFill()] : [createNewWhiteFill()]
  }

  private getFirstParentFill = (
    parents: ReadOnlyNode[]
  ): AttributeFill | null => {
    for (const parent of parents) {
      const fills = parent.getStyleAttribute('background')
      if (!fills || fills.length === 0) continue
      return fills[0]
    }
    return null
  }

  private getBrightness = (color: Color): number => {
    const hsb = rgbaToHsba(color)
    return hsb.b
  }

  private adjustBrightness = (color: Color, delta: number): Color => {
    const hsb = rgbaToHsba(color)
    return hsbaToRgba({ ...hsb, b: Math.min(Math.max(hsb.b + delta, 0), 100) })
  }
}
