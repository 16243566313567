import { useEffect, useState } from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import IconBlock from '../../IconBlock/IconBlock/IconBlock'
import { DesignColor } from 'themes'

declare const prettier: any
declare const prettierPlugins: any

export interface TextCodeProps {
  code: string
  language: TextCodeLanguage
  copy: () => void
  bottom: boolean
}

export type TextCodeLanguage = 'javascript' | 'html' | 'css'

const languageToParser = {
  javascript: 'babel',
  html: 'html',
  css: 'css',
}

const languageToSyntax = {
  javascript: 'javascript',
  html: 'html',
  css: 'css',
}

export default function TextCode({
  code,
  language,
  copy,
  bottom,
}: TextCodeProps) {
  const [formattedCode, setFormattedCode] = useState<string>('<div></div>')

  const transparentTheme = {
    ...oneDark,
    'code[class*="language-"]': {
      ...oneDark['code[class*="language-"]'],
      background: 'transparent',
    },
  }

  useEffect(() => {
    const options = {
      parser: languageToParser[language],
      plugins: prettierPlugins,
      semi: true,
      singleQuote: true,
      tabWidth: 2,
    }
    prettier.format(code, options).then(setFormattedCode)
  }, [code, language])

  return (
    <CodeBlockWrapper bottom={bottom} copy={copy}>
      <SyntaxHighlighter
        className={'code'}
        language={languageToSyntax[language]}
        style={transparentTheme}
        customStyle={{
          fontSize: '11px',
          background: 'transparent',
          padding: 0,
          margin: 0,
          maxHeight: 200,
        }}
      >
        {formattedCode}
      </SyntaxHighlighter>
    </CodeBlockWrapper>
  )
}

function CodeBlockWrapper({
  children,
  bottom,
  copy,
}: {
  children: React.ReactNode
  bottom: boolean
  copy: () => void
}) {
  return (
    <div
      style={{
        position: 'relative',
        padding: 8,
        borderBottom: !bottom ? `1px solid ${DesignColor('panelBorder')}` : 0,
      }}
    >
      <IconBlock
        style={{
          position: 'absolute',
          top: 4,
          right: 4,
          background: 'rgb(30, 30, 30)',
        }}
        icon={'Copy'}
        onClick={copy}
      />
      {children}
    </div>
  )
}
