import { ClientUpdateListener, Update } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { ReadOnlyNode } from 'application/node'
import { DocumentRenderTransformer } from './transformer'
import { Canvas } from 'editor/canvas/canvas'
import { FontLoaderInterface, Shaper } from 'application/text'

export class DocumentRenderingService implements ClientUpdateListener {
  private document: ReadOnlyDocument
  private selection: ReadOnlyDocumentSelection
  private canvas: Canvas
  private fontLoader: FontLoaderInterface
  private textShaper: Shaper

  constructor(
    document: ReadOnlyDocument,
    selection: ReadOnlyDocumentSelection,
    canvas: Canvas,
    fontLoader: FontLoaderInterface,
    textShaper: Shaper
  ) {
    this.document = document
    this.selection = selection
    this.canvas = canvas
    this.fontLoader = fontLoader
    this.textShaper = textShaper
  }

  init = () => {
    this.renderDocument()
  }

  getTypes = (): Update['type'][] => {
    return [
      'node_created',
      'node_deleted',
      'node_updated',
      'initialize',
      'selected_canvas',
    ]
  }

  onUpdate = (updates: Update[]) => {
    for (const update of updates) {
      switch (update.type) {
        case 'initialize':
        case 'selected_canvas':
          this.renderDocument()
          break
        case 'node_created':
          this.handleNodeCreated(update.data.id)
          break
        case 'node_deleted':
          this.handleNodeDeleted(update.data.id)
          break
        case 'node_updated':
          this.handleNodeUpdated(update.data.id)
          break
      }
    }
  }

  private handleNodeCreated = (nodeId: string) => {
    const node = this.document.getNode(nodeId)
    if (!node) return

    this.renderNode(node)
  }

  private handleNodeDeleted = (nodeId: string) => {
    this.canvas.deleteNode(nodeId)
  }

  private handleNodeUpdated = (nodeId: string) => {
    const node = this.document.getNode(nodeId)
    if (!node) return

    this.renderNode(node)
  }

  private renderDocument = () => {
    const canvasId = this.selection.getSelectedCanvas()
    if (!canvasId) return

    this.renderTree(canvasId)
  }

  private renderTree = (nodeId: string) => {
    const node = this.document.getNode(nodeId)
    if (!node) return

    this.renderNode(node)

    const children = node.getChildren()
    if (!children) return

    for (const child of children) {
      this.renderTree(child)
    }
  }

  private renderNode = (node: ReadOnlyNode) => {
    const context = this.canvas.getContext()
    const parent = this.document.getParent(node)
    const transformed = DocumentRenderTransformer.transform(
      context,
      node,
      parent,
      this.fontLoader,
      this.textShaper
    )
    if (transformed) this.canvas.setNode(transformed)
  }
}
