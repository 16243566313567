import { CodeBlockType } from 'application/export'
import TitleButtonBlock from 'components/Library/Components/Button/TitleButtonBlock/TitleButtonBlock'
import CodeBlock from 'components/Library/Components/CodeBlock/CodeBlock'
import { dropdownOption } from 'components/Library/Components/Dropdown/Dropdown'
import TextCode from 'components/Library/Components/Text/TextCode/TextCode'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import useToast from 'hooks/editor/useToast'
import { editor } from 'index'

export default function CodeExport() {
  const toast = useToast()
  const codePanel = usePanelHelper(editor.getUI().getCodePanel())
  if (!codePanel.settings || !codePanel.settings.export) return null

  const { exportMode, styleMode } = codePanel.settings
  const { code, style, font } = codePanel.settings.export

  return (
    <>
      <PanelSection>
        <PanelRow>
          <TitleButtonBlock title={'Code'} active={true} />
        </PanelRow>
        <CodeBlock
          options={codeExportOptions}
          selected={exportMode}
          select={(value) => codePanel.handlers.setExportMode(value)}
        >
          {code.map((block, index) => (
            <TextCode
              key={index}
              language={block.type}
              code={block.code}
              copy={() => {
                if (!codePanel.settings) return
                navigator.clipboard.writeText(block.code)
                toast('Copied code to clipboard', 'success')
              }}
              bottom={index === code.length - 1}
            />
          ))}
        </CodeBlock>
      </PanelSection>
      <PanelSection>
        <PanelRow>
          <TitleButtonBlock title={'Styles'} active={true} />
        </PanelRow>
        <CodeBlock
          options={styleExportOptions}
          selected={styleMode}
          select={(value) => codePanel.handlers.setStyleMode(value)}
        >
          <TextCode
            language={styleMode}
            code={style.code}
            copy={() => {
              if (!codePanel.settings) return
              navigator.clipboard.writeText(style.code)
              toast('Copied styles to clipboard', 'success')
            }}
            bottom={true}
          />
        </CodeBlock>
      </PanelSection>
      {font.code !== '' && (
        <PanelSection>
          <PanelRow>
            <TitleButtonBlock title={'Fonts'} active={true} />
          </PanelRow>
          <CodeBlock
            options={fontExportOptions}
            selected={'html'}
            select={() => {}}
          >
            <TextCode
              language={'html'}
              code={font.code}
              copy={() => {
                if (!codePanel.settings) return
                navigator.clipboard.writeText(font.code)
                toast('Copied fonts to clipboard', 'success')
              }}
              bottom={true}
            />
          </CodeBlock>
        </PanelSection>
      )}
    </>
  )
}

const styleExportOptions: dropdownOption<CodeBlockType>[] = [
  { value: 'css', text: 'CSS' },
]

const fontExportOptions: dropdownOption<CodeBlockType>[] = [
  { value: 'html', text: 'Fonts' },
]

const codeExportOptions: dropdownOption<CodeBlockType>[] = [
  { value: 'html', text: 'HTML & CSS' },
]
