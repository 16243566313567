import {
  AttributeType,
  canTypeInsertInto,
  isInteractable,
} from 'application/attributes'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import { QuadTree } from 'application/quadtree'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { Point } from 'application/shapes'
import { QuadTreeNodeFilter } from '../types'
import { Camera } from 'application/camera'
import { getPadding, isFlex } from 'application/attributes/utils'

interface Filter {
  (id: string, parentId: string): boolean
}

interface CameraService {
  getCamera: () => Camera
}

export class ReparentCalculator {
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private quadtree: QuadTree
  private cameraService: CameraService
  private filters: Filter[]

  constructor(
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    quadtree: QuadTree,
    cameraService: CameraService
  ) {
    this.document = document
    this.documentSelection = documentSelection
    this.quadtree = quadtree
    this.cameraService = cameraService
    this.filters = [
      this.filterWidth,
      this.filterHeight,
      this.filterSiblingWidth,
      this.filterSiblingHeight,
      this.filterDescendants,
    ]
  }

  getParentAtPoint = (
    ids: string[],
    point: Point,
    allowedIds: string[] = [],
    filteredIds: string[] = []
  ): string | undefined => {
    const canvasId = this.documentSelection.getSelectedCanvas()
    const canvasIdFiltered = filteredIds.includes(canvasId)

    const nodes = this.getNodes(ids)
    const types = this.getTypes(nodes)
    if (nodes.length === 0 || types.length === 0) return undefined

    const containers = this.containerNodesAtPoint(types, point, filteredIds)
    if (containers.length === 0) return canvasIdFiltered ? undefined : canvasId

    const valid = containers.filter(
      (c) =>
        allowedIds.includes(c) ||
        (this.filters.every((f) => ids.every((id) => f(id, c))) &&
          !ids.includes(c))
    )

    if (valid.length === 0) return canvasIdFiltered ? undefined : canvasId

    const closeToEdge = this.getCloseToEdgeFlex(valid, point)
    if (closeToEdge) return closeToEdge

    return valid[0]
  }

  private containerNodesAtPoint = (
    types: AttributeType[],
    point: Point,
    filtered: string[] = []
  ): string[] => {
    const rectanglesAtPoint = this.quadtree.getAtPoint(
      point,
      QuadTreeNodeFilter
    )

    return rectanglesAtPoint
      .filter((r) => {
        const node = this.document.getNode(r.id)
        if (!node) return false

        return (
          !filtered.includes(r.id) &&
          isInteractable(node) &&
          types.every((t) =>
            canTypeInsertInto(t, node.getBaseAttribute('type'))
          )
        )
      })
      .map((r) => r.id)
  }

  private getNodes = (ids: string[]): ReadOnlyNode[] => {
    return ids
      .map((id) => this.document.getNode(id))
      .filter((n) => n) as ReadOnlyNode[]
  }

  private getTypes = (nodes: ReadOnlyNode[]): AttributeType[] => {
    return nodes
      .map((n) => n.getBaseAttribute('type'))
      .filter((t) => t) as AttributeType[]
  }

  private filterWidth = (id: string, parentId: string): boolean => {
    const node = this.document.getNode(id)
    if (!node) return false

    const parent = this.document.getNode(parentId)
    if (!parent) return false
    if (parent.getStyleAttribute('display') !== 'flex') return true

    return this.filterSize('w', node, parent)
  }

  private filterHeight = (id: string, parentId: string): boolean => {
    const node = this.document.getNode(id)
    if (!node) return false

    const parent = this.document.getNode(parentId)
    if (!parent) return false
    if (parent.getStyleAttribute('display') !== 'flex') return true

    return this.filterSize('h', node, parent)
  }

  private filterSiblingWidth = (id: string, parentId: string): boolean => {
    const node = this.document.getNode(id)
    if (!node) return false

    const parent = this.document.getNode(parentId)
    if (!parent) return false

    if (parent.getParent() !== node.getParent()) return true

    return this.filterSize('w', node, parent)
  }

  private filterSiblingHeight = (id: string, parentId: string): boolean => {
    const node = this.document.getNode(id)
    if (!node) return false

    const parent = this.document.getNode(parentId)
    if (!parent) return false

    if (parent.getParent() !== node.getParent()) return true

    return this.filterSize('h', node, parent)
  }

  private filterDescendants = (id: string, parentId: string): boolean => {
    const node = this.document.getNode(id)
    if (!node) return false

    const parent = this.document.getNode(parentId)
    if (!parent) return false

    return !this.document.isDescendantOf(parent, node)
  }

  private filterSize = (
    axis: 'w' | 'h',
    node: ReadOnlyNode,
    parent: ReadOnlyNode
  ): boolean => {
    if (parent.getBaseAttribute('type') === 'page') return true

    const sizeKey = axis
    const padding1Key = axis === 'w' ? 'left' : 'top'
    const padding2Key = axis === 'w' ? 'right' : 'bottom'

    const parentSize = parent.getBaseAttribute(sizeKey)
    const parentPadding1 = getPadding(parent, padding1Key)
    const parentPadding2 = getPadding(parent, padding2Key)
    const nodeSize = node.getBaseAttribute(sizeKey)

    const parentWidthBounds = parentSize - parentPadding1 - parentPadding2
    return parentWidthBounds >= nodeSize
  }

  private getCloseToEdgeFlex = (ids: string[], point: Point): string | null => {
    const offset = 10 / this.cameraService.getCamera().z
    for (const id of [...ids].reverse()) {
      const node = this.document.getNode(id)
      if (!node || !isFlex(node)) continue

      const direction = node.getStyleAttribute('flex.direction')
      const x = node.getBaseAttribute('x')
      const y = node.getBaseAttribute('y')
      const w = node.getBaseAttribute('w')
      const h = node.getBaseAttribute('h')

      if (direction === 'row') {
        if (point.x < x + offset) return id
        if (point.x > x + w - offset) return id
      } else if (direction === 'column') {
        if (point.y < y + offset) return id
        if (point.y > y + h - offset) return id
      }
    }

    return null
  }
}
