import ProjectSettingField from './ProjectSettingField'
import { useRecoilState } from 'recoil'
import ProjectSettingCard from './ProjectSettingCard'
import MenuButton from 'components/Library/Menus/MenuButton'
import useAuthService from 'hooks/services/useAuthService'
import useNavigatePage from 'hooks/admin/useNavigatePage'
import { DesignColor } from 'themes'
import useUserService from 'hooks/services/useUserService'
import useToast from 'hooks/editor/useToast'
import { userInfoAtom } from 'components/Pages/Projects/Projects'

export default function ProjectSettingBody() {
  const authService = useAuthService()
  const userService = useUserService()
  const toast = useToast()
  const { toLogin } = useNavigatePage()

  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom)

  const updateName = async (firstName: string, lastName: string) => {
    try {
      await userService.updateUserInfo({ firstName, lastName })
      const updated = await userService.getUserInfo()
      setUserInfo(updated)
      toast('User Info Updated', 'success')
    } catch (e) {
      toast('Repaint is offline, please try again later.', 'error')
    }
  }

  return (
    <div
      style={{
        width: '100%',
        padding: '24px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: '24px',
      }}
    >
      <ProjectSettingCard>
        <h2
          style={{
            color: DesignColor('text1'),
            fontSize: '20px',
            fontWeight: 400,
          }}
        >
          Account
        </h2>
        <div
          style={{
            display: 'flex',
            gap: '16px',
          }}
        >
          <ProjectSettingField
            label="First Name"
            placeholder="John"
            value={userInfo.firstName}
            setValue={(first: string) => updateName(first, userInfo.lastName)}
          />
          <ProjectSettingField
            label="Last Name"
            placeholder="Doe"
            value={userInfo.lastName}
            setValue={(last: string) => updateName(userInfo.firstName, last)}
          />
        </div>
        <MenuButton
          label="Logout"
          onClick={() => {
            authService.clearToken()
            toLogin()
          }}
        />
      </ProjectSettingCard>
    </div>
  )
}
