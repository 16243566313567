import {
  ClientUpdateListener,
  CommandHandler,
  Update,
} from 'application/client'
import { DisplayPanel } from './displayPanel'
import { PositionPanel } from './positionPanel'
import { TextPanelDefault } from './text/textPanel'
import { TextPanel } from './text/interface'
import { TextPanelTextEditor } from './text/textPanelTextEditor'
import {
  EditorActionListener,
  EditorSelectionListener,
  UIActionInitiator,
  UINodeActions,
  UIProjectService,
  UIWebsocketService,
} from './types'
import {
  Action,
  ActionHandler,
  ActiveActionListener,
} from 'editor/action/types'
import { ActionInitiator } from 'editor/action/initiator'
import { LayersPanel } from './layersPanel'
import { BorderPanel } from './borderPanel'
import { ShadowPanel } from './shadowPanel'
import { OpacityPanel } from './opacityPanel'
import { OverflowPanel } from './overflowPanel'
import { RoundingPanel } from './roundingPanel'
import { CreateAction } from 'editor/action/node/create'
import { ImagePanel } from './imagePanel'
import { FilterBlurPanel } from './filterBlurPanel'
import { SelectionColorsPanel } from './selectionColors/selectionColorsPanel'
import { NodeActions } from './nodeActions'
import { EditorProjectService } from 'editor/project/project'
import { ImageLibraryPanel } from './imageLibraryPanel'
import { ScreenSizePanel } from './screenSizePanel'
import { FPSPanel } from './fpsPanel'
import { DetailsPanel } from './namePanel'
import { CameraPanel } from './cameraPanel'
import { TemplatesPanel } from './templatesPanel'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import { PositionModePanel } from './positionModePanel'
import { SizePanel } from './sizePanel'
import { StyleOverridesPanel } from './styleOverrides'
import { BackgroundPanel } from './backgroundPanel'
import { TransitionPanel } from './transitionPanel'
import { EditorWebsocketService } from 'editor/websocket/websocket'
import { CursorPanel } from './cursorPanel'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { FontPopupPanel } from './fontPopupPanel'
import { FlexChildPanel } from './flexChildPanel'
import { SizeRatioPanel } from './sizeRatioPanel'
import { PaddingPanel } from './paddingPanel'
import { CodePanel } from './codePanel'
import { HTMLAttributePanel } from './htmlAttributePanel'

export class EditorUI implements ClientUpdateListener, ActiveActionListener {
  private actionInitator: ActionInitiator
  private createAction: CreateAction
  private nodeActions: NodeActions
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private commandHandler: CommandHandler

  private backgroundPanel: BackgroundPanel
  private borderPanel: BorderPanel
  private cursorPanel: CursorPanel
  private displayPanel: DisplayPanel
  private filterBlurPanel: FilterBlurPanel
  private flexChildPanel: FlexChildPanel
  private fontPopupPanel: FontPopupPanel
  private imageLibraryPanel: ImageLibraryPanel
  private imagePanel: ImagePanel
  private layoutPanel: DisplayPanel
  private opacityPanel: OpacityPanel
  private overflowPanel: OverflowPanel
  private paddingPanel: PaddingPanel
  private positionModePanel: PositionModePanel
  private positionPanel: PositionPanel
  private roundingPanel: RoundingPanel
  private selectionColorsPanel: SelectionColorsPanel
  private screenSizePanel: ScreenSizePanel
  private shadowPanel: ShadowPanel
  private sizePanel: SizePanel
  private sizeRatioPanel: SizeRatioPanel
  private styleOverridesPanel: StyleOverridesPanel
  private textPanelDefault: TextPanelDefault
  private textPanelTextEditor: TextPanelTextEditor
  private transitionPanel: TransitionPanel

  private codePanel: CodePanel
  private htmlAttributePanel: HTMLAttributePanel

  private layersPanel: LayersPanel
  private detailsPanel: DetailsPanel
  private templatesPanel: TemplatesPanel
  private cameraPanel: CameraPanel
  private fpsPanel: FPSPanel

  private editorProjectService: EditorProjectService
  private editorWebsocketService: EditorWebsocketService

  private action: Action | null
  private actionListeners: { [key: string]: EditorActionListener }
  private selectionListeners: { [key: string]: EditorSelectionListener }

  private editingText: boolean = false

  constructor(
    actionInitator: ActionInitiator,
    createAction: CreateAction,
    nodeAction: NodeActions,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    commandHandler: CommandHandler,

    backgroundPanel: BackgroundPanel,
    borderPanel: BorderPanel,
    cursorPanel: CursorPanel,
    displayPanel: DisplayPanel,
    filterBlurPanel: FilterBlurPanel,
    flexChildPanel: FlexChildPanel,
    fontPopupPanel: FontPopupPanel,
    imageLibraryPanel: ImageLibraryPanel,
    imagePanel: ImagePanel,
    opacityPanel: OpacityPanel,
    overflowPanel: OverflowPanel,
    paddingPanel: PaddingPanel,
    positionModePanel: PositionModePanel,
    positionPanel: PositionPanel,
    roundingPanel: RoundingPanel,
    selectionColorsPanel: SelectionColorsPanel,
    screenSizePanel: ScreenSizePanel,
    shadowPanel: ShadowPanel,
    sizePanel: SizePanel,
    sizeRatioPanel: SizeRatioPanel,
    styleOverridesPanel: StyleOverridesPanel,
    textPanelDefault: TextPanelDefault,
    textPanelTextEditor: TextPanelTextEditor,
    transitionPanel: TransitionPanel,

    codePanel: CodePanel,
    htmlAttributePanel: HTMLAttributePanel,

    layersPanel: LayersPanel,
    detailsPanel: DetailsPanel,
    templatesPanel: TemplatesPanel,
    cameraPanel: CameraPanel,
    fpsPanel: FPSPanel,

    editorProjectService: EditorProjectService,
    editorWebsocketService: EditorWebsocketService
  ) {
    this.actionInitator = actionInitator
    this.createAction = createAction
    this.nodeActions = nodeAction
    this.document = document
    this.documentSelection = documentSelection
    this.commandHandler = commandHandler

    this.layoutPanel = displayPanel
    this.backgroundPanel = backgroundPanel
    this.borderPanel = borderPanel
    this.cursorPanel = cursorPanel
    this.displayPanel = displayPanel
    this.filterBlurPanel = filterBlurPanel
    this.flexChildPanel = flexChildPanel
    this.fontPopupPanel = fontPopupPanel
    this.imageLibraryPanel = imageLibraryPanel
    this.imagePanel = imagePanel
    this.opacityPanel = opacityPanel
    this.overflowPanel = overflowPanel
    this.paddingPanel = paddingPanel
    this.positionModePanel = positionModePanel
    this.positionPanel = positionPanel
    this.roundingPanel = roundingPanel
    this.selectionColorsPanel = selectionColorsPanel
    this.screenSizePanel = screenSizePanel
    this.shadowPanel = shadowPanel
    this.sizePanel = sizePanel
    this.sizeRatioPanel = sizeRatioPanel
    this.styleOverridesPanel = styleOverridesPanel
    this.textPanelDefault = textPanelDefault
    this.textPanelTextEditor = textPanelTextEditor
    this.transitionPanel = transitionPanel

    this.codePanel = codePanel
    this.htmlAttributePanel = htmlAttributePanel

    this.layersPanel = layersPanel
    this.detailsPanel = detailsPanel
    this.templatesPanel = templatesPanel
    this.cameraPanel = cameraPanel
    this.fpsPanel = fpsPanel

    this.editorProjectService = editorProjectService
    this.editorWebsocketService = editorWebsocketService

    this.action = null
    this.actionListeners = {}
    this.selectionListeners = {}

    this.editingText = false
  }

  getTypes = (): Update['type'][] => {
    return ['edit_text', 'selection']
  }

  onUpdate = (updates: Update[]): void => {
    for (const u of updates) {
      switch (u.type) {
        case 'edit_text':
          this.editingText = u.data.id !== null
          break
        case 'selection':
          this.handleSelection(u.data.ids)
          break
      }
    }
  }

  onActiveAction(handler: ActionHandler | null): void {
    if (handler === null) {
      this.action = null
    } else {
      this.action = handler.getType()
    }
    for (const key in this.actionListeners) {
      this.actionListeners[key].onAction(this.action)
    }
  }

  getActionInitiator = (): UIActionInitiator => {
    return {
      openLibrary: this.actionInitator.openLibrary,
      closeLibrary: this.actionInitator.closeLibrary,
      toggleLibrary: this.actionInitator.toggleLibrary,
      create: this.createAction.createNode,
      draw: this.actionInitator.draw,
      cancel: this.actionInitator.cancel,
      editInput: this.actionInitator.editInput,
    }
  }

  getProjectService = (): UIProjectService => {
    return {
      setProject: this.editorProjectService.setProject,
      reloadProject: this.editorProjectService.reloadProject,
    }
  }

  getWebsocketService = (): UIWebsocketService => {
    return {
      open: this.editorWebsocketService.open,
      subscribeConnect: this.editorWebsocketService.subscribeConnect,
      unsubscribeConnect: this.editorWebsocketService.unsubscribeConnect,
      subscribeDisconnect: this.editorWebsocketService.subscribeDisconnect,
      unsubscribeDisconnect: this.editorWebsocketService.unsubscribeDisconnect,
      subscribeError: this.editorWebsocketService.subscribeError,
      unsubscribeError: this.editorWebsocketService.unsubscribeError,
    }
  }

  getNodeActions = (): UINodeActions => {
    return this.nodeActions
  }

  getBackgroundPanel = (): BackgroundPanel => {
    return this.backgroundPanel
  }

  getBorderPanel = (): BorderPanel => {
    return this.borderPanel
  }

  getCursorPanel = (): CursorPanel => {
    return this.cursorPanel
  }

  getDisplayPanel = (): DisplayPanel => {
    return this.displayPanel
  }

  getFilterBlurPanel = (): FilterBlurPanel => {
    return this.filterBlurPanel
  }

  getFlexChildPanel = (): FlexChildPanel => {
    return this.flexChildPanel
  }

  getFontPopupPanel = (): FontPopupPanel => {
    return this.fontPopupPanel
  }

  getImageLibraryPanel = (): ImageLibraryPanel => {
    return this.imageLibraryPanel
  }

  getImagePanel = (): ImagePanel => {
    return this.imagePanel
  }

  getOpacityPanel = (): OpacityPanel => {
    return this.opacityPanel
  }

  getOverflowPanel = (): OverflowPanel => {
    return this.overflowPanel
  }

  getPaddingPanel = (): PaddingPanel => {
    return this.paddingPanel
  }

  getPositionModePanel = (): PositionModePanel => {
    return this.positionModePanel
  }

  getPositionPanel = (): PositionPanel => {
    return this.positionPanel
  }

  getRoundingPanel = (): RoundingPanel => {
    return this.roundingPanel
  }

  getSelectionColorsPanel = (): SelectionColorsPanel => {
    return this.selectionColorsPanel
  }

  getScreenSizePanel = (): ScreenSizePanel => {
    return this.screenSizePanel
  }

  getShadowPanel = (): ShadowPanel => {
    return this.shadowPanel
  }

  getSizePanel = (): SizePanel => {
    return this.sizePanel
  }

  getSizeRatioPanel = (): SizeRatioPanel => {
    return this.sizeRatioPanel
  }

  getStyleOverridesPanel = (): StyleOverridesPanel => {
    return this.styleOverridesPanel
  }

  getTextPanel = (): TextPanel => {
    if (this.editingText) {
      return this.textPanelTextEditor
    } else {
      return this.textPanelDefault
    }
  }

  getTransitionPanel = (): TransitionPanel => {
    return this.transitionPanel
  }

  getCodePanel = (): CodePanel => {
    return this.codePanel
  }

  getHTMLAttributePanel = (): HTMLAttributePanel => {
    return this.htmlAttributePanel
  }

  getLayersPanel = (): LayersPanel => {
    return this.layersPanel
  }

  getDetailsPanel = (): DetailsPanel => {
    return this.detailsPanel
  }

  getTemplatesPanel = (): TemplatesPanel => {
    return this.templatesPanel
  }

  getCameraPanel = (): CameraPanel => {
    return this.cameraPanel
  }

  getFPSPanel = (): FPSPanel => {
    return this.fpsPanel
  }

  commit = (): void => {
    this.commandHandler.handle({ type: 'commit' })
  }

  subscribeToActions = (key: string, listener: EditorActionListener): void => {
    this.actionListeners[key] = listener
    listener.onAction(this.action)
  }

  unsubscribeFromActions = (key: string): void => {
    delete this.actionListeners[key]
  }

  subscribeToSelection = (
    key: string,
    listener: EditorSelectionListener
  ): void => {
    this.selectionListeners[key] = listener
    listener.onSelection(this.documentSelection.getSelected())
  }

  unsubscribeFromSelection = (key: string): void => {
    delete this.selectionListeners[key]
  }

  private handleSelection = (ids: string[]): void => {
    const nodes = ids
      .map((id) => this.document.getNode(id))
      .filter((n) => n) as ReadOnlyNode[]

    for (const key in this.selectionListeners) {
      this.selectionListeners[key].onSelection(nodes)
    }
  }
}
