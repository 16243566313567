import { AttributeBorderSide, AttributeType } from 'application/attributes'
import { Color, createNewColor } from 'application/color'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'
import _ from 'lodash'

type BorderPanelKeys =
  | 'border.side'
  | 'border.color'
  | 'border.top.unit'
  | 'border.right.unit'
  | 'border.bottom.unit'
  | 'border.left.unit'
  | 'border.top.px'
  | 'border.right.px'
  | 'border.bottom.px'
  | 'border.left.px'

export type BorderPanelSide = 'top' | 'right' | 'bottom' | 'left'

export interface BorderPanelState {
  width: number | 'Mixed'
  left: number | 'Mixed'
  right: number | 'Mixed'
  top: number | 'Mixed'
  bottom: number | 'Mixed'
  color: Color | 'Mixed'
  side: AttributeBorderSide | 'Mixed'
  activationMode: 'add' | 'remove' | 'none'
}

export interface BorderPanelHandlers {
  activate: () => void
  deactivate: () => void
  setWidth: (value: number) => void
  slideWidth: (value: number) => void
  setSideValue: (value: number, side: BorderPanelSide) => void
  slideSideValue: (value: number, side: BorderPanelSide) => void
  setSide: (mode: AttributeBorderSide) => void
  setColor: (value: Color) => void
  clearMixedColor: () => void
}

export class BorderPanel extends StyleAttributePanel<
  BorderPanelState,
  BorderPanelHandlers,
  BorderPanelKeys
> {
  getSettings(): BorderPanelState {
    return {
      width: this.getWidth(),
      left: this.getSideValue('left'),
      right: this.getSideValue('right'),
      top: this.getSideValue('top'),
      bottom: this.getSideValue('bottom'),
      color: this.getColor(),
      side: this.getSide(),
      activationMode: this.getActivationMode(),
    }
  }

  getHandlers(): BorderPanelHandlers {
    return {
      activate: this.activate,
      deactivate: this.deactivate,
      setWidth: this.setWidth,
      slideWidth: this.slideWidth,
      setSideValue: this.setSideValue,
      slideSideValue: this.slideSideValue,
      setSide: this.setSide,
      setColor: this.setColor,
      clearMixedColor: this.clearMixedColor,
    }
  }

  private getActivationMode = (): 'add' | 'remove' | 'none' => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return 'none'

    return nodes.filter(this.nodeHasBorder).length === 0 ? 'add' : 'remove'
  }

  private getWidth = (): number | 'Mixed' => {
    const nodes = this.getNodes().filter(this.nodeHasBorder)
    if (nodes.length === 0) return 0

    let width = this.getNodeWidth(nodes[0])
    for (const node of nodes) {
      const nodeWidth = this.getNodeWidth(node)
      if (nodeWidth === 'Mixed') return 'Mixed'
      if (nodeWidth !== width) return 'Mixed'
      width = nodeWidth
    }

    return width
  }

  private getSideValue = (side: BorderPanelSide): number | 'Mixed' => {
    const nodes = this.getNodes().filter(this.nodeHasBorder)
    if (nodes.length === 0) return 0

    let value = nodes[0].getStyleAttribute(`border.${side}.px`) || 0
    for (const node of nodes) {
      const nodeValue = node.getStyleAttribute(`border.${side}.px`) || 0
      if (nodeValue !== value) return 'Mixed'
      value = nodeValue
    }

    return value
  }

  private getColor = (): Color | 'Mixed' => {
    const nodes = this.getNodes().filter(this.nodeHasBorder)
    if (nodes.length === 0) return createNewColor()

    let color = nodes[0].getStyleAttribute('border.color') || createNewColor()
    for (const node of nodes) {
      const nodeColor =
        node.getStyleAttribute('border.color') || createNewColor()
      if (!_.isEqual(color, nodeColor)) return 'Mixed'
      color = nodeColor
    }

    return color
  }

  private getSide = (): AttributeBorderSide | 'Mixed' => {
    const nodes = this.getNodes().filter(this.nodeHasBorder)
    if (nodes.length === 0) return 'all'

    let side = nodes[0].getStyleAttribute('border.side') || 'all'
    for (const node of nodes) {
      const nodeSide = node.getStyleAttribute('border.side') || 'all'
      if (nodeSide !== side) return 'Mixed'
    }

    return side
  }

  private activate = (): void => {
    this.setMulti({
      'border.side': 'all',
      'border.top.unit': 'px',
      'border.right.unit': 'px',
      'border.bottom.unit': 'px',
      'border.left.unit': 'px',
      'border.top.px': 1,
      'border.right.px': 1,
      'border.bottom.px': 1,
      'border.left.px': 1,
      'border.color': createNewColor(),
    })
    this.commit()
  }

  private deactivate = (): void => {
    this.setMulti({
      'border.side': undefined,
      'border.top.unit': undefined,
      'border.right.unit': undefined,
      'border.bottom.unit': undefined,
      'border.left.unit': undefined,
      'border.top.px': undefined,
      'border.right.px': undefined,
      'border.bottom.px': undefined,
      'border.left.px': undefined,
      'border.color': undefined,
    })
    this.commit()
  }

  private setWidth = (value: number): void => {
    const nodes = this.getNodes().filter(this.nodeHasBorder)
    if (nodes.length === 0) return

    for (const node of nodes) {
      const side = node.getStyleAttribute('border.side') || 'all'
      let left: number | undefined = undefined
      let right: number | undefined = undefined
      let bottom: number | undefined = undefined
      let top: number | undefined = undefined
      switch (side) {
        case 'custom':
        case 'all':
          top = value
          right = value
          bottom = value
          left = value
          break
        case 'top':
          top = value
          break
        case 'right':
          right = value
          break
        case 'bottom':
          bottom = value
          break
        case 'left':
          left = value
          break
      }

      this.setOne(node.getId(), {
        'border.top.unit': 'px',
        'border.right.unit': 'px',
        'border.bottom.unit': 'px',
        'border.left.unit': 'px',
        'border.top.px': top,
        'border.right.px': right,
        'border.bottom.px': bottom,
        'border.left.px': left,
      })
    }
  }

  private slideWidth = (value: number): void => {
    const nodes = this.getNodes().filter(this.nodeHasBorder)
    if (nodes.length === 0) return

    for (const node of nodes) {
      const side = node.getStyleAttribute('border.side') || 'all'
      if (['all', 'custom', 'left'].includes(side)) {
        this.slideOne(node.getId(), 'border.left.px', value)
      }
      if (['all', 'custom', 'right'].includes(side)) {
        this.slideOne(node.getId(), 'border.right.px', value)
      }
      if (['all', 'custom', 'top'].includes(side)) {
        this.slideOne(node.getId(), 'border.top.px', value)
      }
      if (['all', 'custom', 'bottom'].includes(side)) {
        this.slideOne(node.getId(), 'border.bottom.px', value)
      }
    }
  }

  private setSideValue = (value: number, side: BorderPanelSide): void => {
    const nodes = this.getNodes().filter(this.nodeHasBorder)
    if (nodes.length === 0) return

    for (const node of nodes) {
      this.setOne(node.getId(), { [`border.${side}`]: value })
    }
  }

  private slideSideValue = (value: number, side: BorderPanelSide): void => {
    const nodes = this.getNodes().filter(this.nodeHasBorder)
    if (nodes.length === 0) return

    for (const node of nodes) {
      this.slideOne(node.getId(), `border.${side}.px`, value)
    }
  }

  private setSide = (mode: AttributeBorderSide): void => {
    const nodes = this.getNodes().filter(this.nodeHasBorder)
    if (nodes.length === 0) return

    for (const node of nodes) {
      const maxValue = this.getNodeMaxValue(node)
      let left: number | undefined = undefined
      let right: number | undefined = undefined
      let bottom: number | undefined = undefined
      let top: number | undefined = undefined

      switch (mode) {
        case 'left':
          left = maxValue
          break
        case 'right':
          right = maxValue
          break
        case 'bottom':
          bottom = maxValue
          break
        case 'top':
          top = maxValue
          break
        case 'all':
          left = maxValue
          right = maxValue
          bottom = maxValue
          top = maxValue
          break
      }

      this.setOne(node.getId(), {
        'border.side': mode,
        'border.top.px': top,
        'border.right.px': right,
        'border.bottom.px': bottom,
        'border.left.px': left,
      })
    }
  }

  private setColor = (value: Color): void => {
    this.setMulti({ 'border.color': value })
  }

  private clearMixedColor = (): void => {
    const nodes = this.getNodes()
    let color: Color | undefined = undefined
    for (const node of nodes) {
      const nodeColor = node.getStyleAttribute('border.color')
      if (color === undefined) {
        color = nodeColor
        break
      }
    }

    this.setMulti({ 'border.color': color })
    this.commit()
  }

  private nodeHasBorder = (node: ReadOnlyNode): boolean => {
    return node.getStyleAttribute('border.side') !== undefined
  }

  private getNodeWidth = (node: ReadOnlyNode): number | 'Mixed' => {
    const mode = node.getStyleAttribute('border.side') || 'all'
    switch (mode) {
      case 'all':
      case 'top':
        return node.getStyleAttribute('border.top.px') || 0
      case 'left':
        return node.getStyleAttribute('border.left.px') || 0
      case 'right':
        return node.getStyleAttribute('border.right.px') || 0
      case 'bottom':
        return node.getStyleAttribute('border.bottom.px') || 0
      case 'custom':
        return 'Mixed'
    }
  }

  private getNodeMaxValue = (node: ReadOnlyNode): number => {
    const values = [
      node.getStyleAttribute('border.top.px') || 0,
      node.getStyleAttribute('border.right.px') || 0,
      node.getStyleAttribute('border.bottom.px') || 0,
      node.getStyleAttribute('border.left.px') || 0,
    ]
    return Math.max(...values, 1)
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }

  protected override getSlideMin = (): number => {
    return 0
  }

  protected override getSlideMax = (): number => {
    return 1_000
  }
}

const allowedTypes: AttributeType[] = ['frame', 'input', 'form']
