import { createSelector } from '../utils'
import { AttributeTransformer } from './types'
import {
  addColorToStyle,
  fontFamilyToLineHeight,
  fontNameToCSS,
  fontWeightToCSS,
} from './utils'
import { ReadOnlyNode } from 'application/node'
import { HTMLDocument } from '../html/types'

export class TextStyle implements AttributeTransformer {
  transformBase = (node: ReadOnlyNode, htmlDocument: HTMLDocument): void => {
    if (
      node.getBaseAttribute('type') !== 'text' &&
      node.getBaseAttribute('type') !== 'input'
    ) {
      return
    }
    const selector = createSelector(node.getId(), 'default')
    const style = htmlDocument.getStyles().get(selector)

    const styles = node.getDefaultSelector().styles

    const fontFamily = styles['text.font.family']
    const fontWeight = styles['text.font.weight']
    const fontSize = styles['text.font.size']
    const color = styles['text.color']
    const textAlign = styles['text.align']
    const letterSpacing = styles['text.letterSpacing']
    const lineHeight = styles['text.lineHeight']

    if (
      fontFamily === undefined ||
      fontWeight === undefined ||
      fontSize === undefined ||
      color === undefined ||
      textAlign === undefined ||
      letterSpacing === undefined ||
      lineHeight === undefined
    ) {
      return
    }

    style.setProperty('fontFamily', fontNameToCSS(fontFamily))
    style.setProperty('fontWeight', fontWeightToCSS(fontWeight))
    style.setProperty('fontSize', { unit: 'px', value: fontSize })
    switch (textAlign) {
      case 'left':
        break
      default:
        style.setProperty('textAlign', textAlign)
    }
    switch (letterSpacing) {
      case 0:
        break
      default:
        style.setProperty('letterSpacing', { unit: 'px', value: letterSpacing })
    }
    switch (lineHeight) {
      case 0:
        break
      default:
        style.setProperty('lineHeight', {
          unit: 'px',
          value: fontFamilyToLineHeight(fontFamily) * fontSize + lineHeight,
        })
    }
    addColorToStyle(color, style)
  }
}
